import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-item-detail',
  templateUrl: './item-detail.component.html',
  styleUrls: ['./item-detail.component.scss']
})
export class OurlyItemDetailComponent implements OnInit {

  @Input() route: string;
  @Input() icon: string;
  @Input() emoji: string;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  doRoute() {
    this.router.navigate([ this.route ]);
  }


}
