import { Component, OnInit, SimpleChanges, OnChanges, ChangeDetectorRef, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormControl,
  Validator,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import * as moment from 'moment/moment';
import {errors} from './goaldate-errors';
import {FormfieldbaseComponent} from '../formfieldbase/formfieldbase.component';

export let dateError = false;

export function ValidateDate(control: AbstractControl) {
  const today = moment.utc(getCurrentDayTimestamp()).startOf('day');
  const controlDate = moment.utc(control.value).endOf('day');
  // const currentDay = getCurrentDayTimestamp();
    if (!controlDate.isValid()) {
      dateError = true;
      return { invalidDate: true };
    }
    if (controlDate.isValid() && controlDate < today) {
      dateError = true;
      return { invalidDate: true };
    }
    dateError = false;
  return null;
}

export function getCurrentDayTimestamp() {
  const d = new Date();
  return new Date(
    Date.UTC(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes(),
      d.getSeconds()
    )
  // `toIsoString` returns something like "2019-08-22T08:32:32.847Z"
  // and we want the first part ("2019-08-22")
  ).toISOString().slice(0, 10);
}

const setTimeoutPromise = (timeout: number) => new Promise(resolve => {
  setTimeout(resolve, timeout);
});

@Component({
  selector: 'app-goaldate-field',
  templateUrl: './goaldate-field.component.html',
  styleUrls: ['../formfieldbase/formfieldbase.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GoaldateFieldComponent),
      multi: true
    },
    {
     provide: NG_VALIDATORS,
     useExisting: forwardRef(() => GoaldateFieldComponent),
     multi: true
   }
 ]
})

export class GoaldateFieldComponent extends FormfieldbaseComponent implements OnChanges, ControlValueAccessor, Validator {

  public currentTime: any;
  public maxdate: any;
  public mindate: any;
  private getDate: string;
  private maxYear = 2050;

  constructor(private cd: ChangeDetectorRef) {
    super('goal');
  this.fieldForm = new FormGroup(
        {
          goal: new FormControl('', [Validators.required, ValidateDate])
        });

    this.currentTime = new Date();
    this.maxdate = this.currentTime.getFullYear();
    this.maxdate = this.maxYear;
    // this.maxdate = this.maxdate + 99;
    this.maxdate = this.maxdate.toString();
    // this.mindate = getCurrentDayTimestamp();
    this.mindate = moment.utc().format('YYYY-MM');
    this.fieldForm.get(this.thisFieldName).setValue(getCurrentDayTimestamp()); // default override later
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cd.detectChanges();
    const testDate = (strDate: string) => {
      const formats = ['YYYY-MM-DD'],
          valid = moment.utc(strDate, formats).isValid();
          if (valid) {
            return true;
          }
          return false;
    };
    // const cpv = testDate(changes.getVal.previousValue);
    const ccv = testDate(changes.getVal.currentValue);
    if (changes.getVal.isFirstChange()) {
      if (changes.getVal.currentValue !== '' && testDate(moment.utc(changes.getVal.currentValue).format('YYYY-MM-DD'))) {
        this.getDate = moment.utc(changes.getVal.currentValue).format('YYYY-MM-DD');
      } else {
        this.getDate = getCurrentDayTimestamp();
        this.setFirstGoal(getCurrentDayTimestamp());
      }
    }
    if (changes.getVal.isFirstChange() && ccv) {
      this.setFirstGoal(changes.getVal.currentValue);
    }
    if (!changes.getVal.isFirstChange()) {
      if (testDate(moment.utc(changes.getVal.previousValue).format('YYYY-MM-DD'))
      && !testDate(moment.utc(changes.getVal.currentValue).format('YYYY-MM-DD'))) {
        // case where date is smaller than today and getVal is set to ""
        if (moment.utc(changes.getVal.previousValue).format('YYYY-MM-DD') === this.getDate) {
          // do nothing! registerOnChange will do emit
          // *(1)
        } else { // maybe somebody enter February, 30
          // set back to to today
          // *(2)
          this.setFirstGoal(getCurrentDayTimestamp());
        }
      } else if (testDate(moment.utc(changes.getVal.previousValue).format('YYYY-MM-DD'))
      && testDate(moment.utc(changes.getVal.currentValue).format('YYYY-MM-DD'))) {
        // do nothing! registerOnChange will do emit
        // *(3)
        this.setFirstGoal(changes.getVal.currentValue);
      } else if (!testDate(moment.utc(changes.getVal.previousValue).format('YYYY-MM-DD'))
      && testDate(moment.utc(changes.getVal.currentValue).format('YYYY-MM-DD'))) {
        if (moment.utc(changes.getVal.currentValue) < moment.utc(getCurrentDayTimestamp())) {
          // special case if we get date from API after component is rendered
          this.getDate = moment.utc(changes.getVal.currentValue).format('YYYY-MM-DD');
          this.getVal = moment.utc(changes.getVal.currentValue).format('YYYY-MM-DD');
          this.setFirstGoal(changes.getVal.currentValue);
        }
        this.setFirstGoal(changes.getVal.currentValue);
        // *(4, this.getDate, this.getVal)
      } else {
        this.setFirstGoal(getCurrentDayTimestamp());
        // *('previous wrong current wrong');
      }
    }
    if (dateError && !this.fieldForm.valid) {
      if (moment.utc(this.fieldForm.get(this.thisFieldName).value) < moment.utc(getCurrentDayTimestamp())) {
        this.mindate = moment.utc(this.fieldForm.get(this.thisFieldName).value).format('YYYY-MM');
      } else {
        this.mindate = moment.utc(getCurrentDayTimestamp()).format('YYYY-MM');
      }
        this.maxdate = this.maxYear;
        this.maxdate = this.maxdate.toString();
        this.invalid = true;
        this.errorMessage = errors.invalid;
        this.fieldForm.controls[this.thisFieldName].markAsDirty();
        this.fieldForm.controls[this.thisFieldName].markAsTouched();
    }
  }

  async setFirstGoal(goalValue: string) {
    await setTimeoutPromise(1).then(res => {
      this.fieldForm.get(this.thisFieldName).setValue(moment.utc(goalValue).format('YYYY-MM-DD'));
      if (moment.utc(goalValue) < moment.utc(getCurrentDayTimestamp())) {
        this.mindate = moment.utc(this.fieldForm.get(this.thisFieldName).value).format('YYYY-MM');
      } else {
        this.mindate = moment.utc(getCurrentDayTimestamp()).format('YYYY-MM');
      }
      this.maxdate = this.maxYear;
      this.maxdate = this.maxdate.toString();
    });
  }

  registerOnChange(fn: any): void {
    this.fieldForm.valueChanges.subscribe((fnx) => {
      if (this.fieldForm.valid) {
        // *('validForm');
        this.valueChange.emit(fnx.goal);
      } else {
        this.invalid = true;
        this.errorMessage = errors.invalid;
        this.fieldForm.controls[this.thisFieldName].markAsDirty();
        this.fieldForm.controls[this.thisFieldName].markAsTouched();
        this.valueChange.emit('');
        dateError = true;
      }
    });
  }

  validate(c: AbstractControl): ValidationErrors | null {
    const controls = this.fieldForm.controls;
    Object.keys(controls).forEach(
        (controlName) => {
          if (controls[controlName].errors !== null) {
            this.required = controls[controlName].errors.hasOwnProperty('required');
            this.invalid = controls[controlName].errors.hasOwnProperty('invalidDate');
          } else {
            this.required = false;
            this.invalid = false;
          }
        });
    let message = '';
    if (this.required) { message = errors.required; }
    if (this.invalid) { message = errors.invalid; }
    this.errorMessage = message;
    return this.fieldForm.valid ? null : { invalidForm: {valid: false, message: message}};
  }

}
