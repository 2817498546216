import { Component, Input, OnInit, OnChanges, HostListener, ViewEncapsulation, AfterViewInit  } from '@angular/core';
import { UsersService } from '../../../services/users/users.service';
import { D3chartsService } from '../../../services/charts/d3charts.service';
import * as moment from 'moment/moment';
import * as Highcharts from 'highcharts';

declare var require: any;
const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');

let chartTotal = 0;

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

const colors = ['#3B97B2', 'green', '#FF56DE', '#E6D605', '#BC36FE', 'white'];

@Component({
  selector: 'app-earned-kept-chart',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './earned-kept-chart.component.html',
  styleUrls: ['./earned-kept-chart.component.scss'],
})
export class EarnedKeptChartComponent implements OnChanges, OnInit, AfterViewInit  {

  resizeTimeout = null;
  private dataEarnSpent = [];
  @Input()
  userId: string;
  @Input()
  familyId: string;
  margin = {top: 20, right: 20, bottom: 30, left: 40};
  private chart;
  public options: any;
  public dete = 'indeterminate';

  private dataLabelsVisible = true;

  private Earned = '0.00';
  private Kept = '0.00';

  private progValue = 0;

  @HostListener('window:resize')
  onResize() {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      if (this.chart) {
        this.chart.reflow();
      }
    }).bind(this), 500);
  }

  constructor(private usersService: UsersService, private d3ChartsService: D3chartsService) {
  }

  ngAfterViewInit() {
    this.d3ChartsService.getEarnedKept(this.userId, this.familyId, moment().format('YYYY-MM')).then((response) => {
      // response is returned as : { earned: 20, spent: 0 }
      if ( response.length === 0) {
        chartTotal = 0;
        this.Earned = chartTotal.toFixed(2);
        this.Kept = '0.00';
          /*this.options.series[0]['data'] = [0];
          this.options.series[1]['data'] = [0];
          this.dataLabelsVisible = false;
          this.options.plotOptions.column.stacking = 'normal';
          this.options.plotOptions.column.maxPointWidth = 60;*/
          /*this.options.plotOptions.column.dataLabels.enabled = true;
          this.options.plotOptions.column.dataLabels.x = 0;
          this.options.plotOptions.column.dataLabels.color = '#616061';
          this.options.plotOptions.column.dataLabels.formatter = function () {
            return '<b style="font-size:30pt;">$ 0.00</b>';
          };*/
          /*this.chart = Highcharts.chart('chart_' + this.userId, this.options);
          setTimeout(() => {
            this.chart.reflow();
          }, 100);*/
      } else {
        chartTotal = parseFloat(response.earned);
        this.Earned = chartTotal.toFixed(2);
        this.Kept = (parseFloat(response.earned)  - parseFloat(response.spent)).toFixed(2);
        let p = 0;
        // solve percent
        if (parseFloat(response.earned) > 0 && parseFloat(response.spent) > 0 ) {
            p = (parseFloat(response.earned)  - parseFloat(response.spent)) / chartTotal;
        } else {
            p = 1;
        }

        this.progValue = p;
          /*this.options.series[0]['data'] = [parseFloat(response.spent)] ;
          this.options.series[1]['data'] = [parseFloat(response.earned)  - parseFloat(response.spent)] ;
          this.dataLabelsVisible = true;
          this.options.plotOptions.column.stacking = 'normal';
          this.options.plotOptions.column.maxPointWidth = 60;*/
          /*this.options.plotOptions.column.dataLabels.enabled = true;
          this.options.plotOptions.column.dataLabels.x = -50;
          this.options.plotOptions.column.dataLabels.color = 'black';
          this.options.plotOptions.column.dataLabels.formatter = function () {
            if (this.series.name === 'Kept') {
              return '<b>' + this.series.name + '<span style="color:white">...</span></b><br/>$' + this.y.toFixed(2);
            } else {
              return '<b>' + this.series.name + '</b><br/>$' + this.y.toFixed(2);
            }
          };*/
         /* this.chart = Highcharts.chart('chart_' + this.userId, this.options);
          setTimeout(() => {
            this.chart.reflow();
          }, 100);*/
      }
    });
  }

  ngOnInit() {
  }

  ngOnChanges(): void {
  }

}
