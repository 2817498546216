import {Component, Input, OnInit, AfterViewChecked, OnChanges, SimpleChanges, AfterContentInit} from '@angular/core';
import {AccountsService} from '../../services/accounts/accounts.service';
import {Router} from '@angular/router';
import {SmartbalanceService} from '../../services/smartbalance/smartbalance.service';
import {UsersService} from '../../services/users/users.service';
import {WallitService} from '../../services/wallit/wallit.service';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss']
})
export class OurlyBankComponent implements OnInit, OnChanges {

  protected institutionImage;
  private wallit;

  @Input() bank;
  @Input() nosmart = false;

  constructor(
      private accountsService: AccountsService,
      private router: Router,
      private smartBalanceService: SmartbalanceService,
      private usersService: UsersService,
      private wallitService: WallitService
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.wallitService.monitorWallitChanged(wallit => {
      this.wallit = wallit;
    })
    if (this.bank) {
      if (!this.wallitService.isRadius()) {
        this.bank.verified = true;
      }
      if (!this.nosmart) {
          const account = this.wallit.smartBalances.find(balance => this.bank.account && balance.plaidAccountId === this.bank.account.account_id);
          if (account) {
            this.bank.smartBalance = account.smartBalance;
          }
      }
    }
  }

  smartBalanceSettings() {
    this.router.navigate(['smart-balance-settings', this.bank.bankId, this.bank.account.account_id ]);
  }

}
