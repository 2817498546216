import {AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UsersService} from '../../services/users/users.service';

@Component({
  selector: 'app-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss'],
})
export class UserItemComponent implements OnInit, AfterViewInit {

  private hasAvatar;

  @Input() member;
  @Input() detail = false;
  @ViewChild('avatar', {static: false}) avatar;
  @ViewChild('item', {static: false}) item;

  constructor(
      private router: Router,
      private usersService: UsersService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.hasAvatar = this.usersService.getAvatar(this.member, this.avatar.nativeElement);
    this.item.detail = this.detail;
  }

  getMemberName(member) {
    return (member.firstName || '') + ' ' + (member.lastName || '') + (member.role === 'admin' ? ' (Admin)' : '');
  }

  getEmail(member) {
    return member.email;
  }

  getLoginName(member) {
      return member.loginName ? '@' + member.loginName : '';
  }

  showUserDetail(event, user) {
    event.stopPropagation();
    if (user.userId) {
      this.router.navigate(['/userdetail', user.userId]);
    }
  }

}
