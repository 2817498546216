import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HelpComponent} from '../../help/help.component';

@Component({
  selector: 'app-setfrequency',
  templateUrl: './setfrequency.component.html',
  styleUrls: ['./setfrequency.component.scss'],
})
export class SetfrequencyComponent implements OnInit {

  @Output() frequencyChangedEvent: EventEmitter<string> = new EventEmitter<string>();

  @Input() transaction;

  private frequency = 'Monthly';

  constructor() { }

  ngOnInit() {
    this.frequency = this.transaction.recurrenceType;
  }

  frequencyChanged(event) {
    this.frequency = event.detail.value;
  }

  cancelClicked() {
    this.frequencyChangedEvent.emit('');
  }

  setClicked() {
    this.frequencyChangedEvent.emit(this.frequency);
  }

}
