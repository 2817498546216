import { Injectable } from '@angular/core';
import {Browser} from '@capacitor/browser';

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  constructor(
  ) { }

  open(url: string) {
    try {
      Browser.open({
        'url': url,
        'toolbarColor': window.getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary')
      });
    } catch (error) {
      console.error('Error opening browser:', error);
    }
  }
}
