import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-setsharegoalheader',
  templateUrl: './setsharegoalheader.component.html',
  styleUrls: ['./setsharegoalheader.component.scss'],
})
export class SetsharegoalheaderComponent implements OnInit {

  @Input() contents;

  constructor(
      protected domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {}

}
