import {Component, Input, OnInit} from '@angular/core';
import {RewardsService} from '../../services/rewards/rewards.service';
import {UsersService} from '../../services/users/users.service';
import {Router} from '@angular/router';
import {BaseComponent} from '../base/base.component';
import {ApiService} from '../../services/api/api.service';
import {LocationService} from '../../services/location/location.service';
import {LogService} from '../../services/log/log.service';

@Component({
  selector: 'app-snacks',
  templateUrl: './snacks.component.html',
  styleUrls: ['./snacks.component.scss'],
})
export class SnacksComponent extends BaseComponent implements OnInit {

  @Input() title;
  @Input() local = false;
  @Input() categories;

  protected rewards = [];
  protected clickedReward;

  private slideOpts = {
    spaceBetween: 10,
    slidesPerView: 1.5
  };

  constructor(
      private rewardsService: RewardsService,
      private usersService: UsersService,
      private router: Router,
      apiService: ApiService,
      private locationService: LocationService,
      private logService: LogService
  ) {
    super(apiService);
  }

  ngOnInit() {
    const filterFunc = item => {
      if (!this.categories || this.categories.length === 0) {
        return true;
      }
      return this.categories.some(r => item.bandCategories && item.bandCategories.includes(r));
    };
    this.usersService.meSubscribe(me => {
      if (me) {
        if (this.local) {
          this.locationService.hereSubscribe(location => {
            if (location) {
              this.rewardsService.getLocalRewards(this.usersService.getCurrentUserId(), location.coords.latitude, location.coords.longitude).then(result => {
                this.logService.write(`************* Got ${result.length} local rewards for ${location.coords.latitude}, ${location.coords.longitude}`);
                this.rewards = result.filter(filterFunc);
              });
            }
          });
        } else {
          this.rewardsService.getRewards(this.usersService.getCurrentUserId()).then(result => {
            this.rewards = result.filter(filterFunc);
          });
        }
      } else {
        this.rewardsService.getPublicRewards().then(result => {
          this.rewards = result.filter(filterFunc);
        });
      }
    });
  }

  rewardClicked(reward) {
    // this.clickedReward = reward;
    if (this.isLoggedIn) {
      this.router.navigate(['rewarddetail', reward.id]);
    }
  }

  detailDone() {
    this.clickedReward = undefined;
  }

  headerClicked() {
    this.router.navigate(['rewards']);
  }

  doneClicked(reward, action) {
    switch (action.type) {
      case 'doit':
        this.rewardClicked(reward);
    }
  }

}
