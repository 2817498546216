import {EventEmitter, Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {BehaviorSubject, Subscription} from 'rxjs';
import {FirestoreService} from '../firestore/firestore.service';
import {RadiusService} from '../radius/radius.service';
import {AccountsService} from '../accounts/accounts.service';
import {UsersService} from '../users/users.service';
import {WallitService} from '../wallit/wallit.service';

@Injectable({
  providedIn: 'root'
})
export class GoalsService {

  private goalsObservable: BehaviorSubject<any>;
  private userId: string;
  private goalsChangeObserver: Subscription;

  private memberGoalsObservable: BehaviorSubject<any>;
  private memberUserId: string;
  private memberGoalsChangeObserver: Subscription;

  private goalChangedEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(
      private restangular: Restangular,
      private firestoreService: FirestoreService,
      private radiusService: RadiusService,
      private accountsService: AccountsService,
      private usersService: UsersService,
      private wallitService: WallitService
  ) {
    this.goalsObservable = new BehaviorSubject(null);
    this.memberGoalsObservable = new BehaviorSubject(null);
  }

  notifyGoalChanged(id: string) {
    this.goalChangedEvent.emit(id);
  }

  monitorGoalChanged(func) {
    this.goalChangedEvent.subscribe(value => func(value));
  }

  getAll(userId: string): Promise<[any]> {
    this.userId = userId;
    if (this.goalsChangeObserver) {
      this.goalsChangeObserver.unsubscribe();
    }
    // this.goalsChangeObserver = this.firestoreService.goals.monitor( this.userId, _ => this.reload());
    return this.getAllHelper();
  }

  memberGetAll(userId: string): Promise<[any]> {
    this.memberUserId = userId;
    if (this.memberGoalsChangeObserver) {
      this.memberGoalsChangeObserver.unsubscribe();
    }
    // this.memberGoalsChangeObserver = this.firestoreService.goals.monitor( this.memberUserId, _ => this.reload());
    return this.memberGetAllHelper();
  }

  get(userId: string, goalId: string): Promise<any> {
    return this.restangular.one('v4').one('families', this.usersService.getCurrentFamilyId()).one('users', userId).all('goals').getList().toPromise().then((response) => {
      return response.find(goal => goal.goalId === goalId);
    });
  }

  getSharedGoal(goalId: string): Promise<any> {
    return this.restangular.one('v4').one('public').one('shared-goals', goalId).get().toPromise();
  }

  remove(userId: string, goalId: string): Promise<any> {
    return this.restangular.one('v4').one('families', this.usersService.getCurrentFamilyId()).one('users', userId).one('goals', goalId).remove().toPromise().then(data => {
      // this.firestoreService.goals.delete({
      //   forUserId: userId,
      //   id: goalId
      // });
      return data;
    });
  }

  add(userId: string, data: any): Promise<any> {
    return this.restangular.one('v4').one('families', this.usersService.getCurrentFamilyId()).one('users', userId).all('goals').post(data).toPromise().then(goal => {
      // this.firestoreService.goals.add({
      //  forUserId: userId,
      //  id: goal.id
      // });
      return goal;
    });
  }

  update(userId: string, goalId: string, data: any): Promise<any> {
    return this.restangular.one('v4').one('families', this.usersService.getCurrentFamilyId()).one('users', userId).one('goals', goalId).customPUT(data).toPromise().then(goal => {
      // this.firestoreService.goals.update({
      //  forUserId: userId,
      //  id: goalId
      // });
      return goal;
    });
  }

  updateImage(userId: string, goalId: string, image: any): Promise<any> {
    return this.restangular.one('v4').one('families', this.usersService.getCurrentFamilyId()).one('users', userId).one('goals', goalId)
        .all('image').customPUT(image, undefined, undefined, { 'content-type': 'image/*' }).toPromise();
  }

  reload() {
    this.goalsObservable.next(null);
    this.memberGoalsObservable.next(null);
    if (this.userId) {
      this.getAllHelper();
    }
    if (this.memberUserId) {
      this.memberGetAllHelper();
    }
  }

  private getAllHelper() {
    return this.restangular.one('v4').one('families', this.usersService.getCurrentFamilyId()).one('users', this.userId).one('goals').getList().toPromise().then((data) => {
      this.goalsObservable.next(data);
      return data;
    });
  }

  private memberGetAllHelper() {
    return this.restangular.one('v4').one('families', this.usersService.getCurrentFamilyId()).one('users', this.memberUserId).one('goals').getList().toPromise().then((data) => {
      this.memberGoalsObservable.next(data);
      return data;
    });
  }

  goalsSubscribe(response) {
    this.goalsObservable.subscribe(response);
  }

  memberGoalsSubscribe(response) {
    this.memberGoalsObservable.subscribe(response);
  }


/*
  getFundingSources(familyId: string, userId: string, goalId: string): Promise<any> {
    let sources = [];
    return this.accountsService.getPlaidTokens(familyId, this.usersService.getFamilyOwnerId()).then(info => {
      return this.radiusService.getFundingSource(familyId, userId).then(accounts => {
        sources = accounts;
        sources.forEach(source => {
          source.title = 'Account ' + source.counterparty.plaid.name;
          source.sourceType = 'Account';
          info.find(plaidInfo => {
            if (plaidInfo.balanceCache) {
              const bank = plaidInfo.balanceCache.accounts.find(account => account.account_id === source.counterparty.plaid.account_id);
              if (bank) {
                source.balance = bank.balances.current;
                source.sourceId = source.id;
                return true;
              }
              return false;
            }
          });
        });
        return goalId ? this.addGoalSources(sources, familyId, userId, goalId) : sources;
      }).catch(error => {
      }).then(_ => {
        return goalId ? this.addGoalSources(sources, familyId, userId, goalId) : sources;
      });
    }).catch(error => {
    }).then(_ => {
      return goalId ? this.addGoalSources(sources, familyId, userId, goalId) : sources;
    });
  }

 */

  getFundingSources(familyId: string, userId: string, goalId: string): Promise<any> {
    let sources = [];
    if (this.usersService.isChild()) {
      return this.accountsService.getSharedAccountsInfo(familyId, userId).then(info => {
          return this.accountsService.getSharedAccountsBalances(familyId, userId).then(accounts => {
            sources = info.sharedFundingSources.map(source => {
              const accountInfo = accounts.find(acc => acc.account_id === source.plaidAccountId);
              return { fundingSourceId: source.dwollaFundingSourceId, fundingSourceName: accountInfo.name, mask: accountInfo.mask };
            });
          });
      }).then(() => {
        return goalId ? this.addGoalSources(sources, familyId, userId, goalId) : sources;
      });
    } else {
      return this.accountsService.getPlaidDwollaAccounts(familyId, userId).then(response => {
        sources = response.filter(account => account.fundingSourceType === 'bank');
        sources.forEach(source => {
          source.title = 'Account ' + source.fundingSourceName;
          source.sourceType = 'Account';
          source.sourceId = source.fundingSourceId;
        });
        this.accountsService.getAccountBalances(familyId, userId).then(data => {
          sources.forEach(source => {
            source.balance = data.find(balance => source.fundingSourceId === balance.dwollaFundingSource.id).plaidAccount[0].balances.available;
          });
        });
      }).then(() => {
        return goalId ? this.addGoalSources(sources, familyId, userId, goalId) : sources;
      });
    }
  }

  getRewardDestinations(familyId: string, userId: string): Promise<any> {
    const sources = [];
    return this.addGoalSources(sources, familyId, userId, '');
  }

  private addGoalSources(sources, familyId: string, userId: string, goalId: string) {
    let promise;
    if (this.usersService.isChild(userId)) {
      promise = this.memberGetAll(userId);
    } else {
      promise = this.getAll(userId);
    }
    return promise.then((response) => {
      const clonedResponse = response.slice();
      clonedResponse.forEach(goal => {
        goal.title = 'Goal ' + goal.title;
        goal.balance = goal.balance.value;
        goal.sourceType = 'Goal';
        goal.sourceId = goal.goalId;
      });
      sources = sources.concat(clonedResponse.filter(source => source.goalId !== goalId));
      const wallitBalance = this.wallitService?.wallit?.accounts?.filter(account => account.fundingSourceType === 'balance')[0]?.balances?.current || 0;
      sources.unshift({title: 'My Wallit', sourceType: 'Wallit', sourceId: 'none', balance: wallitBalance});
      return sources;
    });
  }

  isAccountActiveForGoal(account): Promise<boolean> {
    return this.getAll(this.usersService.getCurrentUserId()).then(goals => {
      return goals.find(goal => goal.saveFrequency && goal.saveSource.account_id === account.account_id);
    });
  }

  isGoalFundingSource(goalId: string): Promise<boolean> {
    return this.getAll(this.usersService.getCurrentUserId()).then(goals => {
      return goals.find(goal => goal.saveFrequency && goal.fundingSourceId === goalId);
    });
  }

  depositToGoal(userId: string, goalId: string, fundingSourceType: string, fundingSourceId: string, amount: number, isSubAccount: boolean) {
    return this.restangular.one('v4').one('families', this.usersService.getCurrentFamilyId()).one('users', userId).one('goals', goalId).one('deposits').customPOST({
      fundingSourceType: fundingSourceType,
      fundingSourceId: fundingSourceId,
      amount: amount,
      isSubAccount: isSubAccount
    }).toPromise();
  }

  withdrawFromGoal(userId: string, goalId: string, fundingSourceType: string, fundingSourceId: string, amount: number, isSubAccount: boolean) {
    return this.restangular.one('v4').one('families', this.usersService.getCurrentFamilyId()).one('users', userId).one('goals', goalId).one('withdraws').customPOST({
      fundingSourceType: fundingSourceType,
      fundingSourceId: fundingSourceId,
      amount: amount,
      isSubAccount: isSubAccount
    }).toPromise();
  }

  attachToGoal(userId: string, goalId: string): Promise<any> {
    return this.restangular.one('v4').one('shared-goals', goalId).one('users', userId).customPUT().toPromise();
  }

  getGoalContributions(familyId: string, userId: string, goalId: string): Promise<any> {
    return this.restangular.one('v4').one('families', familyId).one('users', userId).one('goals', goalId).getList('transactions', {allTransactions: true}).toPromise();
  }

  getPublicGoalContributions(goalId: string): Promise<any> {
    return this.restangular.one('v4').one('public').one('shared-goals', goalId).getList('transactions').toPromise();
  }

}
