import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {ApiService} from '../services/api/api.service';
import {SessionService} from '../services/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
      public apiService: ApiService,
      private sessionService: SessionService,
      public router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.apiService.isAuthenticated()) {
      this.sessionService.loginUrl = state.url;
      this.router.navigate(['/SignIn']);
      return false;
    }
    return true;
  }

}
