import {Component, Input, OnInit} from '@angular/core';
import {HelpService} from '../../services/help/help.service';

@Component({
  selector: 'app-infopopup',
  templateUrl: './infopopup.component.html',
  styleUrls: ['./infopopup.component.scss'],
})
export class InfopopupComponent implements OnInit {

  @Input() position = 'top';
  @Input() width = '200px';
  @Input() top = 0;
  @Input() bottom = 0;
  @Input() left = 0;
  @Input() right = 0;

  protected tipsState;
  protected popupStyle: any = {};

  constructor(
      private helpService: HelpService
  ) { }

  ngOnInit() {
    this.tipsState = this.helpService.getTipsState();
    this.helpService.tipsStateChangedEvent.subscribe(state => {
      this.tipsState = state;
    });
    if (this.top) {
      this.popupStyle.top = this.top;
    }
    if (this.bottom) {
      this.popupStyle.bottom = this.bottom;
    }
    if (this.left) {
      this.popupStyle.left = this.left;
    }
    if (this.right) {
      this.popupStyle.right = this.right;
    }
    this.popupStyle.width = this.width;
  }

}
