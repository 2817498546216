import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {WallitService} from '../../services/wallit/wallit.service';
import {UsersService} from '../../services/users/users.service';
import {ApiService} from '../../services/api/api.service';
import {ContentService} from '../../services/content/content-service.service';
import {BaseComponent} from '../base/base.component';
import {BrandsService} from '../../services/brands/brands.service';

@Component({
  selector: 'app-homecta',
  templateUrl: './homecta.component.html',
  styleUrls: ['./homecta.component.scss'],
})
export class HomeCtaComponent extends BaseComponent implements OnInit {

  protected noAccount = false;
  protected message = this.contentService.getMessage('addAccountReminder');
  protected OLDUI_ENABLED = this.brandsService.getUIConfig().oldUi;

  constructor(
      private router: Router,
      private wallitService: WallitService,
      private usersService: UsersService,
      apiService: ApiService,
      private contentService: ContentService,
      private brandsService: BrandsService
  ) {
    super(apiService);
  }

  ngOnInit() {
    this.wallitService.monitorWallitChanged(wallit => {
      this.noAccount = wallit.accounts.length < 2;
     });
  }

  learnMoreClicked() {
    this.router.navigate(['learnmore']);
  }

  getStartedClicked() {
    this.router.navigate(['/Addbank', this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId()]);
  }
}
