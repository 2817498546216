import {Component, Input, OnInit} from '@angular/core';
import {GoalsService} from '../../services/goals/goals.service';
import {Router} from '@angular/router';
import {SessionService} from '../../services/session/session.service';
import {IonItemSliding} from '@ionic/angular';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {ToastComponent} from '../toast/toast.component';
import {Location} from '@angular/common';
import {UsersService} from '../../services/users/users.service';
import {listAnimator} from '../../services/animations/animations.service';
import {LedgerService} from '../../services/ledger/ledger.service';
import {ApiService} from '../../services/api/api.service';
import {ExperimentService} from '../../services/experiments/experiment.service';
import {ContentService} from '../../services/content/content-service.service';
import {LinkpreviewService} from '../../services/linkpreview/linkpreview.service';

@Component({
  selector: 'app-user-my-goals',
  templateUrl: './user-my-goals.component.html',
  styleUrls: ['./user-my-goals.component.scss'],
  // animations: listAnimator
})

export class OurlyUserMyGoalsComponent implements OnInit {

  @Input() userId = '';
  @Input() familyId = '';

  private spinnerLevel;
  protected noGoalsMessage = this.contentService.getMessage('noGoalsMessage');
  protected noGoalsButtonLabel = this.contentService.getMessage('noGoalsButtonLabel');

  protected goals;

  constructor(
      private goalsService: GoalsService,
      private router: Router,
      private sessionService: SessionService,
      private uiAlertService: UiAlertService,
      private toastComponent: ToastComponent,
      private ledgerService: LedgerService,
      private apiService: ApiService,
      private usersService: UsersService,
      private contentService: ContentService,
      private linkpreviewService: LinkpreviewService,
  ) { }


  ngOnInit() {
    this.apiService.spinnerSubscribe(val => this.spinnerLevel = val);
    if (!this.usersService.isOnboardingCompleted()) {
      return;
    }
    this.goalsService.getAll(this.userId).then((response) => {
      this.loadGoals(response);
    });

    this.goalsService.goalsSubscribe({
      next: (data: any) => {
        this.goals = [];
        this.goals = data;
      }
    });
  }

  private loadGoals(goals) {
    this.goals = this.sorted(goals);
    this.goals.forEach(goal => {
      this.getGoalImage(goal);
    });
  }

  getGoalImage(goal) {
    if (goal.url && !goal.goalImage) {
      return this.linkpreviewService.getInfo(goal.url).then( info => {
        goal.goalImage = info['image'] ? info['image'] : goal.url;
      }).catch(error => {
        console.error('error in  get goal image', error);
        goal.goalImage = goal.url ? goal.url : '';
      });
    }
  }

  addYourFirstGoal(event) {
    event.stopPropagation();
    this.router.navigate([ '/AddAGoal', this.userId ]);
  }

  currentGoalValue(goal) {
    return goal?.balance?.value;
  }

  sorted(goals) {
    return goals.sort((a, b) => a.title < b.title);

  }

  goToGoal(goal) {
    this.router.navigate([ '/GoalDetail', this.familyId, this.userId, goal.goalId ]);
  }

  goToAllGoals() {
    this.router.navigate([ '/goals', this.familyId, this.userId ]);
  }

  removeGoal(sliding: IonItemSliding, goal) {
    this.uiAlertService.presentAlertConfirm('Do you really want to remove this goal?').then(confirm => {
      sliding.close();
      if (confirm) {
        this.goalsService.remove(this.userId, goal.goalId).then((response) => {
              this.goalsService.reload();
              this.toastComponent.presentToast('Goal removed');
            }
        ).catch((error) => {
          this.toastComponent.presentError(error);
        });
      }
    });
  }

}
