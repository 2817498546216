import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective {

  allowNegative = false;
  // Allow decimal numbers. The \. is only allowed once to occur
  private regex: RegExp = new RegExp(/^[0-9]+(\.[0-9]*){0,1}$/g);

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];

  constructor(private el: ElementRef) {
  }

  // Prevent user to enter anything but digits and decimal separator
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    // Do not use event.keycode this is deprecated.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    const charList = '.0123456789';
    const keyx = event.key.toLowerCase();
    // we are only interested in alphanumeric keys
    if (charList.indexOf(keyx) === -1) { event.preventDefault(); return; }
  }

  /*@HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    if (event.key === 'Unidentified') {
      event.preventDefault();
      return false;
    }
    // Do not use event.keycode this is deprecated.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
    const current: string = this.el.nativeElement.value;
    console.log(current, 'currentval');
    // We need this because the current value on the DOM element
    // is not yet updated with the value from this event
    // const next: string = current.concat(event.key); // no concatenation
    const next: string = event.key;
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }*/

}
