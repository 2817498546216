import {
  Component,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  OnInit,
  Input,
  HostListener
} from '@angular/core';
import { D3chartsService } from '../../services/charts/d3charts.service';
import { HttpClient } from '@angular/common/http';
import { interval, Subscription } from 'rxjs';
import * as Highcharts from 'highcharts';
import * as moment from 'moment/moment';
import { UsersService } from '../../services/users/users.service';

import {GoalsService} from '../../services/goals/goals.service';
import {AppSettings} from '../../app.settings';
import {ApiService} from '../../services/api/api.service';

declare var require: any;
const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
import Glide from '@glidejs/glide';

const colors = ['#3B97B2', '#67BC42', '#FF56DE', '#E6D605', '#BC36FE', 'whitesmoke'];

@Component({
  selector: 'app-glide',
  templateUrl: './glide.component.html',
  styleUrls: ['./glide.component.scss'],
})
export class GlideComponent implements OnInit, AfterViewInit {

  @Input() info;
  @Input() familyId;
  @Input() userId;

  private spinnerLevel;

  protected goals = [];

  private goalsLength = [];

  private dataEarnSpent = [];

  private htmlGoals = '';
  private htmlGolasButtons = '';

  private glide;
  private chart;


  public options: any = {
    chart: {
      type: 'column',
      height: 170,
    },
    title: {
      text: 'Transfers Out Wallit'
    },
    credits: {
      enabled: false
    },
    xAxis: {
    categories: ['Earned / Spent']
    },
    yAxis: {
    min: 0,
    title: {
      text: 'Total earned / kept'
    },
    stackLabels: {
      enabled: true,
      style: {
        fontWeight: 'bold',
        color: 'gray'
      }
    }
  },
  legend: {
    align: 'right',
    x: -30,
    verticalAlign: 'top',
    y: 25,
    floating: true,
    backgroundColor: 'white',
    borderColor: '#CCC',
    borderWidth: 1,
    shadow: false
  },
  tooltip: {
    headerFormat: '<b>{point.x}</b><br/>',
    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: true,
        color: 'white'
      }
    }
  },
  responsive: {
    rules: [{
        condition: {
            maxWidth: 395
        }
      }]
    },
  series: [{
    name: 'Spent',
    data: [20],
    color: colors[5]
  },
  {
    name: 'Kept',
    data: [30],
    color: colors[1]
  }]
  };

  constructor(
    private goalsService: GoalsService,
    private usersService: UsersService,
    private http: HttpClient,
    private d3ChartsService: D3chartsService,
    private apiService: ApiService
    ) { }

  ngOnInit() {
    // *(this.info, 1, this.familyId, this.userId)
    // for this user and family id find data
  }

  saveInstance(chartInstance: any) {
    this.chart = chartInstance;
    setTimeout(() => {
      this.chart.reflow();
    }, 0);
  }

  ngAfterViewInit() {

    setTimeout(() => { this.glide = new Glide('.glide_' + this.userId).mount(); });

    if (this.info && this.info.user) {
      this.userId = this.info.userId;
      // this.earnKepUserId = this.info.userId;
      // this.earnKepFamilyId = this.info.familyId;
      const isOtherUser = this.info.userId !== this.usersService.me().user.id;
      let promise;
      if (isOtherUser) {
        this.goals = [];
        promise = this.goalsService.memberGetAll(this.info.user.id || this.info.user.userId);
      } else {
        this.goals = [];
        promise = this.goalsService.getAll(this.info.user.id || this.info.user.userId);
      }
      promise.then((response) => {
        this.goals = response;
        if (isOtherUser) {
          this.goalsService.memberGoalsSubscribe(result => {
            if (result) {
              setTimeout(() => {
                this.goals = result;
                setTimeout(() => { this.glide = new Glide('.glide_' + this.userId).mount(); });
              });
            }
          });
        } else {
          this.goalsService.goalsSubscribe(result => {
            if (result) {
              setTimeout(() => {
                this.goals = result;
                setTimeout(() => { this.glide = new Glide('.glide_' + this.userId).mount(); });
              });
            }
          });
        }
      }).catch(error => {

      });
    }
    this.d3ChartsService.getEarnedKept(this.userId, this.familyId, moment().format('YYYY-MM')).then((response) => {

      // response is returned as : { earned: 20, spent: 0 }
      if ( response.length === 0) {
          this.options.series[0]['data'] = [0];
          this.options.series[1]['data'] = [0];
          Highcharts.chart('chart_' + this.userId, this.options);
          setTimeout(() => { this.glide = new Glide('.glide_' + this.userId).mount(); });
      } else {
          this.options.series[0]['data'] = [parseFloat(response.earned)  - parseFloat(response.spent)] ;
          this.options.series[1]['data'] = [parseFloat(response.earned)] ;
          Highcharts.chart('chart_' + this.userId, this.options);
          setTimeout(() => { this.glide = new Glide('.glide_' + this.userId).mount(); });
      }
    });
    this.apiService.spinnerSubscribe(val => this.spinnerLevel = val);
  }

}
