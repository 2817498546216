import { Component, OnInit, SimpleChanges, OnChanges, forwardRef, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormControl,
  Validator,
  Validators,
  AbstractControl,
  ValidationErrors } from '@angular/forms';
import {errors} from './ssn-errors';
import {FormfieldbaseComponent, setTimeoutPromise} from '../formfieldbase/formfieldbase.component';

/**
^                                       #Start of expression
(?!\b(\d)\1+-(\d)\1+-(\d)\1+\b)         #Don't allow all matching digits for every field
(?!123-45-6789|219-09-9999|078-05-1120) #Don't allow "123-45-6789", "219-09-9999" or "078-05-1120"
(?!666|000|9\d{2})\d{3}                 #Don't allow the SSN to begin with 666, 000 or
                                         anything between 900-999
-                                       #A dash (separating Area and Group numbers)
(?!00)\d{2}                             #Don't allow the Group Number to be "00"
-                                       #Another dash (separating Group and Serial numbers)
(?!0{4})\d{4}                           #Don't allow last four digits to be "0000"
$                                       #End of expression

This expression will not allow a Social Security Number that:

Contains all zeroes in any specific group (ie 000-##-####, ###-00-####, or ###-##-0000)
Begins with ’666′
Begins with any value from ’900-999′
Is equal to ’078-05-1120′ (due to the Woolworth’s Wallet Fiasco)
Is equal to ’219-09-9999′ (appeared in an advertisement for the Social Security Administration)
and:

Contains all matching values (i.e., 000-00-0000, 111-11-1111, 222-22-2222, etc.)
Contains all incrementing values (i.e., 123-45-6789)

*/

const SSN_DASH_REGEX = new RegExp('^(?!\\b(\\d)\\1+-(\\d)\\1+-(\\d)\\1+\\b)(?!123-45-6789|219-09-9999|078-05-1120)(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$');

const SSN_NODASH_REGEX = new RegExp('^(?!\\b(\\d)\\1+\\b)(?!123456789|219099999|078051120)(?!666|000|9\\d{2})\\d{3}(?!00)\\d{2}(?!0{4})\\d{4}$');

const SSN_LAST_FOUR_REGEX = new RegExp('^(?!0{4})\\d{4}$');

@Component({
  selector: 'app-ssn-field',
  templateUrl: './ssn-field.component.html',
  styleUrls: ['../formfieldbase/formfieldbase.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SsnFieldComponent),
      multi: true
    },
    {
     provide: NG_VALIDATORS,
     useExisting: forwardRef(() => SsnFieldComponent),
     multi: true
   }
 ]
})
export class SsnFieldComponent extends FormfieldbaseComponent implements OnChanges, ControlValueAccessor, Validator {

  @Input() fullFormatSSN = false;
  @Input() requiredSSN = false;
  @Input() dash = true;

  // min and max number length for validators
  private minLenSSNNumber: number;
  private maxLenSSNNumber: number;

  constructor(private cd: ChangeDetectorRef) {
    super('ssn');
    this.fieldForm = new FormGroup(
      {
        ssn: new FormControl('')
      });
  }

    registerOnChange(fn: any): void {
        this.fieldForm.valueChanges.subscribe((fnx) => {
            if (this.fieldForm.valid) {
                // *('validForm');
                this.valueChange.emit(fnx.ssn);
            } else {
                this.valueChange.emit('');
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void { // *(changes, 'chngs', this.fieldForm);
    this.cd.detectChanges();
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    if (this.fullFormatSSN && this.dash) {
      this.minLenSSNNumber = 11; // we use format AAA-GG-SSSS -> length = 11 chars
      this.maxLenSSNNumber = 11; // we use format AAA-GG-SSSS -> length = 11 chars
    } else if (this.fullFormatSSN && !this.dash) {
      this.minLenSSNNumber = 9; // we use format AAAGGSSSS -> length = 9 chars
      this.maxLenSSNNumber = 9; // we use format AAAGGSSSS -> length = 9 chars
    } else {
      this.minLenSSNNumber = 4; // we use format SSSS -> length = 4 chars
      this.minLenSSNNumber = 4; // we use format SSSS -> length = 4 chars
    }
    if (this.fullFormatSSN  && changes.hasOwnProperty('fullFormatSSN') && changes.fullFormatSSN.isFirstChange()
    && this.dash && this.requiredSSN && this.minLenSSNNumber === 11) { // FULL && DASH && REQUIRED
        this.fieldForm.get(this.thisFieldName).setValidators([
          Validators.required,
          Validators.minLength(this.minLenSSNNumber), // override constructor
          Validators.maxLength(this.maxLenSSNNumber),
          Validators.pattern(SSN_DASH_REGEX)
        ]);
    }
    if (this.fullFormatSSN  && changes.hasOwnProperty('fullFormatSSN') && changes.fullFormatSSN.isFirstChange()
    && this.dash && !this.requiredSSN && this.minLenSSNNumber === 11) { // FULL && DASH && NO REQUIRED
        this.fieldForm.get(this.thisFieldName).setValidators([
          Validators.minLength(this.minLenSSNNumber), // override constructor
          Validators.maxLength(this.maxLenSSNNumber),
          Validators.pattern(SSN_DASH_REGEX)
        ]);
    }
    if (this.fullFormatSSN  && changes.hasOwnProperty('fullFormatSSN') && changes.fullFormatSSN.isFirstChange()
    && !this.dash && this.requiredSSN && this.minLenSSNNumber === 9) { // FULL && NO DASH && REQUIRED
        this.fieldForm.get(this.thisFieldName).setValidators([
          Validators.required,
          Validators.minLength(this.minLenSSNNumber), // override constructor
          Validators.maxLength(this.maxLenSSNNumber),
          Validators.pattern(SSN_NODASH_REGEX)
        ]);
    }
    if (this.fullFormatSSN  && changes.hasOwnProperty('fullFormatSSN') && changes.fullFormatSSN.isFirstChange()
    && !this.dash && !this.requiredSSN && this.minLenSSNNumber === 9) { // FULL && NO DASH && NO REQUIRED
        this.fieldForm.get(this.thisFieldName).setValidators([
          Validators.minLength(this.minLenSSNNumber), // override constructor
          Validators.maxLength(this.maxLenSSNNumber),
          Validators.pattern(SSN_NODASH_REGEX)
        ]);
    }
    //  ELSE we need 4 digits validation
    if (!this.fullFormatSSN  && changes.hasOwnProperty('fullFormatSSN') && changes.fullFormatSSN.isFirstChange()
    && !this.requiredSSN && this.minLenSSNNumber === 4) { // NO FULL && NO REQUIRED
        this.fieldForm.get(this.thisFieldName).setValidators([
          Validators.minLength(this.minLenSSNNumber), // override constructor
          Validators.maxLength(this.maxLenSSNNumber),
          Validators.pattern(SSN_LAST_FOUR_REGEX)
        ]);
    }
    if (!this.fullFormatSSN  && changes.hasOwnProperty('fullFormatSSN') && changes.fullFormatSSN.isFirstChange()
    && this.requiredSSN && this.minLenSSNNumber === 4) { // NO FULL && REQUIRED
        this.fieldForm.get(this.thisFieldName).setValidators([
          Validators.required,
          Validators.minLength(this.minLenSSNNumber), // override constructor
          Validators.maxLength(this.maxLenSSNNumber),
          Validators.pattern(SSN_LAST_FOUR_REGEX)
        ]);
    }
    if (this.getVal && changes.getVal.firstChange !== null && changes.getVal.firstChange !== undefined && this.getVal !== '') {
      this.formatSSN(this.getVal);
      this.cd.detectChanges();
    }
    if (this.getVal && this.getVal !== '') {
      if (changes.getVal.previousValue !== changes.getVal.currentValue) {
        this.fieldForm.get(this.thisFieldName).setValue(changes.getVal.currentValue);
      }
    }
  }
  async formatSSN(ssn: string) {
    // format given SSN to right format before set to input field
    const ssnnumber = ssn.replace(/-/g, '');
    const ssnlen = ssnnumber.length;
    // if max and min length == 11 we need number formated with dashes
    if (ssnlen === 9 && this.minLenSSNNumber === 11) {
        const formatedSSN = ssnnumber.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
        await setTimeoutPromise(100).then(res => {
          this.fieldForm.get(this.thisFieldName).setValue(formatedSSN);
        });
    } else if (ssnlen === 9 && this.minLenSSNNumber === 9) { // full format ssn no dashes
        await setTimeoutPromise(100).then(res => {
          this.fieldForm.get(this.thisFieldName).setValue(ssnnumber);
        });
    } else if (ssnlen === 4 && this.minLenSSNNumber === 4) { // only last 4
        await setTimeoutPromise(100).then(res => {
          this.fieldForm.get(this.thisFieldName).setValue(ssnnumber);
        });
    } else { // we have wrong getVal and we need to set field to ''
        await setTimeoutPromise(100).then(res => {
          this.fieldForm.get(this.thisFieldName).setValue('');
        });
    }
  }

    validate(c: AbstractControl): ValidationErrors | null {
        const controls = this.fieldForm.controls;
        Object.keys(controls).forEach(
            (controlName) => { // *(controls[controlName].errors);
                if (controls[controlName].errors !== null) {
                    this.required = controls[controlName].errors.hasOwnProperty('required');
                    this.minLength = controls[controlName].errors.hasOwnProperty('minlength');
                    this.maxLength = controls[controlName].errors.hasOwnProperty('maxlength');
                    this.invalid = controls[controlName].errors.hasOwnProperty('pattern');
                } else {
                    this.required = false;
                    this.minLength = false;
                    this.maxLength = false;
                    this.invalid = false;
                }
            });
        let message = '';
        if (this.required) { message = errors.required; }
        if (this.invalid && this.minLenSSNNumber === 11) {
            message = errors.invalidFull;
        } else if (this.invalid && this.minLenSSNNumber === 4) {
            message = errors.invalidLast;
        } else if (this.invalid && this.minLenSSNNumber === 9) {
            message = errors.invalidFullNoDash;
        }
        if (this.minLength && this.minLenSSNNumber === 11) {
            message = errors.fullError;
        } else if (this.minLength && this.minLenSSNNumber === 4) {
            message = errors.lastError;
        } else if (this.minLength && this.minLenSSNNumber === 9) {
            message = errors.fullNoDashError;
        }
        if (this.maxLength && this.minLenSSNNumber === 11) {
            message = errors.fullError;
        } else if (this.maxLength && this.minLenSSNNumber === 4) {
            message = errors.lastError;
        } else if (this.maxLength && this.minLenSSNNumber === 9) {
            message = errors.fullNoDashError;
        }
        this.errorMessage = message;
        return this.fieldForm.valid ? null : { invalidForm: {valid: false, message: message}};
    }

}
