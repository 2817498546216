import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/api/api.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit {

  protected isLoggedIn;
  protected onLoggedInChange;

  constructor(
      private apiService: ApiService
  ) {
    this.apiService.monitorLoginState(loggedIn => {
      this.isLoggedIn = loggedIn;
      if (this.onLoggedInChange) {
        this.onLoggedInChange(loggedIn);
      }
    });
  }

  ngOnInit() {
  }

}
