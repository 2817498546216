import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FormGroup} from '@angular/forms';
import {ClipboardServiceProvider} from '../../../services/clipboard/clipboard.service';

@Component({
  selector: 'app-reset-magic-code',
  templateUrl: './reset-magic-code.component.html',
  styleUrls: ['./reset-magic-code.component.scss'],
})
export class ResetMagicCodeComponent implements OnInit {

  @Output() buttonEvent: EventEmitter<number> = new EventEmitter<number>();
  @Input() magicCode;

  constructor(
      private modalController: ModalController,
      private clipboardService: ClipboardServiceProvider,
  ) { }

  ngOnInit() {
  }

  buttonClicked(buttonCode) {
    if (buttonCode === 2) {
      this.clipboardService.copy(this.magicCode);
    }
    this.buttonEvent.emit(buttonCode);
  }

}
