import { Component, OnInit, SimpleChanges, OnChanges, ChangeDetectorRef, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormControl,
  Validator,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import {errors} from './state-errors';
import {FormfieldbaseComponent} from '../formfieldbase/formfieldbase.component';

@Component({
  selector: 'app-state-field',
  templateUrl: '../formfieldbase/formfieldbase.component.html',
  styleUrls: ['../formfieldbase/formfieldbase.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StateFieldComponent),
      multi: true
    },
    {
     provide: NG_VALIDATORS,
     useExisting: forwardRef(() => StateFieldComponent),
     multi: true
   }
 ]
})

export class StateFieldComponent extends FormfieldbaseComponent implements OnChanges, ControlValueAccessor, Validator   {

  constructor(private cdRef: ChangeDetectorRef) {
    super('state');
    this.fieldForm = new FormGroup(
      {
        state: new FormControl('',
        [ Validators.minLength(2),
          Validators.maxLength(2),
          Validators.pattern('[A-Z]*')
        ]),
      });
      this.fieldForm.get('state').setValue('');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.minValue  && changes.hasOwnProperty('minValue') && changes.minValue.isFirstChange() ) {
      if (parseInt(this.minValue, 10) > 0) { // value must be greater than zero always
        this.minLen = parseInt(this.minValue, 10); // override with custom min
      } else {
        this.minLen = 2;
      }
      if (/*this.maxLen > this.minLen && */this.requiredField) {
        this.fieldForm.get('state').setValidators([
          Validators.required,
          Validators.minLength(this.minLen), // override constructor
          Validators.maxLength(this.maxLen),
          Validators.pattern('[A-Z]*')
        ]);
      } else if (this.maxLen > this.minLen && !this.requiredField) {
        this.fieldForm.get(this.thisFieldName).setValidators([
          Validators.minLength(this.minLen), // override constructor
          Validators.maxLength(this.maxLen),
          Validators.pattern('[A-Z]*')
        ]);
      }
    }
    if (this.maxValue  && changes.hasOwnProperty('maxValue') && changes.maxValue.isFirstChange() ) {
      if (parseInt(this.maxValue, 10) > 0) { // value must be greater than zero always
        this.maxLen = parseInt(this.maxValue, 10); // override with custom max
      } else {
        this.maxLen = 2;
      }
      if (parseInt(this.maxValue, 10) > this.minLen && this.requiredField) {
        this.fieldForm.get(this.thisFieldName).setValidators([
          Validators.required,
          Validators.minLength(this.minLen),
          Validators.maxLength(this.maxLen), // override constructor
          Validators.pattern('[A-Z]*')
        ]);
      } else if (parseInt(this.maxValue, 10) > this.minLen && !this.requiredField) {
        this.fieldForm.get('state').setValidators([
          Validators.minLength(this.minLen),
          Validators.maxLength(this.maxLen), // override constructor
          Validators.pattern('[A-Z]*')
        ]);
      }
    }
    if (this.getVal && changes.getVal.firstChange !== null && changes.getVal.firstChange !== undefined && this.getVal !== '' ) {
      this.fieldForm.get(this.thisFieldName).setValue(this.getVal);
    } else if (changes.getVal.isFirstChange() && (this.getVal === '' || !this.getVal) ) {
      this.setValue('');
    }
    if (this.getVal && this.getVal !== '') {
      if (parseFloat(changes.getVal.previousValue) !== parseFloat(changes.getVal.currentValue)) {
        this.fieldForm.get(this.thisFieldName).setValue(changes.getVal.currentValue);
      }
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    const controls = this.fieldForm.controls;
    Object.keys(controls).forEach(
        (controlName) => {
          if (controls[controlName].errors !== null) {
            this.required = controls[controlName].errors.hasOwnProperty('required');
            this.minLength = controls[controlName].errors.hasOwnProperty('minlength');
            this.maxLength = controls[controlName].errors.hasOwnProperty('maxlength');
            this.invalid = controls[controlName].errors.hasOwnProperty('pattern');
          } else {
            this.required = false;
            this.minLength = false;
            this.maxLength = false;
            this.invalid = false;
          }
        });
    let message = '';
    if (this.required) { message = errors.required; }
    if (this.minLength) { message = errors.minLength; }
    if (this.maxLength) { message = errors.maxLength; }
    if (this.invalid) { message = errors.invalid; }
    this.errorMessage = message;
    return this.fieldForm.valid ? null : { invalidForm: {valid: false, message: message}};
  }

}
