import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import * as moment from 'moment/moment';

@Injectable({
  providedIn: 'root'
})
export class SmartbalanceService {

  lookbackDays = 30;

  constructor(
      private restangular: Restangular
  ) { }

  async getTeamTransactions(userId: string, bankIntegrationId: string = '') {
    let baseRequest = this.restangular.one('v4').one('users', userId);
    if (bankIntegrationId) {
      baseRequest = baseRequest.one('accounts', bankIntegrationId);
    }
    return baseRequest.one('plaid').getList('transaction-history', {status: 6}).toPromise();
  }

  getRecurringTransactions(userId: string, bankIntegrationId: string = '') {
    let baseRequest = this.restangular.one('v4').one('users', userId);
    if (bankIntegrationId) {
      baseRequest = baseRequest.one('accounts', bankIntegrationId);
    }
    return baseRequest.one('plaid').getList('transaction-history', {filter: 'recurrent'}).toPromise();
  }

  setRecurringTransaction(userId: string, transactionId: string, recurring: boolean): Promise<any> {
    return this.restangular.one('v4').one('users', userId).one('plaid', transactionId).one('transaction-history').one('recurrence', recurring).customPUT().toPromise();
  }

  setReimburseTransaction(userId: string, transactionId: string, teamId: string): Promise<any> {
    return this.restangular.one('v4').one('users', userId).one('plaid', transactionId).one('transaction-history').one('reimburse').customPUT({teamId}).toPromise();
  }

  setEligibleTransactions(userId: string, transactionIds: Array<string>, partialAmount = 0, bankId, teamId): Promise<any> {
    return this.restangular.one('v4').one('users', userId).one('plaid').one('transaction-history').one('eligible').customPUT({partialAmount, bankId, teamId, transactionIds}).toPromise();
  }

  setDeclinedTransaction(userId: string, transactionId: string): Promise<any> {
    return this.restangular.one('v4').one('users', userId).one('plaid', transactionId).one('transaction-history').one('declined').customPUT().toPromise();
  }

  setRecurringTransactionFrequency(userId: string, transactionId: string, frequency: string): Promise<any> {
    // frequency: weekly, every-other-week, twice-a-month, monthly, quarterly, annually
    return this.restangular.one('v4').one('users', userId).one('plaid', transactionId).one('transaction-history').one('recurrence-type', frequency).customPUT().toPromise();
  }

  getSmartBalances(familyId: string, userId: string, bankIntegrationId: string = ''): Promise<any> {
    let baseRequest = this.restangular.one('v4').one('families', familyId).one('users', userId);
    if (bankIntegrationId) {
      baseRequest = baseRequest.one('accounts', bankIntegrationId);
    }
    return baseRequest.one('plaid').customGET('smart-balances', {beginAt: '', endAt: moment().add(this.lookbackDays, 'days').format('YYYY-MM-DD'), cache: ''}).toPromise();
  }

}
