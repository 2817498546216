import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';

import { PushNotifications } from '@capacitor/push-notifications';

import {Platform} from '@ionic/angular';
// import { FCM } from '@capacitor-community/fcm';

@Injectable({
  providedIn: 'root'
})
export class PushnotificationsService {

  static initCompleted = false;

  private brandTopic;
  private notificationFunc;

  constructor(
    private restangular: Restangular,
    private platform: Platform,
  ) {}

  init(userId: string) {
    if (!this.platform.is('cordova')) {
        this.setToken(userId, '');
        return;
    }
    PushNotifications.requestPermissions().then(response => {
        if (response.receive === 'granted') {
            PushNotifications.register().catch(error => { });
            PushNotifications.addListener('registration',
                token => {
                    console.log('Push registration success, token: ' + token.value);
                }
            );
            PushNotifications.addListener('registrationError',
                (error: any) => {
                    console.log('Error on registration: ' + JSON.stringify(error));
                }
            );
            PushNotifications.addListener('pushNotificationReceived',
                notification => {
                    console.log('********************************Push received: ' + JSON.stringify(notification));
                }
            );
            PushNotifications.addListener('pushNotificationActionPerformed',
                notification => {
                    this.notificationFunc(notification.notification.data.aps.alert.title, notification.notification.data.aps.alert.body);
                    console.log('Push action performed: ' + JSON.stringify(notification));
                }
            );
            console.log('pushnotifications listeners added')
            if (!PushnotificationsService.initCompleted) {
                console.log('pushnotifications init not completed')
                PushnotificationsService.initCompleted = true;
                console.log('initializing back end');
                /*
                FCM.getToken().then(r => {
                    console.log('++++++++++++++++++++++++token', r.token)
                    this.setToken(userId, r.token);
                    FCM.subscribeTo({ topic: 'everyone' });
                }).catch(err => console.log(err));
                 */
            }
        }
    });
  }

  resetBadgeCount() {
      if (this.platform.is('cordova')) {
          PushNotifications.removeAllDeliveredNotifications();
      }
  }

  setBrand(brand: string, notificationFunc: (title: string, body: string) => void) {
      if (!this.platform.is('cordova')) {
          return;
      }
      if (this.brandTopic) {
          // FCM.unsubscribeFrom({topic: this.brandTopic});
      }
      this.brandTopic = brand;
      this.notificationFunc = notificationFunc;
      // FCM.subscribeTo({topic: brand});
  }

  setToken(userId: string, token: string): Promise<any> {
      if (token) {
          return this.restangular.one('v3').one('gcm', userId).one('token', token).customPUT().toPromise();
      }
  }

  setReceiveMessages(userId: string, receiveMessages: boolean): Promise<any> {
    return this.restangular.one('v3').one('gcm', userId).one('messages').one('allow', receiveMessages).customPUT().toPromise();
  }

  getConfig(userId: string): Promise<any> {
      return this.restangular.one('v3').one('gcm', userId).one('config').get().toPromise();
  }

}
