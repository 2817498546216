import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {RewardsService} from '../../services/rewards/rewards.service';
import {UsersService} from '../../services/users/users.service';

@Component({
  selector: 'app-cashback',
  templateUrl: './cashback.component.html',
  styleUrls: ['./cashback.component.scss'],
})
export class CashbackComponent implements OnInit, OnChanges {

  @Input() refresh;

  protected rebates = [];
  private me;
  private totalCashback = 0;

  constructor(
      private rewardsService: RewardsService,
      private usersService: UsersService,
  ) { }

  ngOnInit() {
    this.usersService.meSubscribe(me => {
      this.me = me;
      this.loadRebates();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadRebates();
  }

  loadRebates() {
    if (this.me) {
      this.rebates = [];
      this.totalCashback = 0;
      this.rewardsService.getRewardTransactions(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId()).then(campaignMatches => {
        campaignMatches.forEach(campaignMatch => {
          campaignMatch.userTransactions.forEach(transactions => {
            transactions.forEach(transaction => {
              this.totalCashback += transaction.amount;
            });
            this.rebates = this.rebates.concat(transactions.map(transaction =>  ({
              date: transaction.date,
              name: transaction.name,
              amount: transaction.amount,
              category: transaction.category,
              account_id: transaction.account_id,
              transaction_id: transaction.transaction_id
            })));
          });
        });
      });
    }
  }

  getAmount(rebate) {
    return rebate.amount / 100;
  }

  getDate(rebate) {
    return rebate.date;
  }

  getTitle(rebate) {
    return rebate.name;
  }

  gotoRebate(rebate) {

  }

  claimRebate(rebate) {
    this.rewardsService.claimRewardTransaction(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId(), rebate.id).then(_ => {

    });
  }

}
