import {Component, Input, OnInit} from '@angular/core';

export enum AdContext {
  HOME_PAGE_TOP = 'AppHomePageTop',
  ACCOUNTS_PAGE_TOP = 'AppAccountsPageTop',
  EXPLORE_PAGE_TOP = 'AppExplorePageTop',
  ACTIVITY_PAGE_TOP = 'AppActivityPageTop'
}

@Component({
  selector: 'app-integration-ad-carousel',
  templateUrl: './integration-ad-carousel.component.html',
  styleUrls: ['./integration-ad-carousel.component.scss'],
})
export class IntegrationAdCarouselComponent implements OnInit {

  @Input() contextId: AdContext;

  protected content;

  constructor() { }

  ngOnInit() {

  }

}
