import {Component, ContentChild, ElementRef, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit {

  @ContentChild('footer', {static: false}) footer: ElementRef;

  constructor(private router: Router) { }

  public cssFixClassName: string;

  ngOnInit() {
    this.setupCssClassName();
  }

  setupCssClassName(): void {
    if (this.router.url.includes('/EditProfile/primary')) {
      this.cssFixClassName = 'outer-height-profile-fix';
    } else if (this.router.url.includes('/BankDetail')) {
      this.cssFixClassName = 'outer-height-bank-details-fix';
    } else {
      this.cssFixClassName = '';
    }
  }
}
