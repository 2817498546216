import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss'],
})
export class FileuploadComponent implements OnInit {

  @Input() requiredFileType: string;
  @Output() doneEvent: EventEmitter<any> = new EventEmitter<any>();

  fileName = '';

  constructor(
  ) {}

  ngOnInit() {}

  onFileSelected(event) {
    const file: File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        this.doneEvent.emit(reader.result);
      };
      reader.readAsArrayBuffer(file);
    }
  }

}
