import {Component, Input, OnInit} from '@angular/core';
import { ModalController } from '@ionic/angular';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-pdf-modal',
  templateUrl: './pdf-modal.component.html',
  styleUrls: ['./pdf-modal.component.scss'],
})
export class PdfModalComponent implements OnInit {
  @Input() pdfDocuments: string[] = []; // array of URLs/base64 strings
  @Input() title;

  private sanitizedPdfDocuments;
  currentIndex = 0;

  constructor(
      private modalCtrl: ModalController,
      private domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.sanitizedPdfDocuments = this.pdfDocuments.map(pdf =>
        this.domSanitizer.bypassSecurityTrustResourceUrl(pdf)
    );
  }

  get currentDocument() {
    return this.sanitizedPdfDocuments[this.currentIndex];
  }

  nextDocument() {
    if (this.currentIndex < this.pdfDocuments.length - 1) {
      this.currentIndex++;
    } else {
      this.modalCtrl.dismiss({dismissedBy: 'done'}); // All done, close modal
    }
  }

  closeModal() {
    this.modalCtrl.dismiss({dismissedBy: 'c'});
  }
}
