import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmValidator} from '../../../validators/confirm-validator';
import {FieldbaseComponent} from '../fieldbase/fieldbase.component';

@Component({
  selector: 'app-get-email',
  templateUrl: './get-email.component.html',
  styleUrls: ['./get-email.component.scss']
})
export class GetEmailComponent extends FieldbaseComponent implements OnInit {

  constructor(
      private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.parent.addControl('email', this.formBuilder.control('', [ Validators.required, Validators.email ]));
    this.parent.addControl('confirmemail', this.formBuilder.control('', [ Validators.required ]));
    ConfirmValidator.twoFieldsEqual(this.parent, 'email', 'confirmemail');
  }

}
