import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  public tips = {
    accounts_carousel: 'Tap the arrows to shuffle between your balance cards.',
    accounts_carousel_arrows: 'Monitor your cash balances in Wallit.<br><br>',
    home_featured: 'Access automatic cash back offers by local retailers and national brands all over the US.',
    home_local: 'Wallit\'s interactive map uses your location to show nearby offers. Click the map and the page will load with all current offers near you.',
  };

  private state = false;
  public tipsStateChangedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  getTipsState() {
    return this.state;
  }

  toggleTipsState() {
    this.state = !this.state;
    this.tipsStateChangedEvent.emit(this.state);
  }

}
