import {Component, ElementRef, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RebatesService} from '../../services/rebates/rebates.service';
import {BrowserService} from '../../services/browser/browser.service';
import {UsersService} from '../../services/users/users.service';
import {CommissionJunctionService} from '../../services/commissionjunction/commissionjunction.service';
import {LocationService} from '../../services/location/location.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {Location} from '@angular/common';
import Handlebars from 'handlebars/dist/cjs/handlebars';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {StripeService} from '../../services/stripe/stripe.service';
import {ToastComponent} from '../../components/toast/toast.component';
import {CryptoService} from '../../services/crypto/crypto.service';
import {VendorsService} from '../../services/vendors/vendors.service';
import {Platform} from '@ionic/angular';
import {StorageServiceProvider} from '../../services/storage-service/storage-service';
import {RewardsService} from '../../services/rewards/rewards.service';
import {BrandsService} from '../../services/brands/brands.service';
import {UsersInsuranceService} from '../../services/usersinsurance/users.insurance.service';
import {PurchaseService} from '../../services/purchase/purchase.service';

declare var google;

@Component({
  selector: 'app-rebatedetail',
  templateUrl: './rebatedetail.page.html',
  styleUrls: ['./rebatedetail.page.scss'],
})
export class RebatedetailPage implements OnInit {

  @ViewChild('locationMapElement', {static: false}) mapElement: ElementRef;

  PurchaseService = PurchaseService;

  private map;

  private locationChunkSize = 3;
  private locationCount;
  private cloLocations;
  private action;

  public offer;
  public pageTitle;
  protected preferredOption;
  private offerType;
  protected redeemInfo;
  protected usesMessage;
  protected remaining;
  private userPaymentInfo;
  protected productPurchased = false;
  protected notAvailable = false;
  protected activeDate;
  protected terminatedDate;
  protected externalurl;
  protected subscriptionInfo;

  protected showPopup;
  protected popupTitle;
  protected popupContents;
  protected productInfo = [
  ];

  private returnFromStripe = false;

  constructor(
      private route: ActivatedRoute,
      private rebatesService: RebatesService,
      private browserService: BrowserService,
      private usersService: UsersService,
      private locationService: LocationService,
      private router: Router,
      private location: Location,
      private commissionjunctionService: CommissionJunctionService,
      public domSanitizer: DomSanitizer,
      private uiAlertService: UiAlertService,
      private stripeService: StripeService,
      private toastComponent: ToastComponent,
      private cryptoService: CryptoService,
      private vendorsService: VendorsService,
      private platform: Platform,
      private storage: StorageServiceProvider,
      private rewardsService: RewardsService,
      private brandsService: BrandsService,
      private usersInsuranceService: UsersInsuranceService,
      private purchaseService: PurchaseService
  ) {

  }

  ngOnInit() {
    PurchaseService.actionClicked = false;
    this.route.params.subscribe(async params => {
      if (params.data) {
        // return from Stripe or ACH setup payment method
        this.returnFromStripe = true;
        this.purchaseService.processPaymentMethod(params);
      }
      this.stripeService.getUserPaymentInfo().then(result => this.userPaymentInfo = result);
      this.offerType = params.type;
      switch (this.offerType) {
        case 'access':
          this.offer = this.rebatesService.getRebateDetails(params.id);
          const redeemOffer = this.offer._raw.links.redeem_offer['instore_print'];
          this.rebatesService.getOffer(params.id).then(offer => {
            this.remaining = offer.offer_uses_remaining;
          });
          this.rebatesService.getAccessContent(redeemOffer ? redeemOffer : this.offer._raw.links.redeem_offer.link).then(result => {
            this.redeemInfo = result.details;
          });
          break;
        case 'groupon':
          this.offer = this.rebatesService.getRebateDetails(params.id);
          // this.preferredOption = this.offer._raw.options[this.offer._raw.preferredOfferIndex];
          // this.setupMap();
          break;
        case 'clo':
          this.offer = await this.commissionjunctionService.getCLODetails(params.id);
          this.preferredOption = {
            redemptionLocations: this.offer.locations ? this.offer.locations.sort(function(a, b) {
              if (a.position < b.position) { return -1; } if (a.position > b.position) { return  1; } return 0;
            }) : [],
            details: [
              { description: this.offer.description }
            ]
          };
          this.setupMap();
          this.checkIfProductPurchased();
          break;
        case 'cj':
          this.offer = this.commissionjunctionService.getCJRebateDetails(params.id);
          break;
      }
      if (this.offer.locationChunkSize) {
        this.locationChunkSize = parseInt(this.offer.locationChunkSize, 10);
      }
      this.locationCount = this.locationChunkSize;
      if (this.offerType === 'groupon') {
        this.cloLocations = this.preferredOption.redemptionLocations.slice(0, this.locationCount);
      }
      this.pageTitle = this.offer.detailPageTitle || 'Offer Detail';
    });
  }

  async doRefresh(event) {
    await this.loadProductStatus();
    event.target.complete();
  }

  private purchaseButton = () => this.offer.buttons.find(button => button.action.type === 'wallitStripe' || button.action.type === 'vendor' || button.action.type === 'vendorACH' || button.action.type === 'vendorStripe');

  private loadProductStatus() {
    return this.vendorsService.getProductLaunchMetadata(this.purchaseButton().action.arguments.vendorProductId).then(info => {
      this.productInfo = info.status;
    });
  }

  private checkIfProductPurchased() {
    const purchaseButton = this.purchaseButton();
    if (!purchaseButton) {
      return;
    }
    this.vendorsService.getProductState(purchaseButton.action.arguments.vendorProductId).then(state => {
      if (state.info) {
        console.log('STATE INFO', state.info)
        this.subscriptionInfo = state.info.message;
      }
      if (state.state === 'terminated') {
        this.terminatedDate = state.terminatedDate;
        this.productPurchased = true;
        this.loadProductStatus();
      } else if (state.state === 'post') {
        this.activeDate = state.activeDate;
        this.productPurchased = true;
        this.loadProductStatus();
      } else {
        this.vendorsService.getVendorProduct(purchaseButton.action.arguments.vendorProductId).then(vendorProduct => {
          this.stripeService.isProductPurchased(vendorProduct.stripeProductId).then(purchased => {
            this.productPurchased = purchased;
            if (purchased) {
              this.loadProductStatus();
            } else {
              this.vendorsService.getAllVendorProducts().then(allVendorProducts => {
                this.stripeService.getProducts(this.brandsService.getConfigJSON().integration?.stripeProductBrand).then(products => {
                  this.stripeService.getUserPaymentInfo().then(paymentInfo => {
                    this.stripeService.getPurchasedProducts(paymentInfo.stripeCustomerId).then(purchases => {
                      let atLeastOnePurchased = false;
                      products.forEach(product => {
                        const matchedPurchase = purchases.find(purchase => purchase.items.data[0].plan.product === product.id);
                        product.purchased = !!matchedPurchase;
                        if (product.purchased) {
                          atLeastOnePurchased = true;
                        }
                      });
                      if (atLeastOnePurchased) {
                        allVendorProducts.forEach(allVendorProduct => {
                          if (allVendorProduct.stripeProductId !== vendorProduct.stripeProductId && allVendorProduct.vendorSlug === vendorProduct.vendorSlug && allVendorProduct.productName === vendorProduct.productName) {
                            this.notAvailable = true;
                          }
                        });
                      }
                    });
                  });
                });
              });
            }
          });
        });
      }
    }).catch(error => {
      console.log('ERROR GETTING PRODUCT PURCHASED', error.message);
    });
  }

  private setupMap() {
    const options = {
      center: {lat: this.locationService.defaultLocation.latitude, lng: this.locationService.defaultLocation.longitude},
      zoom: 11,
      streetViewControl: false
    };
    const _this = this;
    if (this.mapElement) {
      setTimeout(() => {
        _this.map = new google.maps.Map(_this.mapElement.nativeElement, options);
        _this.cloLocations.forEach(location => this.addMarker(location));
        _this.showAllLocations();
      }, 250);
    }
  }

  addMarker(location) {
    const latLng = new google.maps.LatLng(parseFloat(location.latitude ? location.latitude : location.lat), parseFloat(location.longitude ? location.longitude : location.lng));
    const markerOptions = {
      position: latLng,
      map: this.map
    };
    const marker = new google.maps.Marker(markerOptions);
    marker.addListener('click', () => {
      const address = `${location.streetAddress1}, ${location.city}, ${location.state} ${location.postalCode}`;
      if ((navigator.platform.indexOf('iPhone') !== -1) ||
            (navigator.platform.indexOf('iPad') !== -1) ||
            (navigator.platform.indexOf('iPod') !== -1)) {
        window.open(`maps://maps.google.com?q=${address}`);
      } else {
          window.open(`https://maps.google.com/maps/place/${address}`);
      }
    });
  }

  getDiscountPercentage() {
    if (this.offer.discount_type === 'percent') {
      return parseFloat(this.offer.discount_value).toFixed(0) + '% Off';
    }
    if (!this.offer.discount_percentage) {
      if (this.getStrikethroughPrice()) {
        const price = parseFloat(this.offer.price);
        const salePrice = parseFloat(this.offer['sale-price']);
        return (100 * (price - salePrice) / price).toFixed(0) + '% Off';
      } else {
        return '';
      }
    }
    const percentage = this.offer.discount_percentage * 100;
    return `${(percentage).toFixed(percentage < 10 ? 1 : 0)}% Off`;
  }

  getDiscountAmount() {
    return this.offerType === 'access'  && this.offer.discount_type === 'amount' && parseFloat(this.offer.discount_value) !== 0 ? `$${parseFloat(this.offer.discount_value).toFixed(2)} Off` : '';
  }

  private currency(amount) {
    return parseFloat(amount).toFixed(2);
  }

  getStrikethroughPrice() {
    return this.offer['sale-price'] && this.offer['sale-price'] !== this.offer.price ? ('$' + this.currency(this.offer.price)) : '';
  }

  getCashback() {
    let percentage;
    if (this.offer.cashback_percentage) {
      percentage = this.offer.cashback_percentage;
    } else if (this.offer.cashBackPercentage) {
      percentage = this.offer.cashBackPercentage / 100;
    } else {
      if (this.offerType !== 'cj') {
        return '';
      }
      percentage = this.commissionjunctionService.getDefaultCashBack();
    }
    if (this.offer.price) {
      const cashback = this.offer.price * percentage;
      return cashback === 0 ? '' : `$${(cashback).toFixed(cashback < 10 ? 2 : 0)} Cash Back`;
    } else {
      return percentage === 0 ? '' : `${(percentage * 100).toFixed(percentage < 10 ? 1 : 0)}% Cash Back`;
    }
  }

  getAndWord(offer) {
    return this.getCashback() && this.getDiscountPercentage() ? ' and ' : '';
  }

  getDescription() {
    if (this.offer.details) {
      return this.domSanitizer.bypassSecurityTrustHtml(this.offer.details);
    }
    return this.domSanitizer.bypassSecurityTrustHtml(this.offerType === 'cj' || this.offerType === 'access' ? this.offer.description : this.preferredOption.details[0].description);
  }

  getImage() {
    return this.offerType === 'access' ? this.offer.image_url : (this.offer._raw ? this.offer._raw.grid4ImageUrl : this.offer['image-url']);
  }

  claimClicked() {
    switch (this.offerType) {
      case 'groupon':
        this.browserService.open(this.offer.url);
        break;
      case 'cj':
        this.browserService.open(this.offer['link'] + '?sid=' + this.usersService.getCurrentUserId());
        break;
      case 'clo':
        this.browserService.open(this.offer['buy-url'] + '?sid=' + this.usersService.getCurrentUserId());
        break;
    }
  }

  getRebateTypeInfo() {
    if (!this.offer.cashBackPercentage || this.offer.cashBackPercentage === '0') {
      return '';
    }
    return this.offerType === 'clo' ? 'Just go to a participating location and use your Wallit-connected debit card.' :
        'Just tap the claim button and pay using your Wallit-connected debit card and receive cash back in addition to the merchant discount.';
  }

  rebateCashback() {
    return 'Online Instant Cash Back';
  }

  rebateDiscount() {
    return 'Discount';
  }

  getLocations() {
    switch (this.offerType) {
      case 'clo':
        return this.cloLocations;
      case 'cj':
        return [];
      case 'groupon':
        return this.preferredOption.redemptionLocations;
      case 'access':
        return [this.offer.merchant];
    }
  }

  showAllLocations() {
    const bounds = new google.maps.LatLngBounds();
    let latLng;
    this.cloLocations.forEach(location => {
      latLng = new google.maps.LatLng(parseFloat(location.latitude ? location.latitude : location.lat), parseFloat(location.longitude ? location.longitude : location.lng));
      bounds.extend(latLng);
    });
    if (this.cloLocations.length < 2) {
      this.map.setCenter(latLng);
      this.map.setZoom(this.offer.map ? parseInt(this.offer.map.zoom, 10) : 11);
    } else {
      this.map.fitBounds(bounds);
    }
  }

  loadMoreClicked() {
    const newLocations = this.preferredOption.redemptionLocations.slice(this.locationCount, this.locationCount + this.locationChunkSize);
    this.locationCount += newLocations.length;
    this.cloLocations = this.cloLocations.concat(newLocations);
    newLocations.forEach(location => this.addMarker(location));
    this.showAllLocations();
  }

  expandLocations() {
    this.router.navigate(['/rebateslocal', this.offer.id], {replaceUrl: true});
  }

  private redeem(type) {
    const redeemOffer = this.offer._raw.links.redeem_offer[type];
    this.rebatesService.getAccessContent(redeemOffer ? redeemOffer : this.offer._raw.links.redeem_offer.link).then(result => {
      this.browserService.open(result.details.link);
    });
  }

  redeemStoreClicked() {
    this.redeem('instore');
  }

  redeemPrintClicked() {
    this.browserService.open(this.redeemInfo.link);
  }

  gotoUrl() {
    this.browserService.open(this.offer['external-url']);
  }

  groupPurchase(action) {
    PurchaseService.actionClicked = false;
    this.usersInsuranceService.getInsuranceProductsForUser(this.usersService.getCurrentUserId()).then(products => {
      if (products.indexOf(action.arguments.vendorProductId) >= 0) {
          this.router.navigate(['/EditProfile/profile/group', this.cryptoService.encodeJSON(action)]);
      } else {
        this.toastComponent.presentToast('You are not eligible to purchase this product.');
      }
    });
  }

  doneButtonText() {
  }

  popupDone() {
    this.showPopup = false;
    PurchaseService.actionClicked = false;
  }

  leaveDetailPage() {
    if (this.returnFromStripe) {
      this.router.navigate(['']);
    } else {
      this.location.back();
    }
  }

  doneClicked(action) {
    console.log('DONECLICKED ACTION');
    if (action.type === 'cancel') {
      this.leaveDetailPage();
      return;
    }
    if (this.notAvailable) {
      this.toastComponent.presentToast('You cannot purchase this product because it is part of a family of products where you have already made a purchase.');
      return;
    }
    PurchaseService.actionClicked = true;
    this.vendorsService.getVendorProduct(action.arguments.vendorProductId).then(vendorProduct => {
      let template;
      switch (action.type) {
        case 'popup':
            this.showPopup = true;
            this.popupTitle = action.arguments.popupTitle;
            this.popupContents = action.arguments.content;
            break;
        case 'purchase':
          console.log('PURCHASE')
          this.purchaseService.purchaseByQuantity(action, this.purchaseService.parseHandlebars(action.arguments.stripeProductId), parseInt(this.purchaseService.parseHandlebars(action.arguments.quantity), 10));
          break;
        case 'appurl':
          PurchaseService.actionClicked = false;
          template = Handlebars.compile(action.arguments.url);
          this.router.navigate([template(this.usersService.me())]);
          break;
        case 'externalurl':
          PurchaseService.actionClicked = false;
          template = Handlebars.compile(action.arguments.url);
          const url = template(Object.assign({}, this.usersService.me(), {reward: this.offer}, {platform: this.platform.platforms()}));
          function isSafari() {
            return (
                /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
                /Apple/.test(navigator.vendor)
            );
          }
          if (isSafari()) {
            this.externalurl = url;
          } else {
            window.open(url);
          }
          break;
        case 'embedded':
          template = Handlebars.compile(action.arguments.url);
          this.rewardsService.gotoUrl(template(this.usersService.me()), action.arguments.iframe);
          break;
        case 'unsubscribeVendor':
          this.vendorsService.unenrollProduct(action.arguments.vendor, action.arguments.product, {arguments: action.arguments}).then(result => {
            if (result.error) {
              throw result;
            }
            this.toastComponent.presentToast(`You have unsubscribed from the product`);
          }).catch(error => {
            this.toastComponent.presentToast(`Error unsubscribing from product: ${error.error}`);
          });
          break;
        case 'vendorStripe':
        case 'wallitStripe':
        case 'vendorACH':
        case'group':
          if (this.productPurchased) {
            this.rewardsService.afterPurchase(action);
          } else {
            if (action.isGroup) {
              this.groupPurchase(action);
            } else {
              this.purchaseService.doPurchase(vendorProduct, action);
            }
          }
          break;
        case 'vendor':
          if (this.productPurchased) {
            this.rewardsService.afterPurchase(action);
          } else {
            this.vendorsService.enrollProduct(action.arguments.vendor, action.arguments.product, {arguments: action.arguments}).then(result => {
              PurchaseService.actionClicked = false;
              if (result.errors) {
                this.toastComponent.presentToast(`${result.errors[0]}. Please add this information to your profile and try your purchase again.`);
              } else if (result.error) {
                this.toastComponent.presentToast(`Product enrollment failed: ${result.error}.`);
              } else {
                this.rewardsService.gotoUrl(result.url, action.arguments.iframe);
              }
            });
          }
      }
    });
  }

  disableButton(button) {
    return (this.activeDate && ((button.action.type === 'vendorACH' || button.action.type === 'vendorStripe' || button.action.type === 'wallitStripe' || button.action.type === 'vendor'))) || (!this.productPurchased && button.enableWhenPurchased);
  }

  buttonText(button) {
    if (this.notAvailable  && button.action.type === 'vendorStripe') {
      return 'Not Available';
    }
    if (this.productPurchased && (button.action.type === 'vendorACH' || button.action.type === 'vendorStripe' || button.action.type === 'wallitStripe' || button.action.type === 'vendor')) {
      return this.activeDate ? 'Enrolled effective ' + this.activeDate : (button.action.purchased?.title || 'Go to Product');
    }
    return button.title;
  }

}

@Pipe({
  name: 'getDescription',
  pure: true
})
export class GetDescriptionPipe implements PipeTransform {

  transform(thisArg: RebatedetailPage): SafeHtml {
    return thisArg.getDescription();
  }

}

@Pipe({
  name: 'getAdvertiserName',
  pure: true
})
export class GetAdvertiserNamePipe implements PipeTransform {

  transform(thisArg: RebatedetailPage): SafeHtml {
    return thisArg.domSanitizer.bypassSecurityTrustHtml(thisArg.offer.merchant ? thisArg.offer.merchant.name : thisArg.offer['advertiser-name']);
  }

}

@Pipe({
  name: 'getTitle',
  pure: true
})
export class GetTitlePipe implements PipeTransform {

  transform(thisArg: RebatedetailPage): SafeHtml {
    return thisArg.domSanitizer.bypassSecurityTrustHtml(thisArg.offer.title);
  }

}
