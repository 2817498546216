import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Camera, CameraResultType} from '@capacitor/camera';
import {DomSanitizer} from '@angular/platform-browser';
import {ToastComponent} from '../toast/toast.component';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'app-take-photo',
  templateUrl: './take-photo.component.html',
  styleUrls: ['./take-photo.component.scss'],
})
export class TakePhotoComponent implements OnInit {

  @Input() enabled = true;
  @Output() photoTakenEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('camerainput', {static: false}) cameraInput;

  @Input() imageName;
  @Input() buttonLabel = 'Take Picture';

  public nativeMode = this.platform.is('cordova');

  constructor(
      private toastComponent: ToastComponent,
      private domSanitizer: DomSanitizer,
      private platform: Platform,
  ) { }

  ngOnInit() {
    if (!this.nativeMode) {
      this.buttonLabel = 'Upload image';
    }
  }

  private base64ToArrayBuffer(base64) {
    const binary_string =  window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array( len );
    for (let i = 0; i < len; i++)        {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  async takePicture() {
    if (!this.nativeMode) {
      this.cameraInput.nativeElement.click();
      return;
    }
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri
    });
    this.imageName = this.domSanitizer.bypassSecurityTrustResourceUrl(image.webPath);
    this.photoTakenEvent.emit({img: this.base64ToArrayBuffer(image.base64String), name: 'camera.jpg' });
  }

  changePhoto($event) {
    const _this = this;
    if ($event && $event.target.files[0] && $event.target.files[0].type.indexOf('image') < 0) {
      return this.toastComponent.presentToast('You did not select a valid image file');
    }
    const reader = new FileReader();
    reader.onload = function(event) {
      _this.photoTakenEvent.emit({ img: reader.result, name: $event.target.files[0].name});
    };
    reader.readAsArrayBuffer($event.target.files[0]);
  }


}
