// @ts-nocheck
import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormGroup, ValidationErrors} from '@angular/forms';
import {errors} from '../city-field/city-errors';

@Component({
  selector: 'app-formfieldbase',
  templateUrl: './formfieldbase.component.html',
  styleUrls: ['./formfieldbase.component.scss'],
})

export class FormfieldbaseComponent implements OnInit, AfterViewInit {

  @Input() requiredField = false;
  @Input() label: string;
  @Input() getVal: string;
  @Input() minValue: string;
  @Input() maxValue: string;
  @Input() focus = false;

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('textinput', {static: false}) textinput;

  protected fieldForm: FormGroup;
  protected thisFieldName: string;
  protected minLen: number;
  protected maxLen: number;
  // errors
  protected required: boolean;
  protected minLength: boolean;
  protected maxLength: boolean;
  protected invalid: boolean;
  protected errorMessage: string;

  private hasFocus = false;
  protected onTouched: () => void = () => {};

  constructor(fieldName: string) {
    this.thisFieldName = fieldName;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (focus && this.textinput) {
      this.textinput.setFocus(true);
    }
  }

  writeValue(val: any): void {
    // val && this.fieldForm.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.fieldForm.valueChanges.subscribe((fnx) => {
      this.valueChange.emit(fnx[this.thisFieldName]);
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.fieldForm.disable() : this.fieldForm.enable();
  }

  async setValue(value: string) {
    await setTimeoutPromise(100).then(res => {
      this.fieldForm.get(this.thisFieldName).setValue(value);
    });
  }

  blurInput (event: any): void {
    this.hasFocus = false;
  }

  focusInput (event: any): void {
    this.hasFocus = true;
    if (this.requiredField) {
      if (this.fieldForm.invalid) {
        this.fieldForm.controls[this.thisFieldName].markAsDirty();
        this.fieldForm.controls[this.thisFieldName].markAsTouched();
      }
    }
    let total = 0;
    let container = null;
    const _rec = (obj: any) => {
      total += obj.offsetTop;
      const par = obj.offsetParent;
      if (par && par.localName !== 'ion-content') {
        _rec(par);
      } else {
        container = par;
      }
    };
    _rec(event.target);
    container.scrollToPoint(0, total - 50, 400);
  }

  isErrorState() {
    return !this.hasFocus && this.fieldForm.invalid && (this.fieldForm.dirty || this.fieldForm.touched);
  }

  validate(c: AbstractControl): ValidationErrors | null {
    const controls = this.fieldForm.controls;
    Object.keys(controls).forEach(
        (controlName) => {
          if (controls[controlName].errors !== null) {
            this.required = controls[controlName].errors.hasOwnProperty('required');
            this.minLength = controls[controlName].errors.hasOwnProperty('minlength');
            this.maxLength = controls[controlName].errors.hasOwnProperty('maxlength');
            this.invalid = controls[controlName].errors.hasOwnProperty('pattern');
          } else {
            this.required = false;
            this.minLength = false;
            this.maxLength = false;
            this.invalid = false;
          }
        });
    let message = '';
    if (this.required) { message = errors.required; }
    if (this.minLength) { message = errors.minLength + this.minLen + ' characters'; }
    if (this.maxLength) { message = errors.maxLength + this.maxLen + ' characters'; }
    if (this.invalid) { message = errors.invalid; }
    this.errorMessage = message;
    return this.fieldForm.valid ? null : { invalidForm: {valid: false, message: message}};
  }

}

export const setTimeoutPromise = ((timeout: number) => new Promise(resolve => {
  setTimeout(resolve, timeout);
}));

