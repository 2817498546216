import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from '../../services/utils/utils.service';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {ToastComponent} from '../toast/toast.component';
import {InvitationsService} from '../../services/invitations/invitations.service';
import {UsersService} from '../../services/users/users.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-balance-card',
  templateUrl: './balance-card.component.html',
  styleUrls: ['./balance-card.component.scss'],
})
export class BalanceCardComponent implements OnInit {

  @Input() info;
  @Input() greeting = true;

  constructor(
      protected utilsService: UtilsService,
      private uiAlertService: UiAlertService,
      private toastComponent: ToastComponent,
      private invitationsService: InvitationsService,
      private usersService: UsersService,
      private router: Router
  ) { }

  ngOnInit() {
  }

  clicked() {
    this.router.navigate(['/tabs/tab-activity']);
  }

  pendingClicked(event) {
    event.preventDefault();
    this.invitationsService.getMagicCodeForUser(this.info.familyId, this.info.userId).then(magicCode => {
      this.uiAlertService.presentAlertInvitationPending(magicCode).then(result => {
        switch (result) {
          case this.uiAlertService.INVITATION_CANCEL:
            break;
          case this.uiAlertService.INVITATION_RESEND:
            this.invitationsService.resendSMSInvitations(this.usersService.getCurrentUserId()).then(response => {
              this.toastComponent.presentToast('Invitation has been sent again');
            }).catch((error) => {
              this.toastComponent.presentError(error);
            });
            break;
          case this.uiAlertService.INVITATION_CANCEL_INVITATION:
            this.invitationsService.removeInvitation(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId(), this.info.userId).then(response => {
              this.toastComponent.presentToast('Invitation has been canceled');
              this.usersService.reloadMe();
              this.router.navigate(['/Dashboard']);
            }).catch((error) => {
              this.toastComponent.presentError(error);
            });
            break;
        }
      });
    });

  }
}
