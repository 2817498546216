import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-form-item',
  templateUrl: './form-item.component.html',
  styleUrls: ['./form-item.component.scss']
})
export class OurlyFormItemComponent implements OnInit {

  hasFocus = false;

  @Input() label: string;
  @Input() error: string;
  @Input() hint: string;
  @Input() password: string;

  constructor() { }

  ngOnInit() {
  }



}
