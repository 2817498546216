import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';

const win: any = window;

@Injectable({
  providedIn: 'root'
})
export class ClipboardServiceProvider {

  private dom = win.document;

  constructor(private platform: Platform) {
  }

  public copy(value: string): Promise<any> {
    const promise = new Promise(
        (resolve, reject): void => {
          let textarea = null;
          try {
            textarea = this.dom.createElement('textarea');
            textarea.style.position = 'fixed';
            textarea.style.top = '0';
            textarea.style.left = '0';
            textarea.style.width = '2em';
            textarea.style.height = '2em';
            textarea.style.padding = '0';
            textarea.style.border = 'none';
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';
            textarea.style.background = 'transparent';

            // const att = document.createAttribute('readonly');
            // att.value = 'true';
            // textarea.setAttributeNode(att);

            this.dom.body.appendChild(textarea);
            textarea.value = value;
            textarea.focus();
            textarea.select();
            this.dom.execCommand('copy');
            if (this.platform.is('ios')) {
              this.iosCopyToClipboard(textarea);
            }
            resolve(value);
          } catch (err) {
            reject(err);
          } finally {
            if (textarea && textarea.parentNode) {
              textarea.parentNode.removeChild(textarea);
            }
          }
        }
    );
    return ( promise );
  }

  iosCopyToClipboard(el) {
    const oldContentEditable = el.contentEditable,
        oldReadOnly = el.readOnly,
        range = document.createRange();

    el.contenteditable = true;
    el.readonly = false;
    range.selectNodeContents(el);

    const s = window.getSelection();
    s.removeAllRanges();
    s.addRange(range);

    el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

    el.contentEditable = oldContentEditable;
    el.readOnly = oldReadOnly;

    document.execCommand('copy');
  }

}
