import { Component, OnInit } from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {ApiService} from '../../services/api/api.service';
import {Router} from '@angular/router';
import {BrandsService} from '../../services/brands/brands.service';

@Component({
  selector: 'app-floatingintro',
  templateUrl: './floatingintro.component.html',
  styleUrls: ['./floatingintro.component.scss'],
})
export class FloatingintroComponent extends BaseComponent implements OnInit {

  protected message = 'The first digital wallet for modern workers to self-direct their benefits. Link your debit card and get cash back automatically.';

  constructor(
      apiService: ApiService,
      private router: Router,
      private brandsService: BrandsService
  ) {
    super(apiService);
  }

  ngOnInit() {
    const config = this.brandsService.getConfigJSON();
    if (config.content.messages.homeSignup) {
      this.message = config.content.messages.homeSignup;
    }

  }

  joinClicked() {
    this.router.navigate([ '/signup-username' ]);
  }

  learnMoreClicked() {
    this.router.navigate([ '/learnmore' ]);
  }

}
