import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import * as HighCharts from 'highcharts';
import {FamilyService} from '../../../services/family/family.service';

@Component({
  selector: 'app-spending-savings-chart',
  templateUrl: './spending-savings-chart.component.html',
  styleUrls: ['./spending-savings-chart.component.scss']
})
export class SpendingSavingsChartComponent implements OnInit, AfterViewInit {

  resizeTimeout = null;
  chart = null;

  constructor(
      private familyService: FamilyService
  ) { }

  @HostListener('window:resize')
  onResize() {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      if (this.chart) {
        this.chart.reflow();
      }
    }).bind(this), 500);
  }

  ngAfterViewInit() {
    this.chart.reflow();
  }

  ngOnInit() {
      const highc: any = HighCharts;
      this.chart = highc.chart('chart', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'spline',
          width: null,
          height: 150,
          animation: false,
        },
        title: {
          text: 'Spending vs Savings'
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            day: '%b'
          }
        },
        yAxis: {
          labels: {
            format: '${value}'
          },
          opposite: true,
          title: {
            text: undefined
          }
        },
        plotOptions: {
          series: {
            animation: false
          },
          spline: {
            marker: {
              enabled: false
            }
          }
        },
        series: [{
          showInLegend: false,
          name: 'Spending',
          data: this.familyService.getSpendingById(0),
        },
          {
            showInLegend: false,
            name: 'Savings',
            data: this.familyService.getSavingById(0),
          }]
      });
    setTimeout(() => {
      this.chart.reflow();
    }, 100);
    }
}
