import {AfterViewInit, Component, Input, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {CommissionJunctionService} from '../../services/commissionjunction/commissionjunction.service';
import {BaseComponent} from '../base/base.component';
import {ApiService} from '../../services/api/api.service';
import {BrowserService} from '../../services/browser/browser.service';
import * as ellipsis from 'ftellipsis';
import {UsersService} from '../../services/users/users.service';
import {Router} from '@angular/router';
import {LocationService} from '../../services/location/location.service';
import {BrandsService} from '../../services/brands/brands.service';

@Component({
  selector: 'app-cj-carousel',
  templateUrl: './cj-carousel.component.html',
  styleUrls: ['./cj-carousel.component.scss'],
})
export class CjCarouselComponent extends BaseComponent implements OnInit, AfterViewInit {

  @Input() title;
  @Input() categories;
  @Input() keywords;
  @Input() content;
  @Input() showAdvertisers;
  @Input() distance;
  @Input() arrows;
  @ViewChildren('description') descriptions;
  @ViewChild('Slides', {static: false}) slides;

  protected rebateItems;
  private showSetKeywords;
  private showSetAdvertisers;
  protected advertisers;
  protected PRODUCTS_ONLY = this.brandsService.getUIConfig().productsOnly;

  public isEnd;
  public isBeginning;

  protected slideOpts = {
    spaceBetween: -40,
    /*
    pagination: {
      type: 'bullets',
      clickable: true,
      loop: false,
      el: '.swiper-pagination'
    }
    */
  };

  constructor(
      apiService: ApiService,
      private cjService: CommissionJunctionService,
      private browserService: BrowserService,
      private usersService: UsersService,
      private router: Router,
      private locationService: LocationService,
      private brandsService: BrandsService
  ) {
    super(apiService);
  }

  ngOnInit() {
    if (this.showAdvertisers) {
      this.advertisers = this.keywords;
      this.keywords = '';
    }
    this.load();
  }

  private async load() {
    const here = await this.locationService.here();
    this.cjService.productSearch(this.keywords, this.advertisers).then(products => {
      products.forEach(product => {
        if (product.advertiserName) {
          product['advertiser-name'] =  product.advertiserName;
        }
      });
      this.rebateItems = products.filter(product => (this.content === 'cj' && product.sku) || (this.content === 'merchant' && !product.sku));
      if (this.distance) {
        this.rebateItems = this.rebateItems.filter(item => {
          return !item.merchant || this.locationService.distance(item.merchant.latitude, item.merchant.logitude, here.latitude, here.longitude) < this.distance;
        });
      }
      this.rebateItems = this.rebateItems.sort((a, b) => {
        if (!a.distance) {
          if (!a.locations) {
            return 0;
          }
          const aDistances = a.locations.map(location => this.locationService.distance(parseFloat(location.latitude), parseFloat(location.longitude), here.latitude, here.longitude));
          a.distance = Math.min(...aDistances);
        }
        if (!b.distance) {
          if (!b.locations) {
            return 0;
          }
          const bDistances = b.locations.map(location => this.locationService.distance(parseFloat(location.latitude), parseFloat(location.longitude), here.latitude, here.longitude));
          b.distance = Math.min(...bDistances);
        }
        if (a.distance > b.distance) {
          return 1;
        }
        if (a.distance < b.distance) {
          return -1;
        }
        return 0;
      });
      this.rebateItems = this.rebateItems.filter(item => !item.maxDistance || item.maxDistance === '0' || item.distance <= item.maxDistance);
      this.rebateItems = this.rebateItems.filter(item => {
        this.categories = this.categories.filter(category => category.length > 0);
        if (!this.categories || this.categories.length === 0) {
          return true;
        }
        switch (item.hide) {
          case 'signedin':
            if (this.isLoggedIn) {
              return false;
            }
            break;
          case 'signedout':
            if (!this.isLoggedIn) {
              return false;
            }
            break;
          default:
            break;
        }
        return this.categories.some(r => item.bandCategories && item.bandCategories.includes(r));
      });
      this.rebateItems = this.rebateItems.sort((a, b) => {
        const aPriority = parseInt(a.priority ? a.priority : 0, 10);
        const bPriority = parseInt(b.priority ? b.priority : 0, 10);
        if (aPriority < bPriority) {
          return 1;
        }
        if (aPriority > bPriority) {
          return -1;
        }
        return 0;
      });
    });
  }

  ngAfterViewInit() {
    this.descriptions.changes.subscribe(changes => changes.forEach(description => {
      const descriptionEllipsis = new ellipsis(description.nativeElement);
      descriptionEllipsis.calc();
      descriptionEllipsis.set();
    }));
    this.updateSliderIconState();
  }

  private currency(amount) {
    return parseFloat(amount).toFixed(2);
  }

  getDiscountType(rebate) {
    return parseInt(rebate.cashBackPercentage, 10) ? 'Cash Back' : '';
  }

  getCashback(rebate) {
    const percentage = parseInt(rebate.cashBackPercentage, 10);
    if (!isNaN(percentage)) {
      return percentage > 0 ? rebate.cashBackPercentage + '%' : '';
    }
    return rebate.price ? '$' + this.currency(rebate.price * this.cjService.getDefaultCashBack()) : '';
  }

  getPercentageOff(rebate) {
    if (this.getStrikethroughPrice(rebate)) {
      const price = parseFloat(rebate.price);
      const salePrice = parseFloat(rebate['sale-price']);
      return (100 * (price - salePrice) / price).toFixed(0) + '% off';
    } else {
      return '';
    }
  }

  getStrikethroughPrice(rebate) {
    return rebate['sale-price'] && rebate['sale-price'] !== rebate.price ? ('$' + this.currency(rebate.price)) : '';
  }

  getPrice(rebate) {
    if (rebate['sale-price']) {
      return `$${this.currency(rebate['sale-price'])} ${rebate.priceSuffix || ''}`;
    } else if (rebate.price) {
      return `$${this.currency(rebate.price)} ${rebate.priceSuffix || ''}`;
    } else {
      return '';
    }
  }

  getDistance(rebate) {
    return rebate.distance.toFixed(1) + ' mi';
  }

  rebateClicked(rebateItem) {
    if (this.isLoggedIn) {
      if (rebateItem.sku) {
        this.router.navigate(['rebatedetail', 'cj', rebateItem.sku ]);
      } else {
        this.router.navigate(['rebatedetail', 'clo', rebateItem.id]);
      }
    }
  }

  keywordsClicked() {
    if (this.showAdvertisers) {
      this.showSetAdvertisers = true;
    } else {
      this.showSetKeywords = true;
    }
  }

  setAdvertisersDone(event) {
    if (event) {
      this.advertisers = event;
      this.load();
    }
    this.showSetAdvertisers = null;
  }

  setKeywordsDone(event) {
    if (typeof event === 'string') {
      if (this.keywords !== event) {
        this.keywords = event;
        this.load();
      }
    }
    this.showSetKeywords = null;
  }

  leftArrow() {
    this.slides.slideNext();
  }

  rightArrow() {
    this.slides.slidePrev();
  }

  updateSliderIconState() {
    this.isEnd = this.slides.isEnd().then(isEnd => {
      this.isEnd = isEnd;
    });
    this.slides.isBeginning().then(isBeginning => {
      this.isBeginning = isBeginning;
    });
  }

}
