import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Router} from '@angular/router';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class OurlyConfirmComponent implements OnInit {
  @Input() color: string;
  @Input() title: string;
  @Input() content: string;
  @Input() icon: string;

  constructor(private modalCtrl: ModalController, private router: Router) {}

  ngOnInit() {

  }

  okClicked() {
    this.modalCtrl.dismiss();
    this.router.navigate([{ outlets: { modal: null }}]);

  }

}
