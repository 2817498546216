import {Component, Input, OnInit} from '@angular/core';
import {UsersService} from '../../services/users/users.service';
import {AccountsService} from '../../services/accounts/accounts.service';

@Component({
  selector: 'app-pending-transfers',
  templateUrl: './pending-transfers.component.html',
  styleUrls: ['./pending-transfers.component.scss']
})
export class PendingTransfersComponent implements OnInit {

  @Input() transferAmount;

  constructor(
      private usersService: UsersService,
      private accountsService: AccountsService
  ) { }

  ngOnInit() {
  }

}
