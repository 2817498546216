import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {TasksService} from '../tasks/tasks.service';
import {UsersService} from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class FamilyService {

  public CHILD = 'child';
  public COPARENT = 'parent';

  spendingHistory = [
    [Date.UTC(2018, 8, 1), 3],
    [Date.UTC(2018, 9, 1), 11],
    [Date.UTC(2018, 10, 1), 5],
    [Date.UTC(2018, 11, 1), 8]
  ];

  savingsHistory = [
    [Date.UTC(2018, 8, 1), 6],
    [Date.UTC(2018, 9, 1), 2],
    [Date.UTC(2018, 10, 1), 3],
    [Date.UTC(2018, 11, 1), 10]
  ];

  memberTypes = [
    {
      id: this.CHILD,
      name: 'child',
      icon: 'assets/icon/Profile icon.svg'
    },
    {
      id: this.COPARENT,
      name: 'coparent',
      icon: 'assets/icon/Profile icon.svg'
    }
  ];

  constructor(
      private restangular: Restangular,
      private tasksService: TasksService,
      private usersService: UsersService
  ) { }

  getIconForMember(member) {
    return this.memberTypes.find(memberType => memberType.id === member.type).icon;
  }

  getSavingById(id) {
    return this.savingsHistory;
  }

  getSpendingById(id) {
    return this.spendingHistory;
  }

  getMemberById(familyId: string, userId: string): Promise<any> {
    if (familyId) {
      return this.restangular.one('families', familyId).one('users', userId).get().toPromise();
    } else {
      return this.restangular.one('users', userId).get().toPromise();
    }
  }

  addFamily(userId: string, name = 'My Family') {
    return this.restangular.one('users', userId).all('families').post({ name: name }).toPromise();
  }

  addChild(familyId: string, userId: string, data: any): Promise<any> {
    return this.restangular.one('families', familyId).one('users', userId).all('magic-link').post(data).toPromise();
  }

  updateFamilyMember(data: any): Promise<any> {
    return this.restangular.one('families', data.familyId).one('users', data.userId).customPATCH(data).toPromise();
  }

  removeMember(familyId: string, userId: string): Promise<any> {
    return this.restangular.one('families', familyId).one('users', userId).remove().toPromise().then(response => {
      this.usersService.reloadMe();
      this.tasksService.reload();
      return response;
    });
  }

  setAllowance(familyId: string, userId: string, amount: number): Promise<any> {
    return this.restangular.one('families', familyId).all('allowances').customPUT([{ userId: userId, allowance: amount}]).toPromise();
  }

  getAllowances(familyId: string, userId: string): Promise<any> {
    return this.restangular.one('families', familyId).one('users', userId).one('weeklyAllowances').getList().toPromise();
  }

  approveAllowance(familyId: string, userId: string, data: any) {
    return this.restangular.one('v3').one('families', familyId).one('users', userId).all('weeklyAllowance').customPOST(data).toPromise();
    // URL:/v3/families/:familyId/users/:userId/weeklyAllowance

  }
  requestMe(): Promise<any> {
    return this.restangular.one('me').get().toPromise();
  }

  requestMoreWork(data: any, bodyReq: any) {
    return this.restangular.one('families', data.familyId ).one('users', data.userId ).all('notifications').post(bodyReq).toPromise();
  }
}


