import {FormGroup} from '@angular/forms';

export class ConfirmValidator {

    static twoFieldsEqual = (form: FormGroup, field1: string, field2: string) => {
        const field1Control = form.get(field1);
        const field2Control = form.get(field2);
        field2Control.valueChanges.subscribe(field => {
            if (field1Control.value !== field2Control.value) {
                field2Control.setErrors({ mismatch: true });
            } else {
                field2Control.setErrors(null);
            }
        });
        field1Control.valueChanges.subscribe(field => {
            if (field1Control.value !== field2Control.value) {
                field2Control.setErrors({ mismatch: true });
            } else {
                field2Control.setErrors(null);
            }
        });
    }

}
