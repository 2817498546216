import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {BehaviorSubject, Subscription} from 'rxjs';
import {UsersService} from '../users/users.service';
import {FirestoreService} from '../firestore/firestore.service';
import {UtilsService} from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  STATUS_ACTIVE = 'active';
  STATUS_PENDING = 'pending';
  STATUS_AWAITING_APPROVAL = 'awaitingApproval';
  STATUS_ACCEPTED = 'accepted';
  STATUS_MISSED = 'missed';
  STATUS_REJECTED = 'rejected';

  private cachedActionLocations: any;

  private tasksObservable: BehaviorSubject<any>;
  private tasksMemberObservable: BehaviorSubject<any>;
  private getAllTasksObservable: BehaviorSubject<any>;

  private familyId: string;
  private userId: string;
  private tasksChangeObserver: Subscription;

  private memberFamilyId: string;
  private memberUserId: string;
  private memberTasksChangeObserver: Subscription;

  private getAllFamilyId: string;
  private getAllUserId: string;
  private allTasksChangeObserver: Subscription;

  constructor(
      private restangular: Restangular,
      private usersService: UsersService,
      private firestoreService: FirestoreService,
  ) {
    this.tasksObservable = new BehaviorSubject(null);
    this.tasksMemberObservable = new BehaviorSubject(null);
    this.getAllTasksObservable = new BehaviorSubject(null);
    this.getTaskActionLocations();
  }

  getDisplayName(task): string {
    function capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    function fixSubcategory(subcategory: string) {
        if (subcategory === 'generic') {
          return 'custom';
        }
        return subcategory;
      }
    return task.category === 'custom' ? task.specific : (task.title ? task.title
     : capitalize(task.category) + ' ' + fixSubcategory(task.specific));
  }

  getTaskById(taskId: string): Promise<any> {
    return this.restangular.one('tasks', taskId).get().toPromise();
  }

  getTaskActionLocations(): Promise<any> {
    if (this.cachedActionLocations) {
      return Promise.resolve(this.cachedActionLocations);
    } else {
      return this.restangular.one('public').one('tasks').all('tasksByActionLocation').getList().toPromise().then((response) => {
        this.cachedActionLocations = response;
        return response;
      });
    }
  }

  getTaskInfo(task: any): any {
    if (task.category === 'custom') {
      return { description: task.specific, categoryIcon: task.taskIcon };
    } else {
      const info = this.getTaskInfoById(+task.taskId);
      info.description = task.description;
      return info;
    }
  }

  getTaskInfoById(taskId: number): any {
    let theSubcategory;
    const theCategory = this.cachedActionLocations.
     find(category => theSubcategory = category.subcategories.find(subcategory => subcategory.taskId === taskId));
    return theCategory ? { categoryIcon: theCategory.icon, icon: theSubcategory.icon, specific: theSubcategory.specific, category: theCategory.category } : {};
  }

  getSubcategories(categoryId: string): Promise<any> {
    return this.getTaskActionLocations().then((response) => {
      return response.find(cat => cat.category === categoryId);
    });
  }


  private getChildrenTasks(familyId: string, userId: string): Promise<any> | null {
    if (familyId && userId) {
      if (this.tasksChangeObserver) {
        this.tasksChangeObserver.unsubscribe();
      }
      // this.tasksChangeObserver = this.firestoreService.tasks.monitor( userId, _ => this.reload());
      this.familyId = familyId;
      this.userId = userId;
      return this.getChildrenTasksHelper();
    }
  }

  reload() {
    this.tasksObservable.next(null);
    if (this.familyId && (this.usersService.isParent() || this.userId)) {
      this.getChildrenTasksHelper();
    }
    this.tasksMemberObservable.next(null);
    this.getAllNoHistoryHelper();
    if (this.getAllFamilyId && this.getAllUserId) {
      this.getAllHelper();
    }
    this.usersService.reloadMe(); // to deal with red dot on dashboard
  }

  getChildTasks(familyId: string, userId: string): Promise<any> {
    if (familyId && userId) {
      return this.restangular.one('families', familyId).one('users', userId).all('tasks').getList({detailed: true}).toPromise();
    } else {
      return Promise.resolve([]);
    }
  }

  getAll(familyId: string, userId: string): Promise<[any]> {
    if (this.allTasksChangeObserver) {
      this.allTasksChangeObserver.unsubscribe();
    }
    // this.allTasksChangeObserver = this.firestoreService.goals.monitor( userId, _ => this.reload());
    this.getAllFamilyId = familyId;
    this.getAllUserId = userId;
    return this.getAllHelper();
  }

  private getAllHelper(): Promise<any> {
    return this.restangular.one('families', this.getAllFamilyId).one('users', this.getAllUserId).
    all('tasks-histories').getList({detailed: true}).toPromise().then(data => {
      this.getAllTasksObservable.next(data);
      return data;
    });
  }

  private getAllNoHistoryHelper(): Promise<any> {
    if (this.memberFamilyId && this.memberUserId) {
      if (this.memberTasksChangeObserver) {
        this.memberTasksChangeObserver.unsubscribe();
      }
      // this.memberTasksChangeObserver = this.firestoreService.goals.monitor( this.memberUserId, _ => this.reload());
      return this.restangular.one('families', this.memberFamilyId).one('users', this.memberUserId).
       all('tasks').getList({detailed: true}).toPromise().then(data => {
        this.tasksMemberObservable.next(data);
        return data;
      });
    } else {
      this.tasksMemberObservable.next([]);
      return Promise.resolve([]);
    }
  }

  private getChildrenTasksHelper(): Promise<any> {
    return this.restangular.one('families', this.familyId).one('users', this.userId).all('tasks').
     getList({detailed: true}).toPromise().then(data => {
      this.tasksObservable.next(data);
      return data;
    });
  }

  getPendingTaskCount(familyId: string, userId: string, countCallback?): Promise<any> {
    return this.restangular.one('families', familyId).one('users', userId).
    all('tasks-histories').getList({detailed: true}).toPromise().then(data => {
      if (countCallback) {
        countCallback(data.length);
      }
      return data.filter(task => task.taskStatus === this.STATUS_AWAITING_APPROVAL).length;
    });
  }


  getAllSubscribe(familyId: string, userId: string, response) {
    this.getAllTasksObservable.subscribe(response);
    this.getAll(familyId, userId);
  }

  memberTasksSubscribe(familyId: string, userId: string, response) {
    this.tasksMemberObservable.subscribe(response);
    this.memberFamilyId = familyId;
    this.memberUserId = userId;
    return this.getAllNoHistoryHelper();
  }

  myTasksSubscribe(familyId: string, userId: string, response) {
    this.tasksObservable.subscribe(response);
    this.getChildrenTasks(familyId, userId);
  }

  getTaskCompletionInfo(familyId: string, userId: string, func) {
    this.getAllSubscribe(familyId, userId, result => {
      const returnValue = {};
      if (result) {
        returnValue['totalTaskCount'] = result.length;
        returnValue['completedTaskCount'] = result.filter(task => task.taskStatus ===
            this.STATUS_ACCEPTED || task.taskStatus === this.STATUS_AWAITING_APPROVAL).length;
        this.getPendingTaskCount(familyId, userId).then(count => {
          returnValue['taskCount'] = count;
          func(returnValue);
        });
      }
    });
  }

  getTaskCompletionInfoForWeek(familyId: string, userId: string, week: number): Promise<any> {
    return this.restangular.one('families', this.getAllFamilyId).one('users', this.getAllUserId).
     all('tasks-histories').getList({detailed: true, weekNumber: week}).toPromise().then(result => {
      const returnValue = {};
      returnValue['totalTaskCount'] = result.length;
      returnValue['completedTaskCount'] = result.filter(task => task.taskStatus ===
          this.STATUS_ACCEPTED || task.taskStatus === this.STATUS_AWAITING_APPROVAL).length;
      returnValue['taskCount'] = result.filter(task => task.taskStatus === this.STATUS_AWAITING_APPROVAL).length;
      return returnValue;
    }).catch((err) => {
      const returnValue = {};
      returnValue['totalTaskCount'] = 0;
      returnValue['completedTaskCount'] = 0;
      returnValue['taskCount'] = 0;
      console.log(err, 'this error is generated in tasks.service.ts for getTaskCompletionInfoForWeek promise');
      return returnValue;
    });
  }

  getTaskCompletionInfoForWeekNew(familyId: string, userId: string, week: number): Promise<any> {
    return this.restangular.one('families', familyId).one('users', userId).
     all('tasks-histories').getList({detailed: true, weekNumber: week}).toPromise().then(result => {
      const returnValue = {};
      returnValue['totalTaskCount'] = result.length;
      returnValue['completedTaskCount'] = result.filter(task => task.taskStatus ===
          this.STATUS_ACCEPTED || task.taskStatus === this.STATUS_AWAITING_APPROVAL).length;
      returnValue['taskCount'] = result.filter(task => task.taskStatus === this.STATUS_AWAITING_APPROVAL).length;
      return returnValue;
    }).catch((err) => {
      const returnValue = {};
      returnValue['totalTaskCount'] = 0;
      returnValue['completedTaskCount'] = 0;
      returnValue['taskCount'] = 0;
      console.log(err, 'this error is generated in tasks.service.ts for getTaskCompletionInfoForWeek promise');
      return returnValue;
    });
  }

  public uploadTaskImage(data) {
     return this.restangular.one('families', data.familyId).one('users', data.userId).one('tasks-histories', data.taskId)
        .one('dueAt', data.dueAt).all('img').customPUT(data.img, undefined, undefined, { 'content-type': 'image/*' }).toPromise();
  }

  getById(familyId: string, userId: string, taskId: string): Promise<any> {
    return this.restangular.one('families', familyId).one('users', userId).one('tasks', taskId).get().toPromise();
  }

  remove(familyId: string, userId: string, taskId: string): Promise<any> {
    return this.restangular.one('families', familyId).one('users', userId).one('tasks', taskId).remove().toPromise().then(data => {
      /*
      this.firestoreService.goals.delete({
        forUserId: userId,
        id: taskId
      });
       */
      return data;
    });
  }

  add(familyId: string, userId: string, data: any): Promise<any> {
    return this.restangular.one('families', familyId).one('users', userId).all('tasks').customPUT(data).toPromise().then(task => {
      /*
      this.firestoreService.tasks.add({
        forUserId: userId,
        id: data.taskId
      });
       */
      return task;
    });
  }

  addCustomTask(data: any): Promise<any> {
    return this.restangular.all('tasks').customPOST(data).toPromise();
  }

  update(familyId: string, userId: string, taskId: string, data: any): Promise<any> {
    return this.restangular.one('families', familyId).one('users', userId).one('tasks', taskId).customPATCH(data).toPromise().then(returnedData => {
      /*
      this.firestoreService.goals.update({
        forUserId: userId,
        id: taskId
      });
      */
      return returnedData;
    });
  }

  setCompleted(data: any , caption: string, url: string): Promise<any> {
    return this.restangular.one('families', data.familyId).one('users', data.userId).one('tasks-histories', data.taskId)
        .one('dueAt', data.dueAt).one('taskStatus').one('awaitingApproval').customPUT({ caption: caption, url: url }).toPromise();
  }

  setApproved(data: any): Promise<any> {
    return this.restangular.one('families', data.familyId).one('users', data.userId).one('tasks-histories', data.taskId)
        .one('dueAt', data.dueAt).one('taskStatus').one('accepted').customPUT(data).toPromise();
  }

  setRejected(data: any): Promise<any> {
    return this.restangular.one('families', data.familyId).one('users', data.userId).one('tasks-histories', data.taskId)
        .one('dueAt', data.dueAt).one('taskStatus').one('rejected').customPUT(data).toPromise();
  }
}
