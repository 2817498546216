import { Component, OnInit, forwardRef, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormControl,
  Validator,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import {errors} from './recurring-task-errors';
import {FormfieldbaseComponent} from '../formfieldbase/formfieldbase.component';

@Component({
  selector: 'app-recurring-task-field',
  templateUrl: './recurring-task-field.component.html',
  styleUrls: ['../formfieldbase/formfieldbase.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecurringTaskFieldComponent),
      multi: true
    },
    {
     provide: NG_VALIDATORS,
     useExisting: forwardRef(() => RecurringTaskFieldComponent),
     multi: true
   }
 ]
})
export class RecurringTaskFieldComponent extends FormfieldbaseComponent implements OnInit, ControlValueAccessor, Validator {

  constructor(private cdRef: ChangeDetectorRef) {
      super('recTask');
      this.fieldForm = new FormGroup(
          {
              recTask: new FormControl('',
                  [ Validators.required,
                      Validators.min(1),
                      Validators.pattern('^(0|[1-9][0-9]*)$')
                  ]),
          });
  }

  ngOnInit() {
      this.fieldForm.get(this.thisFieldName).setValue(this.getVal ? this.getVal : '1');
  }

    validate(c: AbstractControl): ValidationErrors | null {
        const controls = this.fieldForm.controls;
        Object.keys(controls).forEach(
            (controlName) => {
                if (controls[controlName].errors !== null) {
                    this.required = controls[controlName].errors.hasOwnProperty('required');
                    this.minLength = controls[controlName].errors.hasOwnProperty('min');
                    this.invalid = controls[controlName].errors.hasOwnProperty('pattern');
                } else {
                    this.required = false;
                    this.minLength = false;
                    this.invalid = false;
                }
            });
        let message = '';
        if (this.required) { message = errors.required; }
        if (this.minLength) { message = errors.minLength; }
        if (this.invalid) { message = errors.invalid; }
        this.errorMessage = message;
        return this.fieldForm.valid ? null : { invalidForm: {valid: false, message: message}};
    }

    inputChanged(event: any) {
        let valNr = 1;
        if (!(Number(event.target.value) < 1)) {
            this.fieldForm.get(this.thisFieldName).setValue(event.target.value);
            valNr = Number(event.target.value);
        }
        this.valueChange.emit(valNr);
    }

}

