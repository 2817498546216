import { Injectable } from '@angular/core';
import * as HighCharts from 'highcharts';
import {Restangular} from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {

  public defaultMonthlyBudget = 25.00;

  constructor(
      private restangular: Restangular
  ) { }

  chart(args) {
    return HighCharts.chart(args);
  }

  getMonthlySpending(userId: string, date: string): Promise<any> {
    // date: YYYY-MM
    // returns: { budget: 10, lastMonthAmount: 20, currentAmount: 10, max: 40 }
    return this.restangular.one('users', userId).one('reporting').one('chart').one('spentThisMonth', date).get().toPromise();
  }

  spentThisMonth(userId: string, date: string): Promise<any> {
    // date: YYYY-MM
    return this.restangular.one('users', userId)
    .one('reporting')
    .one('chart')
    .one('monthlyPlaidTransactions', date).get().toPromise();
  }

  getParameters(userId: string): Promise<any> {
    return this.restangular.one('users', userId).one('reporting').one('parameters').get().toPromise();
  }

  setParameters(userId: string, params: any): Promise<any> {
    return this.restangular.one('users', userId).one('reporting').one('parameters').customPUT(params).toPromise();
  }

}
