import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import {Platform} from '@ionic/angular';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormControl,
  Validator,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import {errors} from './amount-errors';
import {FormfieldbaseComponent} from '../formfieldbase/formfieldbase.component';

@Component({
  selector: 'app-amount-field',
  templateUrl: './amount-field.component.html',
  styleUrls: ['./amount-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmountFieldComponent),
      multi: true
    },
    {
     provide: NG_VALIDATORS,
     useExisting: forwardRef(() => AmountFieldComponent),
     multi: true
   }
 ]
})

export class AmountFieldComponent extends FormfieldbaseComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

  private plt = true;
  private minAmount: number;
  private maxAmount: number;

  protected showCurrencySymbol = false;

  registerOnChange(fn: any): void {
    this.fieldForm.valueChanges.subscribe((fnx) => {
      if (fnx.hasOwnProperty('amountField') && fnx.amountField <= 0 ) {
        this.invalid = true;
        this.errorMessage = errors.invalid;
        this.valueChange.emit('');
      } else if (fnx.hasOwnProperty('amountField') && parseFloat(fnx.amountField) > 0 && parseFloat(fnx.amountField) <= this.maxAmount) {
        this.valueChange.emit(fnx.amountField);
      } else {
        this.valueChange.emit('');
      }
    });
  }

  validate(c: AbstractControl): ValidationErrors | null {
    const controls = this.fieldForm.controls;
      Object.keys(controls).forEach(
        (controlName) => {
          if (controls[controlName].errors !== null) {
            this.required = controls[controlName].errors.hasOwnProperty('required');
            this.minLength = controls[controlName].errors.hasOwnProperty('min');
            this.maxLength = controls[controlName].errors.hasOwnProperty('max');
            this.invalid = controls[controlName].errors.hasOwnProperty('pattern');
          } else {
            this.required = false;
            this.minLength = false;
            this.maxLength = false;
            this.invalid = false;
          }
        });
       let message = '';
       if (this.required) { message = errors.required; }
       if (this.minLength) { message = errors.minLength + this.minAmount.toFixed(2) + ' or greater'; }
       if (this.maxLength) { message = errors.maxLength + this.maxAmount.toFixed(2) + ' or less'; }
       if (this.invalid) { message = errors.invalid + this.maxAmount.toFixed(2); }
       this.errorMessage = message;
    return this.fieldForm.valid ? null : { invalidForm: {valid: false, message: message}};
  }

  constructor(private cdRef: ChangeDetectorRef, private platform: Platform) {
    super('amountField');
    this.minAmount = 1.00;
    this.maxAmount = 1000000.00;
    this.fieldForm = new FormGroup(
        {
          amountField: new FormControl('',
              [ Validators.required,
                Validators.min(this.minAmount),
                Validators.max(this.maxAmount),
                Validators.pattern(/^\d{1,7}(?:[.,]\d{1,2})?$/)
              ]),
        });
  }

  ngOnInit() {
    if (this.platform.is('android') && !this.platform.is('iphone')) {
      const ua = navigator.userAgent;
      if ( ua.indexOf('Android') >= 0 ) {
        const androidVersion = parseFloat(ua.slice(ua.indexOf('Android') + 8));
        if (androidVersion <= 7) {
            this.plt = false;
        }
        this.showCurrencySymbol = this.plt;
      }
    }
    this.setCurrencySymbol();
   }

  ngOnChanges(changes: SimpleChanges) {
    this.setCurrencySymbol();
    if (this.minValue  && changes.hasOwnProperty('minValue') && changes.minValue.isFirstChange() ) {
      if (parseFloat(this.minValue) > 0) { // value must be greater than zero always
        // this.setValue(this.minValue);
        this.minAmount = parseFloat(this.minValue); // override with custom min
      } else {
        // this.setValue(this.minAmount.toFixed(2));
      }
      if (this.maxAmount > this.minAmount) {
        const regMin = new RegExp('^\\d{1,' + this.maxAmount.toFixed(0).length + '}(?:[.,]\\d{1,2})?$');
        this.fieldForm.get(this.thisFieldName).setValidators([
          Validators.required,
          Validators.min(this.minAmount), // override constructor minAmount
          Validators.max(this.maxAmount),
          Validators.pattern(regMin)
        ]);
      }
    }
    if (this.maxValue  && changes.hasOwnProperty('maxValue') && changes.maxValue.isFirstChange() ) {
      if (parseFloat(this.maxValue) > this.minAmount) {
        this.maxAmount = parseFloat(this.maxValue); // override with custom max
        const regMax = new RegExp('^\\d{1,' + this.maxAmount.toFixed(0).length + '}(?:[.,]\\d{1,2})?$');
        this.fieldForm.get(this.thisFieldName).setValidators([
          Validators.required,
          Validators.min(this.minAmount),
          Validators.max(this.maxAmount), // override constructor maxAmount
          Validators.pattern(regMax)
        ]);
      }
    }
    if (this.getVal && changes.getVal.firstChange !== null && changes.getVal.firstChange !== undefined && parseFloat(this.getVal) > 0 ) {
      this.showCurrencySymbol = this.plt;
      this.fieldForm.get(this.thisFieldName).setValue(this.getVal);
    } else if (changes.getVal.isFirstChange() && (this.getVal === '' || !this.getVal) ) {
      this.showCurrencySymbol = this.plt;
      // this.setValue(this.minAmount.toFixed(2));
    }
    if (this.getVal && parseFloat(this.getVal) > 0) {
      if (parseFloat(changes.getVal.previousValue) !== parseFloat(changes.getVal.currentValue)) {
        this.showCurrencySymbol = this.plt;
        // this.setValue(changes.getVal.currentValue);
      }
    }
  }

  setCurrencySymbol() {
    this.showCurrencySymbol = this.fieldForm && this.fieldForm.get(this.thisFieldName).value ? this.plt : false;
  }

  amountFocusInput (event: any): void {
    this.showCurrencySymbol = this.plt;
    this.setValue('');
    this.focusInput(event);
  }

  amountBlurInput(event: any): void {
    this.setCurrencySymbol();
    this.blurInput(event);
  }

}
