import { Injectable } from '@angular/core';
import {AppSettings} from '../../app.settings';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BrandsService {

  private isBranded = false;

  private brand;
  private brands;

  private defaultBrand = {
    name: 'Wallit',
    tagline: 'The Workplace Wallet',
    logoUrl: 'assets/logo/Wallit - Full Logo.svg',
    ourlyPrimaryColor: '#FF224A',
    ourlySecondaryColor: '#171A51',
    wallitTertiaryColor: '#3AE3A0',
    wallitGradientSWColor: '#FF005D',
    wallitGradientNEColor: '#FF4139',
    lightLogoUrl: '/assets/logo/Wallit - Brandmark.svg',
    verticalLogoOnWhiteUrl: 'assets/logo/Wallit - Full Logo - White.png',
    horizontalLogoUrl: '/assets/logo/Wallit - Horizontal - White All.png',
    horizontalLogoOnWhiteUrl: '/assets/logo/Wallit - Horizontal.svg',
    configjson: { bands: [], content: {ui: {giftcards: true, products: true}} },
    redirectUrl: 'wallit.app'
  };

  constructor(
      private httpClient: HttpClient,
  ) {
    this.brand = this.defaultBrand;
  }

  init(): Promise<any> {
    const subdomain = this.getSubdomain();
    return this.httpClient.get(AppSettings.getEndpoint() + '/public/partners').toPromise().then((data: Array<any>) => {
      const ourBrand = data.find(brand => brand.redirectUrl && brand.redirectUrl.split('.')[0].includes(subdomain));
      const wallitBrandInfo = data.find(brand => brand.name === 'Wallit');
      if (wallitBrandInfo) {
        this.defaultBrand.configjson = wallitBrandInfo.configjson;
      }
      this.setBrand(subdomain ? ourBrand : undefined);
      this.brands = data;
      return data;
    });
  }

  getBrandsInGroup(groupName: string): Array<any> {
    return this.brands.filter(brand => brand.configjson.brandgroups && brand.configjson.brandgroups.indexOf(groupName) >= 0);
  }

  getPrimaryColor() {
    return this.brand['ourlyPrimaryColor'];
  }

  getCampaignBrand(): string {
    return this.brand.redirectUrl.split('.')[0];
  }

  private getSubdomain(): string {
    const overrideBrand = AppSettings.getBrandOverride();
    console.log('OVERRIDEBRAND', overrideBrand);
    return overrideBrand ? overrideBrand : window.location.host.split('.')[0];
  }

  getBrandSubdomain(): string {
    return this.isBranded ? this.getSubdomain() : '';
  }

  getVerticalLogoOnWhite() {
    return this.brand.verticalLogoOnWhiteUrl ? this.brand.verticalLogoOnWhiteUrl : this.brand.logoUrl;
  }

  getHorizontalLogo(): string {
    return this.brand.horizontalLogoUrl ? this.brand.horizontalLogoUrl : this.brand.lightLogoUrl;
  }

  getHorizontalLogoOnWhite(): string {
    return this.brand.horizontalLogoOnWhiteUrl ? this.brand.horizontalLogoOnWhiteUrl : this.brand.logoUrl;
  }

  getLogoOnWhite(): string {
    return this.brand.logoUrl;
  }

  getWallitIcon(): string {
    return this.brand.lightLogoUrl;
  }

  getLearnMore() {
    return this.getConfigJSON().learnMore;
  }

  getContentMessage(id: string): string {
    return this.getConfigJSON().content.messages[id];
  }

  getName(): string {
    return this.brand['name'];
  }

  getTagline(): string {
    return this.brand['tagline'];
  }

  getConfigJSON(): any {
    return this.brand['configjson'];
  }

  getUIConfig(): any {
    return Object.assign(this.getConfigJSON().content.ui, {oldUi: false});
  }

  private setBrand(brand) {
    if (brand) {
      this.isBranded = true;
      this.brand = brand;
    }
    const styles = document.documentElement.style;
    styles.setProperty('--ion-color-primary', this.brand['ourlyPrimaryColor']);
    styles.setProperty('--ion-color-secondary', this.brand['ourlySecondaryColor']);
    styles.setProperty('--app-color-tertiary', this.brand['wallitTertiaryColor']);
    styles.setProperty('--app-color-gradientsw', this.brand['wallitGradientSWColor']);
    styles.setProperty('--app-color-gradientne', this.brand['wallitGradientNEColor']);
    styles.setProperty('--app-color-default-primary', this.defaultBrand['ourlyPrimaryColor']);
    styles.setProperty('--app-color-default-secondary', this.defaultBrand['ourlySecondaryColor']);
    styles.setProperty('--app-color-default-tertiary', this.defaultBrand['wallitTertiaryColor']);
    styles.setProperty('--app-color-default-gradientsw', this.defaultBrand['wallitGradientSWColor']);
    styles.setProperty('--app-color-default-gradientne', this.defaultBrand['wallitGradientNEColor']);
  }

}
