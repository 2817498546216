import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import { DomController, Platform } from '@ionic/angular';

@Directive({
  selector: '[appNavbarHide]'
})
export class NavbarHideDirective implements OnInit {

  @Input('appNavbarHide') scrollArea: any;

    private hidden = false;
    private triggerDistance = 20;

    constructor(
      private element: ElementRef,
      private renderer: Renderer2,
      private domCtrl: DomController,
      public plt: Platform
    ) { }

  ngOnInit() {
    this.initStyles();
    this.scrollArea.ionScroll.subscribe((scrollEvent: CustomEvent) => {
    // *(scrollEvent.detail);
    const delta = scrollEvent.detail.deltaY;
    if (scrollEvent.detail.currentY === 0 && this.hidden) {
      this.show();
    } else if (!this.hidden && delta > this.triggerDistance) {
      this.hide();
    } else if (this.hidden && delta < -this.triggerDistance) {
      this.show();
    }
    });
  }
  initStyles() {
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.element.nativeElement, 'transition', '0.3s linear');
    });
  }
  hide() {
    if (this.plt.is('mobile')) {
      // This will only print when on mobile
        this.domCtrl.write(() => {
        this.renderer.setStyle(this.element.nativeElement, 'min-height', '0px');
        this.renderer.setStyle(this.element.nativeElement, 'height', '0px');
        this.renderer.setStyle(this.element.nativeElement, 'opacity', '0');
        this.renderer.setStyle(this.element.nativeElement, 'padding', '0');
        });
        this.hidden = true;
    }
  }
  show() {
    if (this.plt.is('mobile')) {
      // This will only print when on mobile
        this.domCtrl.write(() => {
        this.renderer.setStyle(this.element.nativeElement, 'height', '77px');
        this.renderer.removeStyle(this.element.nativeElement, 'opacity');
        this.renderer.removeStyle(this.element.nativeElement, 'min-height');
        this.renderer.removeStyle(this.element.nativeElement, 'padding');
        });
        this.hidden = false;
    }
  }
}

