import { Component, OnInit, SimpleChanges, OnChanges, forwardRef, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormControl,
  Validator,
  Validators,
  AbstractControl,
  ValidationErrors } from '@angular/forms';
import * as libphonenumber from 'google-libphonenumber';
import {errors} from './phone-errors';
import {FormfieldbaseComponent} from '../formfieldbase/formfieldbase.component';

export function formatPhoneNumber(phoneNumberString: string): string {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    // return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, match[2], '-', match[3], '-', match[4]].join('');
  }
  return '';
}

export function tryUSPhone(phoneNumber: string): boolean {
          try {
            const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
            const telNumber = phoneNumber;
            const pNumber = phoneUtil.parseAndKeepRawInput(telNumber, 'US');
            // const isValidNumber = phoneUtil.isValidNumber(pNumber);
              if (true /* TODO: figure out validation that works properly */ || phoneUtil.isValidNumberForRegion(pNumber, 'US')) {
                return true;
              } else {
                return false;
              }
          } catch (e) {
            return false;
          }
}
const PHONE_REGEXP_NOPREFIX = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
const PHONE_REGEXP_PREFIX = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;

@Component({
  selector: 'app-phone-field',
  templateUrl: './phone-field.component.html',
  styleUrls: ['../formfieldbase/formfieldbase.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneFieldComponent),
      multi: true
    },
    {
     provide: NG_VALIDATORS,
     useExisting: forwardRef(() => PhoneFieldComponent),
     multi: true
   }
 ]
})
export class PhoneFieldComponent extends FormfieldbaseComponent implements OnChanges, ControlValueAccessor, Validator {

  @Input() formatWithPrefix = false;

  // min and max number length for validators
  private minLenPhoneNumber: number;
  private maxLenPhoneNumber: number;
  // errors
  private errorPlaceholder = false;
  private problematicPhoneNumber = 'Wrong';

  constructor(private cd: ChangeDetectorRef) {
    super('phone');
  }

  registerOnChange(fn: any): void {
    this.fieldForm.valueChanges.subscribe((fnx) => {
      /**
       * we only run google libphone number validation once
       * when we have valid phone number string from pattern validation
       * This way we optimize calls to google-libphonenumber package
       */
      if (this.fieldForm.valid && fnx.phone !== '') { // format ok ( we need to check phone number with libphone )
         const validPhone = tryUSPhone(this.fieldForm.get('phone').value);
         if (!validPhone) { // phone number not ok^(1|)?(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$
          // delete users tel input value
          this.fieldForm.get(this.thisFieldName).setValue('');
          this.valueChange.emit('');
         } else {
          this.valueChange.emit(fnx.phone); // format ok and phone number ok emit change to parent
         }
      } else { // format ok but phone number is not
        if (this.fieldForm.invalid) {
          this.fieldForm.controls[this.thisFieldName].markAsDirty();
          this.fieldForm.controls[this.thisFieldName].markAsTouched();
        }
        this.invalid = true;
        this.errorMessage = errors.invalid;
        this.valueChange.emit('');
      }
    });
  }

  validate(c: AbstractControl): ValidationErrors | null {
    const controls = this.fieldForm.controls;
      Object.keys(controls).forEach(
        (controlName) => {
          if (controls[controlName].errors !== null) {
            this.required = controls[controlName].errors.hasOwnProperty('required');
            this.minLength = controls[controlName].errors.hasOwnProperty('minlength');
            this.maxLength = controls[controlName].errors.hasOwnProperty('maxlength');
            this.invalid = controls[controlName].errors.hasOwnProperty('pattern');
          } else {
            this.required = false;
            this.minLength = false;
            this.maxLength = false;
            this.invalid = false;
          }
        });
       let message = '';
       if (this.required) { message = errors.required; }
       if (this.minLength) { message = errors.minLength; }
       if (this.maxLength) { message = errors.maxlength; }
       if (this.invalid) { message = errors.invalid; }
       this.errorMessage = message;
    return this.fieldForm.valid ? null : { invalidForm: {valid: false, message: message}};
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    if (this.formatWithPrefix) {
      this.minLenPhoneNumber = 15; // we use format +1 xxx-xxx-xxxx -> length = 15 chars
      this.maxLenPhoneNumber = 15; // we use format +1 xxx-xxx-xxxx -> length = 15 chars
    } else {
      this.minLenPhoneNumber = 12; // we use format xxx-xxx-xxxx -> length = 12 chars
      this.maxLenPhoneNumber = 12; // we use format xxx-xxx-xxxx -> length = 12 chars
    }
    if (changes.hasOwnProperty('requiredPhone') && changes.requiredPhone.firstChange) {
      if (this.requiredField) {
        this.fieldForm = new FormGroup(
          {
            phone: new FormControl('',
            { validators: [
              Validators.required,
              Validators.minLength(this.minLenPhoneNumber),
              Validators.maxLength(this.maxLenPhoneNumber),
              Validators.pattern(PHONE_REGEXP_PREFIX)], updateOn: 'blur'}
          )});
      } else {
        this.fieldForm = new FormGroup(
          {
            phone: new FormControl('',
            { validators: [
              Validators.minLength(this.minLenPhoneNumber),
              Validators.maxLength(this.maxLenPhoneNumber),
              Validators.pattern(PHONE_REGEXP_PREFIX)], updateOn: 'blur'}
          )});
      }
      this.cd.detectChanges();
    }
    if (changes.hasOwnProperty('getVal')) {
      // reformat phone number from getVal to format xxx-xxx-xxxx before set value
      if (changes.getVal.currentValue !== '' && changes.getVal.currentValue.length === this.maxLenPhoneNumber) {
        // 12 or 15 (this.maxLenPhoneNumber) is length of phone number string with "-" and numbers
        const validPhone = tryUSPhone(changes.getVal.currentValue);
        if (!validPhone) {
          this.errorPlaceholder = true;
          this.invalid = true;
          this.errorMessage = errors.invalid;
          this.problematicPhoneNumber = changes.getVal.currentValue;
          this.fieldForm.get(this.thisFieldName).setValue('');
        } else {
          // we set phone number in right format
          this.fieldForm.get(this.thisFieldName).setValue(formatPhoneNumber(changes.getVal.currentValue));
        }
      } else {
        this.fieldForm.get(this.thisFieldName).setValue('');
      }
    }
  }

}
