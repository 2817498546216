import { Component, OnInit, SimpleChanges, OnChanges, ChangeDetectorRef, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormControl,
  Validator,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import * as moment from 'moment/moment';
import {errors} from './dob-errors';
import {FormfieldbaseComponent} from '../formfieldbase/formfieldbase.component';

export let dateError = false;

export function ValidateDate(control: AbstractControl) {
  const today = moment.utc(getCurrentDayTimestamp()).endOf('day');
  if (control.value === '') { return null; }
  const controlDate = moment.utc(control.value).startOf('day');
    if (!controlDate.isValid()) {
      dateError = true;
      return { invalidDate: true };
    }
    if (controlDate.isValid() && controlDate > today) {
      dateError = true;
      return { invalidDate: true };
    }
    dateError = false;
  return null;
}

export function ValidateDateRequired(control: AbstractControl) {
  const today = moment.utc(getCurrentDayTimestamp()).endOf('day');
  const controlDate = moment.utc(control.value).startOf('day');
  // const currentDay = getCurrentDayTimestamp();
    if (!controlDate.isValid()) {
      dateError = true;
      return { invalidDate: true };
    }
    if (controlDate.isValid() && controlDate > today) {
      dateError = true;
      return { invalidDate: true };
    }
    dateError = false;
  return null;
}

export function getCurrentDayTimestamp() {
  const d = new Date();
  return new Date(
    Date.UTC(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes(),
      d.getSeconds()
    )
  // `toIsoString` returns something like "2019-08-22T08:32:32.847Z"
  // and we want the first part ("2019-08-22")
  ).toISOString().slice(0, 10);
}

const setTimeoutPromise = (timeout: number) => new Promise(resolve => {
  setTimeout(resolve, timeout);
});

@Component({
  selector: 'app-dob-field',
  templateUrl: './dob-field.component.html',
  styleUrls: ['../formfieldbase/formfieldbase.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DobFieldComponent),
      multi: true
    },
    {
     provide: NG_VALIDATORS,
     useExisting: forwardRef(() => DobFieldComponent),
     multi: true
   }
 ]
})

export class DobFieldComponent extends FormfieldbaseComponent implements OnChanges, ControlValueAccessor, Validator {

  public currentTime: any;
  public maxdate: any;
  public mindate: any;
  private getDate: string;

  // errors
  public invalid: boolean;
  public errorMessage: string;
  private minYear = '1940-01-01'; // default min year date for dob reel

  registerOnChange(fn: any): void {
    this.fieldForm.valueChanges.subscribe((fnx) => {
      if (this.fieldForm.valid) {
        // *('validForm');
        this.valueChange.emit(fnx.dob);
      } else {
       this.invalid = true;
       this.errorMessage = errors.invalid;
       this.fieldForm.controls[this.thisFieldName].markAsDirty();
       this.fieldForm.controls[this.thisFieldName].markAsTouched();
       this.valueChange.emit('');
       dateError = true;
      }
     });
  }

  validate(c: AbstractControl): ValidationErrors | null {
    const controls = this.fieldForm.controls;
      Object.keys(controls).forEach(
        (controlName) => {
          if (controls[controlName].errors !== null) {
            this.required = controls[controlName].errors.hasOwnProperty('required');
            this.invalid = controls[controlName].errors.hasOwnProperty('invalidDate');
          } else {
            this.required = false;
            this.invalid = false;
          }
        });
       let message = '';
       if (this.required) { message = errors.required; }
       if (this.invalid) { message = errors.invalid; }
       if (this.invalid && this.required) { message = errors.invalid; }
       this.errorMessage = message;
    return this.fieldForm.valid ? null : { invalidForm: {valid: false, message: message}};
  }

  constructor(private cd: ChangeDetectorRef) {
    super('dob');
    this.fieldForm = new FormGroup(
      {
        dob: new FormControl('')
      });
    this.maxdate = moment.utc(getCurrentDayTimestamp()).format('YYYY-MM'); // max date is current month
    this.mindate = moment.utc(this.minYear).format('YYYY-MM');
  }

  cancelDate() {
    const canceledDate = moment.utc(this.fieldForm.get(this.thisFieldName).value).format('YYYY-MM');
    if (moment.utc(canceledDate, 'YYYY-MM-DD').isValid()) {
    } else {
      this.getVal = '';
      this.fieldForm.get(this.thisFieldName).setValue('');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.requiredDob && changes.requiredDob.isFirstChange() && this.requiredField) {
      this.fieldForm.get(this.thisFieldName).setValidators([
        Validators.required,
        ValidateDateRequired
      ]);
    }
    if (changes.requiredDob && changes.requiredDob.isFirstChange() && !this.requiredField) {
      this.fieldForm.get(this.thisFieldName).setValidators([
        ValidateDate
      ]);
    }
    const testDate = (strDate: string) => {
      const formats = ['YYYY-MM-DD'],
          valid = moment.utc(strDate, formats).isValid();
          if (valid) {
            return true;
          }
          return false;
    };
    const ccv = testDate(moment.utc(changes.getVal.currentValue).format('YYYY-MM-DD'));
    if (changes.getVal.isFirstChange()) {
      if (changes.getVal.currentValue !== '' && testDate(moment.utc(changes.getVal.currentValue).format('YYYY-MM-DD'))) {
        this.getDate = moment.utc(changes.getVal.currentValue).format('YYYY-MM-DD');
      } else {
        this.getDate = getCurrentDayTimestamp();
        // this.setFirstdob(getCurrentDayTimestamp());
      }
    }
    if (changes.getVal.isFirstChange() && ccv) {
      this.setFirstdob(changes.getVal.currentValue);
    }
    if (!changes.getVal.isFirstChange()) {
      if (testDate(moment.utc(changes.getVal.previousValue).format('YYYY-MM-DD'))
      && !testDate(moment.utc(changes.getVal.currentValue).format('YYYY-MM-DD'))) {
        // case where date is smaller than today and getVal is set to ""
        if (moment.utc(changes.getVal.previousValue).format('YYYY-MM-DD') === this.getDate) {
          // do nothing! registerOnChange will do emit
          // *(1);
        } else { // maybe somebody enter February, 30
          // set back to to today
          // *(2)
          this.setFirstdob(getCurrentDayTimestamp());
        }
      } else if (testDate(moment.utc(changes.getVal.previousValue).format('YYYY-MM-DD'))
      && testDate(moment.utc(changes.getVal.currentValue).format('YYYY-MM-DD'))) {
        // do nothing! registerOnChange will do emitSeejayuu
        // *(3)
        this.setFirstdob(changes.getVal.currentValue);
      } else if (!testDate(moment.utc(changes.getVal.previousValue).format('YYYY-MM-DD'))
      && testDate(moment.utc(changes.getVal.currentValue).format('YYYY-MM-DD'))) {
        if (moment.utc(changes.getVal.currentValue) > moment.utc(getCurrentDayTimestamp())) {
          // special case if we get date from API after component is rendered
          this.getDate = moment.utc(changes.getVal.currentValue).format('YYYY-MM-DD');
          this.getVal = moment.utc(changes.getVal.currentValue).format('YYYY-MM-DD');
          this.setFirstdob(changes.getVal.currentValue);
        }
        this.setFirstdob(changes.getVal.currentValue);
        // *(4, this.getDate, this.getVal)
      } else {
        this.setFirstdob(getCurrentDayTimestamp());
        // *('previous wrong current wrong');
      }
    }
    if (dateError && !this.fieldForm.valid) {
      if (moment.utc(this.fieldForm.get(this.thisFieldName).value) > moment.utc(getCurrentDayTimestamp())) {
        this.mindate = moment.utc(this.minYear).format('YYYY-MM');
        this.maxdate = moment.utc(this.fieldForm.get(this.thisFieldName).value).format('YYYY-MM');
      } else {
        this.mindate = moment.utc(this.minYear).format('YYYY-MM');
        this.maxdate = moment.utc(getCurrentDayTimestamp()).format('YYYY-MM');
      }
        this.invalid = true;
        this.errorMessage = errors.invalid;
        this.fieldForm.controls[this.thisFieldName].markAsDirty();
        this.fieldForm.controls[this.thisFieldName].markAsTouched();
    }
  }

  async setFirstdob(dobValue: string) {
    await setTimeoutPromise(1).then(res => {
      this.fieldForm.get(this.thisFieldName).setValue(moment.utc(dobValue).format('YYYY-MM-DD'));
      if (moment.utc(this.fieldForm.get(this.thisFieldName).value) > moment.utc(getCurrentDayTimestamp())) {
        this.mindate = moment.utc(this.minYear).format('YYYY-MM');
        this.maxdate = moment.utc(this.fieldForm.get(this.thisFieldName).value).format('YYYY-MM');
      } else {
        this.mindate = moment.utc(this.minYear).format('YYYY-MM');
        this.maxdate = moment.utc(getCurrentDayTimestamp()).format('YYYY-MM');
      }
        this.currentTime = new Date();
        this.maxdate = this.currentTime.getFullYear();
        this.maxdate = this.maxdate.toString();
        this.mindate = moment.utc(this.minYear).format('YYYY-MM');
    });
  }

}
