import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RebatesService} from '../../services/rebates/rebates.service';
import {ApiService} from '../../services/api/api.service';
import {BaseComponent} from '../base/base.component';
import {LocationService} from '../../services/location/location.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-rebates-list',
  templateUrl: './rebates-list.component.html',
  styleUrls: ['./rebates-list.component.scss'],
})
export class RebatesListComponent extends BaseComponent implements OnInit {

  @Input() title;
  @Input() latitude = 0;
  @Input() longitude = 0;
  @Input() radius = 10;
  @Input() categorySlug;
  @Input() height;
  @Input() params;
  @Output() locationInfoEvent: EventEmitter<any> = new EventEmitter<any>();

  protected rebateItems;
  protected listStyle;
  private here;

  constructor(
      private rebatesService: RebatesService,
      private locationService: LocationService,
      private router: Router,
      apiService: ApiService,
  ) {
    super(apiService);
  }

  ngOnInit() {
    if (this.height) {
      this.listStyle = {
        'max-height': this.height + 'px',
        'overflow-y': 'scroll'
      };
    }
    if (this.latitude && this.longitude) {
      this.loadTheList(this.latitude, this.longitude);
    } else {
      this.locationService.here().then(here => {
        this.here = here;
        this.loadTheList(here.latitude, here.longitude);
      });
    }
  }

  loadTheList(latitude, longitude) {
    const processRebates = (rebates) => {
      this.rebateItems = rebates;
      this.rebateItems.forEach(rebate => {
        this.locationService.distanceFromHere(rebate.merchant.latitude, rebate.merchant.longitude).then(result => {
          rebate.distance = result;
          this.sortByDistance();
        });
        this.locationService.distanceSubscribe(rebate.merchant.latitude, rebate.merchant.longitude, function(distance)  {
          rebate.distance = distance;
          this.sortByDistance();
        }.bind(this));
      });
      this.sortByDistance();
    };
    if (this.params) {
      this.rebatesService.getRebatesByParams(this.params).then(rebates => {
        processRebates(rebates);
      });
    } else {
      if (!this.categorySlug || (isNaN(this.categorySlug) && this.categorySlug[0] !== '=')) {
        this.rebatesService.getRebatesByCategoryAndLocation('', this.latitude, this.longitude, this.radius, this.categorySlug).then(rebates => {
          processRebates(rebates);
        });
      } else {
        this.rebatesService.getTeamMap().then(map => {
          this.rebatesService.getRebatesByCategoryAndLocation(this.categorySlug && this.categorySlug[0] === '=' ? map[this.categorySlug.slice(1)].join(',') : this.categorySlug, this.latitude, this.longitude, this.radius).then(rebates => {
            processRebates(rebates);
          });
        });
      }
    }
  }

  sortByDistance() {
    this.rebateItems = this.rebateItems.sort((a, b) => {
      if (a.distance < b.distance) {
        return -1;
      }
      if (a.distance > b.distance) {
        return 1;
      }
      return 0;
    });
    const bottomRightPosition = { latitude: 90, longitude: 180 };
    const topLeftPosition = { latitude: -90, longitude: -180 };
    this.rebateItems.forEach(rebate => {
      if (rebate.merchant.latitude !== 0 && rebate.merchant.longitude !== 0) {
        if (rebate.merchant.latitude > topLeftPosition.latitude) {
          topLeftPosition.latitude = parseFloat(rebate.merchant.latitude);
        }
        if (rebate.merchant.latitude < bottomRightPosition.latitude) {
          bottomRightPosition.latitude = parseFloat(rebate.merchant.latitude);
        }
        if (rebate.merchant.longitude > topLeftPosition.longitude) {
          topLeftPosition.longitude = parseFloat(rebate.merchant.longitude);
        }
        if (rebate.merchant.longitude < bottomRightPosition.longitude) {
          bottomRightPosition.longitude = parseFloat(rebate.merchant.longitude);
        }
      }
    });
    const radius = Math.sqrt((topLeftPosition.latitude - bottomRightPosition.latitude) ** 2 + (topLeftPosition.longitude - bottomRightPosition.longitude) ** 2) * 69 * .45;
    const center = { lat: (topLeftPosition.latitude + bottomRightPosition.latitude) / 2, lng: (topLeftPosition.longitude + bottomRightPosition.longitude) / 2};
    this.locationInfoEvent.emit({radius, center});
  }

  getDistance(rebate) {
    return rebate.distance ? rebate.distance.toFixed(1) : '';
  }

  rebateClicked(rebateItem) {
    if (this.isLoggedIn) {
      this.router.navigate(['rebatedetail', rebateItem.type, rebateItem.id]);
    }
  }

  showAllClicked() {
    this.router.navigate(['/rebatelist', this.here.latitude, this.here.longitude, 10]);
  }

  getDiscount(rebate) {
    if (rebate.discount_type === 'percent') {
      return parseFloat(rebate.discount_value).toFixed(0) + '%';
    }
    const percentage = rebate.discount_percentage * 100;
    return `${(percentage).toFixed(percentage < 10 ? 1 : 0)}%`;
  }

  getCashback(rebate) {
    if (rebate.price) {
      const cashback = rebate.cashback_percentage * rebate.price;
      return `$${(cashback).toFixed(cashback < 10 ? 2 : 0)}`;
    } else {
      const percentage = rebate.cashback_percentage * 100;
      return `${(percentage).toFixed(percentage < 10 ? 1 : 0)}%`;
    }
  }

  getDiscountAmount(rebate) {
    return rebate.discount_type === 'amount' ? (parseFloat(rebate.discount_value) === 0 ? 'See Offer' : `$${parseFloat(rebate.discount_value).toFixed(2)} Off`) : '';
  }

  getAndWord(rebate) {
    return rebate.discount_percentage && rebate.cashback_percentage ? 'and ' : '';
  }

  getDiscountIcon() {
    return 'assets/icon/discount.png';
  }

  getCashbackIcon() {
    return 'assets/icon/cashback.png';
  }

}
