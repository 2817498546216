import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  private linesObservable: BehaviorSubject<any>;
  private lines = [];

  constructor() {
    this.linesObservable = new BehaviorSubject(null);
    this.linesObservable.next(this.lines);
  }

  getLines() {
    return Promise.resolve(this.lines);
  }

  subscribeLines(func) {
    this.linesObservable.subscribe(func);
  }

  write(line) {
  const now = new Date().toISOString();
    this.lines.push({createdAt: now, message: line});
    this.linesObservable.next(this.lines);
  }

}
