import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-setteam',
  templateUrl: './setteam.component.html',
  styleUrls: ['./setteam.component.scss'],
})
export class SetteamComponent implements OnInit {

  @Output() teamChangedEvent: EventEmitter<string> = new EventEmitter<string>();
  @Input() teams;

  private team;

  constructor(
  ) { }

  ngOnInit() {
  }

  teamChanged(event) {
    this.team = event.detail.value;
  }

  cancelClicked() {
    this.teamChangedEvent.emit('');
  }

  setClicked() {
    this.teamChangedEvent.emit(this.team);
  }

}
