import {Component, Input, OnInit} from '@angular/core';
import {UtilsService} from '../../services/utils/utils.service';
import {TasksService} from '../../services/tasks/tasks.service';

@Component({
  selector: 'app-task-info',
  templateUrl: './task-info.component.html',
  styleUrls: ['./task-info.component.scss'],
})
export class TaskInfoComponent implements OnInit {

  @Input() info;
  @Input() task;

  constructor(
      protected utilsService: UtilsService,
      protected tasksService: TasksService
  ) { }

  ngOnInit() {}

}
