import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-goal',
  templateUrl: './goal.component.html',
  styleUrls: ['./goal.component.scss']
})
export class OurlyGoalComponent implements OnInit {

  @Input() goal: any;
  @Input() balance: number;
  @Input() inCarousel = false;

  constructor() { }

  ngOnInit() {

  }

  currentGoalValue(goal) {
    return Math.min(goal.cost, this.balance);
  }

}
