import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-add-family',
  templateUrl: './add-family.component.html',
  styleUrls: ['./add-family.component.scss']
})
export class OurlyAddFamilyComponent implements OnInit {

  @Input() userId = '';
  @Input() familyId = '';

  constructor() { }

  ngOnInit() {
  }

}
