import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClipboardServiceProvider} from '../../../services/clipboard/clipboard.service';

@Component({
  selector: 'app-magic-code-sent',
  templateUrl: './magic-code-sent.component.html',
  styleUrls: ['./magic-code-sent.component.scss'],
})
export class MagicCodeSentComponent implements OnInit {

  @Output() buttonEvent: EventEmitter<number> = new EventEmitter<number>();
  @Input() magicCode = 'their magic code';

  constructor(
      private clipboardService: ClipboardServiceProvider
  ) { }

  ngOnInit() {}

  buttonClicked(buttonCode) {
    if (buttonCode === 2) {
      this.clipboardService.copy(this.magicCode);
    }
    this.buttonEvent.emit(buttonCode);
  }

}
