
import {EventEmitter, Injectable} from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class RadiusService {

  private accountChangedEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private restangular: Restangular,
  ) {
  }

  notifyAccountChanged(id: string) {
    this.accountChangedEvent.emit(id);
  }

  monitorAccountChanged(func) {
    this.accountChangedEvent.subscribe(value => func(value));
  }

  /**
   *
   * ENDPOINT: get /v4/families/:familyId/users/:userId/banks/radius/accounts
   *
   * @param familyId
   * @param userId
   * @param name
   * @param plaidAccountId
   *
   * return RESPONSE like this:
   *
   */
  getFundingSource(familyId: string, userId: string): Promise<any> {
    return this.restangular.one('v4').one('families', familyId).one('users', userId).one('banks').
    one('radius').one('accounts').get().toPromise();
  }

  getFundingSourceAsObservable(familyId: string, userId: string) {
    return this.restangular.one('v4').one('families', familyId).one('users', userId).one('banks').
    one('radius').one('accounts').getList();
  }

  /**
   * MM-1218
   * ENDPOINT: post /v4/families/:familyId/users/:userId/banks/radius/accounts
   *
   * @param familyId
   * @param userId
   * @param name
   * @param plaidAccountId
   *
   * return RESPONSE like this:
   * 201 {accountId: 9c1e69ba-30c3-411a-a366-f4ced0cac331}
   */
  createFundingSource(familyId: string, userId: string, name: string, plaidAccountId: string): Promise<any> {
    const data = {
      name: name,
      plaid_account_id: plaidAccountId
    };
    return this.restangular.one('v4').one('families', familyId).one('users', userId).one('banks').
    one('radius').all('accounts').customPOST(data).toPromise().then(result => {
      this.notifyAccountChanged(plaidAccountId);
      return result;
    });
  }

  getSummaryBalances(familyId: string, userId: string): Promise<any> {
    return this.restangular.one('v4').one('families', familyId).one('users', userId).one('banks').
    one('radius').one('summary-balances').get().toPromise();
  }

  getSummaryBalancesAsObservable(familyId: string, userId: string) {
    return this.restangular.one('v4').one('families', familyId).one('users', userId).one('banks').
    one('radius').one('summary-balances').get();
  }

  /**
   * MM-1219
   * ENDPOINT: post /v4/families/:familyId/users/:userId/banks/radius/:radiusCounterPartyId/reload
   *
   * @param familyId
   * @param userId
   * @param amount
   * @param radiusCounterPartyId // accountId from createFundingSource method
   *
   * return RESPONSE like this:
   * 201 {transactionId: ach_11ekyknzfnd3h }
   */
  reloadAccount(familyId: string, userId: string, amount: number, radiusCounterPartyId: string): Promise<any> {
    const data = {
      amount: amount
    };
    return this.restangular.one('v4').one('families', familyId).one('users', userId).one('banks')
    .one('radius', radiusCounterPartyId).all('reload')
    .customPOST(data).toPromise();
  }

  /**
   * MM-1220
   * ENDPOINT: post /v4/families/:familyId/users/:userId/banks/radius/:radiusCounterPartyId/withdraws
   *
   * @param familyId
   * @param userId
   * @param amount
   * @param radiusCounterPartyId // accountId from createFundingSource method
   *
   * return RESPONSE like this:
   * 201 {transactionId: ach_11ekyknzfnd3h }
   */
  withdrawsFromAccount(familyId: string, userId: string, amount: number, radiusCounterPartyId: string): Promise<any> {
    const data = {
      amount: amount
    };
    return this.restangular.one('v4').one('families', familyId).one('users', userId).one('banks').one('radius', radiusCounterPartyId)
    .all('withdraws').customPOST(data).toPromise();
  }

  /**
   * MM-1222
   * ENDPOINT post /v4/families/:familyId/users/:userId/banks/tango/gift-cards
   *
   * @param familyId
   * @param userId
   * @param amount
   * @param itemIdentifier
   * @param email
   *
   * RESPONSE:201 { transactionId: 'ach_11em3v72g2dgk',
      tangoOrder:
      { referenceOrderID: 'RA190731-13327-79',
        customerIdentifier: 'acc03b60-35db-11e9-8f65-a95e85e321fa',
        accountIdentifier: 'ad2c1c90-35db-11e9-8f65-a95e85e321fa',
        accountNumber: 'A06677644',
        amountCharged: { currencyCode: 'USD', total: 0.01, value: 0.01 },
        denomination: { currencyCode: 'USD', value: 0.01 },
        utid: 'U666425',
        rewardName: 'Amazon.com Gift Card',
        sender:
          { email: 'info@wallit.app',
            firstName: 'info@wallit.app',
            lastName: '' },
        recipient:
          { email: 'marcos+radius004@ourly.com',
            firstName: 'parent',
            lastName: 'parent' },
        sendEmail: true,
        etid: 'E000000',
        status: 'COMPLETE',
        createdAt: '2019-07-31T19:32:43Z',
        reward:
          { credentials: [Object],
            credentialList: [Array],
            redemptionInstructions:
            '<p>|&nbsp; &nbsp;<a href="https://www.amazon.com/gp/css/gc/payment/view-gc-balance?claimCode=">
            Apply to Account </a>&nbsp; |&nbsp;
            <a href="http://www.amazon.com/gc-redeem">How to Use</a>&nbsp; |</p>\r\n\r\n
            <p>To redeem your gift card visit&nbsp;<a href="http://www.amazon.com/redeem">
            www.amazon.com/redeem.</a></p>\r\n\r\n<p>
            If you have questions about redeeming your gift card, please visit&nbsp;
            <a href="http://www.amazon.com/gc-redeem">www.amazon.com/gc-redeem.</a></p>\r\n' },
        marginShare: null }
   */
  orderGiftCard(familyId: string, userId: string, amount: number, itemIdentifier: string, email: string): Promise<any> {
    const data = {
      amount: amount,
      itemIdentifier: itemIdentifier,
      email: email
    };
    return this.restangular.one('v4').one('families', familyId).one('users', userId).one('banks').one('tango')
    .all('gift-cards').customPOST(data).toPromise();
  }

  /**
   * MM-1221
   * ENDPOINT: PUT /v4/families/:familyId/users/:userId/banks/radius/transfers/:radiusAchUuid/status/cancel
   *
   * @param familyId
   * @param userId
   * @param radiusAchUuid
   *
   * RESPONSE 204
   */
  cancelTransfer(familyId: string, userId: string, radiusAchUuid: string): Promise<any> {
    return this.restangular.one('v4').one('families', familyId).one('users', userId).one('banks').one('radius')
    .one('transfers', radiusAchUuid).one('status').one('cancel').put().toPromise();
  }

  /**
   * MM-1229
   * ENDPOINT: DELETE /v4/families/:familyId/users/:userId/banks/:bankId/accounts/:integrationAccountId
   *
   * @param familyId
   * @param userId
   * @param bankId
   * @param integrationAccountId
   *
   * RESPONSE 204
   */
  deleteFundingSource(familyId: string, userId: string, bankId: string, integrationAccountId: string): Promise<any> {
    return this.restangular.one('v4').one('families', familyId).one('users', userId).one('banks', bankId)
    .one('accounts', integrationAccountId).remove().toPromise().then(data => {
      this.notifyAccountChanged(bankId);
      return data;
    });
  }

  deletePlaidSource(familyId: string, userId: string, accessToken: string): Promise<any> {
    return this.restangular.one('v4').one('families', familyId).one('users', userId).one('plaid')
        .one('access-tokens', accessToken).remove().toPromise().then(data => {
          this.notifyAccountChanged(accessToken);
          return data;
        });
  }

  /**
   * MM-1223
   * ENDPOINT: get /v4/families/:familyId/users/:userId/banks/radius/transactions
   * @param familyId
   * @param userId
   *
   * RESPONSE: 200
      [{ uuid: '7c227e70-b54f-11e9-848a-c581c902c387',   sourceFrom: 'acct_11ejsgxqc1ej5',
      sourceTo: 'cp_11em3t4ag2c2e',
      amount: 1,
      bankType: 'radius',
      transactionStatus: 'completed',
      transactionUUID: 'ach_11em8y6rg9c2j',
      createdAt: '2019-08-02T21:01:00.000Z',
      currencyType: 'USD',
      account:
      { id: '651bb810-b3c7-11e9-b1c3-653ec05127c4',
      referenceNumber: 2856,
      accountNumber: null,
      userId: 'e0f945c0-b3c6-11e9-b1c3-653ec05127c4',
      cardNumber: null,
      bankType: null,
      accountType: 'parent',
      bankStatus: 'created',
      createdAt: '2019-07-31T22:14:17.000Z',
      modifiedAt: '2019-07-31T22:14:17.000Z',
      routingNumber: null,
      integrationId: 'cp_11em3t4ag2c2e',
      legallyAllowed: 1,
      fundingSourceType: null,
      counterparty:
      { counterpartyId: 'cp_11em3t4ag2c2e',
      status: 'active',
      updatedAt: '2019-07-31T22:14:18.000Z',
      createdAt: '2019-07-31T22:14:18.000Z' } },
      type: 'withdraw',   wallitTransaction: true },... ]
   */
  getRadiusClientTransactions(familyId: string, userId: string): Promise<any> {
    return this.restangular.one('v4').one('families', familyId).one('users', userId)
    .one('banks').one('radius').one('transactions').get().toPromise();
  }

  getRadiusClientTransactionsAsObservable(familyId: string, userId: string) {
    return this.restangular.one('v4').one('families', familyId).one('users', userId)
    .one('banks').one('radius').one('transactions').getList();
  }


// /families/:familyId/users/:userId/transactions?type=bankId&beginAt=2019-09-20&endAt=2019-09-20

  getRadiusGoalTransactionsAsObservable(familyId: string, userId: string) {
    return this.restangular.one('v4').one('families', familyId).one('users', userId).one('transactions').getList({ type: 'ledgerGoal'});
  }

  /**
   * MM-1224
   * ENDPOINT: get /v4/families/:familyId/users/:userId/banks/radius/transactions/:transactionUuid
   *
   * @param familyId
   * @param userId
   * @param transactionId
   *
   * RESPONSE: 200
      { uuid: '7c227e70-b54f-11e9-848a-c581c902c387',
      sourceFrom: 'acct_11ejsgxqc1ej5',
      sourceTo: 'cp_11em3t4ag2c2e',
      amount: 1,
      bankType: 'radius',
      transactionStatus: 'completed',
      transactionUUID: 'ach_11em8y6rg9c2j',
      createdAt: '2019-08-02T21:01:00.000Z',
      currencyType: 'USD',
      account:
      { id: '651bb810-b3c7-11e9-b1c3-653ec05127c4',
      referenceNumber: 2856,
      accountNumber: null,
      userId: 'e0f945c0-b3c6-11e9-b1c3-653ec05127c4',
      cardNumber: null,
      bankType: null,
      accountType: 'parent',
      bankStatus: 'created',
      createdAt: '2019-07-31T22:14:17.000Z',
      modifiedAt: '2019-07-31T22:14:17.000Z',
      routingNumber: null,
      integrationId: 'cp_11em3t4ag2c2e',
      legallyAllowed: 1,
      fundingSourceType: null,
      counterparty:
      { counterpartyId: 'cp_11em3t4ag2c2e',
      status: 'active',
      updatedAt: '2019-07-31T22:14:18.000Z',
      createdAt: '2019-07-31T22:14:18.000Z' } },
      type: 'withdraw',
      wallitTransaction: true }
   */
  verifyTransactionFromRadius(familyId: string, userId: string, transactionId: string): Promise<any> {
    return this.restangular.one('v4').one('families', familyId).one('users', userId)
    .one('banks').one('radius').one('transactions', transactionId).get().toPromise();
  }

  /**
   * MM-1244
   * ENDPOINT: POST /v4/families/:familyId/users/:userId/banks/radius/sharedAccounts
   *
   * @param familyId
   * @param userId
   * @param data
   *
   * RESPONSE:[{accountId: 'ab903020-ba0f-11e9-9dc1-c15dc4d9c6ec', userId: a3c28610-ba08-11e9-901d-33abffbc1275}];
   */
  addSharedRadiusAccount(familyId: string, userId: string, data: any): Promise<any> {
    const bodyMessage = {
      counterpartyId: data.integrationId, // (integrationId from account)
      users: data.users // array of shared users id
    };
    return this.restangular.one('v4').one('families', familyId).one('users', userId)
    .one('banks').one('radius').all('sharedAccounts').customPOST(bodyMessage).toPromise().then(result => {
      this.notifyAccountChanged(data.integrationId);
      return result;
    });
  }
  /**
   * MM - 1258
   * ENDPOINT DELETE /v4/families/:familyId/users/:userId/banks/radius/sharedAccounts/:sharedAccountId
   *
   * @param familyId
   * @param userId
   * @param sharedAccountId
   */
  removeSharedRadiusAccount(familyId: string, userId: string, sharedAccountId: string): Promise<any> {
    return this.restangular.one('v4').one('families', familyId).one('users', userId)
    .one('banks').one('radius').one('sharedAccounts', sharedAccountId ).remove().toPromise().then(result => {
      this.notifyAccountChanged(sharedAccountId);
      return result;
    });
  }
  /**
   *
   * @param familyId
   * @param userId
   */
  getSharedRadiusAccountsInfo(familyId: string, userId: string): Promise<any> {
    return this.restangular.one('v4').one('families', familyId).one('users', userId).one('sharedAccounts').get().toPromise();
  }

  getTransactionCategories(): Promise<any> {
    return this.restangular.one('plaid').one('categories').getList().toPromise();
  }
}
