import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UtilsService} from '../../services/utils/utils.service';
import {InvitationsService} from '../../services/invitations/invitations.service';
import {ToastComponent} from '../toast/toast.component';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {UsersService} from '../../services/users/users.service';

@Component({
  selector: 'app-user-balance',
  templateUrl: './user-balance.component.html',
  styleUrls: ['./user-balance.component.scss']
})
export class OurlyUserBalanceComponent implements OnInit {

  @Input() user: any;
  @Input() balance: number;

  constructor(
      private router: Router,
      private utilsService: UtilsService,
      private invitationsService: InvitationsService,
      private toastComponent: ToastComponent,
      private uiAlertService: UiAlertService,
      private usersService: UsersService
  ) { }

  ngOnInit() {
  }

  pendingClicked() {
    this.invitationsService.getMagicCodeForUser(this.user.familyId, this.user.id).then(magicCode => {
      this.uiAlertService.presentAlertInvitationPending(magicCode).then(result => {
        switch (result) {
          case this.uiAlertService.INVITATION_CANCEL:
            break;
          case this.uiAlertService.INVITATION_RESEND:
            this.invitationsService.resendSMSInvitations(this.usersService.getCurrentUserId()).then(response => {
              this.toastComponent.presentToast('Invitation has been sent again');
            }).catch((error) => {
              this.toastComponent.presentError(error);
            });
            break;
          case this.uiAlertService.INVITATION_CANCEL_INVITATION:
            this.invitationsService.removeInvitation(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId(), this.user.id).then(response => {
              this.toastComponent.presentToast('Invitation has been canceled');
              this.usersService.reloadMe();
              this.router.navigate(['/tabs/tab-home']);
            }).catch((error) => {
              this.toastComponent.presentError(error);
            });
            break;
        }
      });
    });
  }

}
