import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-rewardpopup',
  templateUrl: './rewardpopup.component.html',
  styleUrls: ['./rewardpopup.component.scss'],
})
export class RewardpopupComponent implements OnInit {

  @Output() doneEvent: EventEmitter<string> = new EventEmitter<string | undefined>();

  @Input() title;
  @Input() content;

  constructor() { }

  ngOnInit() {}

  doneClicked() {
    this.doneEvent.emit(undefined);
  }


}
