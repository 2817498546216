import { Directive, ElementRef, Renderer2, OnInit, HostListener } from '@angular/core';
import { DomController, Platform } from '@ionic/angular';

@Directive({
  selector: 'ion-content'
})
export class ArrowKeyboardDirective {

  private br = 0;
  private brDown = 0;
  private clientHeight = 0;
  private offsetHeight = 0;
  private scrollHeight = 0;
  private offsetTop = 0;
  private newY = 0;
  private arrowStep = 10;

  @HostListener('document:keydown', ['$event'])
  async handleKeyboardEvent(event: KeyboardEvent) {

    if (event.key  ===  'ArrowDown') {
        event.preventDefault();
        const content = this.element.nativeElement;
        const y = 56;
        const el = await content.getScrollElement();
        this.scrollHeight = el.scrollHeight;
        this.clientHeight = el.clientHeight;
        if (this.newY + this.clientHeight > this.scrollHeight) {
            this.newY = this.scrollHeight - ( this.scrollHeight - this.br );
        } else {
            this.br += this.arrowStep;
            this.newY = this.scrollHeight - ( this.scrollHeight - this.br );
        }
        if (el.clientHeight + this.newY < el.scrollHeight) {
          content.scrollToPoint(0, this.newY);
        } else {
          content.scrollToBottom(100);
        }
    } else if (event.key  ===  'ArrowUp') {
        event.preventDefault();
        const content = this.element.nativeElement;
        const el = await content.getScrollElement();
        this.scrollHeight = el.scrollHeight;
        this.clientHeight = el.clientHeight;
        if (this.newY + this.clientHeight > this.scrollHeight) {
          this.br  -= this.arrowStep;
          this.newY = this.scrollHeight - ( this.scrollHeight - this.br );
          content.scrollToPoint(0, this.newY);
        } else {
            if (!(this.br <= 0) && !(this.newY <= 0)) {
              this.br -= this.arrowStep;
              this.newY = this.scrollHeight - ( this.scrollHeight - this.br );
              content.scrollToPoint(0, this.newY);
            } else {
              content.scrollToTop(100);
              this.newY = 0;
              this.br = 0;
            }
        }
    }
    if (event.key  ===  'Enter') {
        // Press action
        // *('enter');
    }
  }

  constructor(
      private element: ElementRef,
      private renderer: Renderer2,
      private domCtrl: DomController,
      public plt: Platform
  ) { }
}
