import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-setup-content',
  templateUrl: './setup-content.component.html',
  styleUrls: ['./setup-content.component.scss']
})
export class OurlySetupContentComponent implements OnInit {

  @Input() title: string;

  constructor() { }

  ngOnInit() {
  }

}
