import { Component, OnInit } from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {BrandsService} from '../../services/brands/brands.service';

@Component({
  selector: 'app-home-page-popup',
  templateUrl: './home-page-popup.component.html',
  styleUrls: ['./home-page-popup.component.scss']
})
export class HomePagePopupComponent implements OnInit {

  constructor(
      private modalCtrl: ModalController,
      public brandsService: BrandsService
  ) {
  }

  ngOnInit() {
  }

  cancelClicked() {
    this.modalCtrl.dismiss();
  }

}
