import { Component, OnInit, SimpleChanges, OnChanges, ChangeDetectorRef, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormControl,
  Validator,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import {errors} from './postal-errors';
import {FormfieldbaseComponent} from '../formfieldbase/formfieldbase.component';

const ZIP_REGEXP = /^[0-9]{5}(?:-[0-9]{4})?$/;

@Component({
  selector: 'app-postal-field',
  templateUrl: '../formfieldbase/formfieldbase.component.html',
  styleUrls: ['../formfieldbase/formfieldbase.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PostalFieldComponent),
      multi: true
    },
    {
     provide: NG_VALIDATORS,
     useExisting: forwardRef(() => PostalFieldComponent),
     multi: true
   }
 ]
})
export class PostalFieldComponent extends FormfieldbaseComponent implements OnChanges, ControlValueAccessor, Validator   {

  constructor(private cdRef: ChangeDetectorRef) {
    super('zip');
    this.fieldForm = new FormGroup(
        {
          zip: new FormControl('',
              [ Validators.minLength(5),
                Validators.maxLength(5),
                Validators.pattern(ZIP_REGEXP)
              ]),
        });
    this.fieldForm.get('zip').setValue('');
  }

  registerOnChange(fn: any): void {
    this.fieldForm.valueChanges.subscribe((fnx) => {
      if (!this.fieldForm.valid) {
        if (this.fieldForm.controls.zip && this.fieldForm.controls[this.thisFieldName].hasError('required')) {
          this.errorMessage = errors.required;
        }
        if (this.fieldForm.controls.zip && this.fieldForm.controls[this.thisFieldName].hasError('minlength')) {
          this.errorMessage = errors.minLength;
        }
        if (this.fieldForm.controls.zip && this.fieldForm.controls[this.thisFieldName].hasError('maxlength')) {
          this.errorMessage = errors.maxLength;
        }
        if (this.fieldForm.controls.zip && this.fieldForm.controls[this.thisFieldName].hasError('pattern')) {
          this.errorMessage = errors.invalid;
        }
      } else {
        this.errorMessage = '';
      }
      this.valueChange.emit(fnx.zip);
    });
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.fieldForm.valid ? null : { invalidForm: {valid: false, message: this.errorMessage}};
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.minValue  && changes.hasOwnProperty('minValue') && changes.minValue.isFirstChange() ) {
      if (parseInt(this.minValue, 10) > 0) { // value must be greater than zero always
        this.minLen = parseInt(this.minValue, 10); // override with custom min
      } else {
        this.minLen = 5;
      }
      if (this.maxLen > this.minLen && this.requiredField) {
        this.fieldForm.get(this.thisFieldName).setValidators([
          Validators.required,
          Validators.minLength(this.minLen), // override constructor
          Validators.maxLength(this.maxLen),
          Validators.pattern(ZIP_REGEXP)
        ]);
      } else if (this.maxLen > this.minLen && !this.requiredField) {
        this.fieldForm.get(this.thisFieldName).setValidators([
          Validators.minLength(this.minLen), // override constructor
          Validators.maxLength(this.maxLen),
          Validators.pattern(ZIP_REGEXP)
        ]);
      }
    }
    if (this.maxValue  && changes.hasOwnProperty('maxValue') && changes.maxValue.isFirstChange() ) {
      if (parseInt(this.maxValue, 10) > 0) { // value must be greater than zero always
        this.maxLen = parseInt(this.maxValue, 10); // override with custom max
      } else {
        this.maxLen = 5;
      }
      if (parseInt(this.maxValue, 10) > this.minLen && this.requiredField) {
        this.fieldForm.get(this.thisFieldName).setValidators([
          Validators.required,
          Validators.minLength(this.minLen),
          Validators.maxLength(this.maxLen), // override constructor
          Validators.pattern(ZIP_REGEXP)
        ]);
      } else if (parseInt(this.maxValue, 10) > this.minLen && !this.requiredField) {
        this.fieldForm.get('zip').setValidators([
          Validators.minLength(this.minLen),
          Validators.maxLength(this.maxLen), // override constructor
          Validators.pattern(ZIP_REGEXP)
        ]);
      }
    }
    if (this.getVal && changes.getVal.firstChange !== null && changes.getVal.firstChange !== undefined && this.getVal !== '' ) {
      this.fieldForm.get(this.thisFieldName).setValue(this.getVal);
    } else if (changes.getVal.isFirstChange() && (this.getVal === '' || !this.getVal) ) {
      this.setValue('');
    }
    if (this.getVal && this.getVal !== '') {
      if (parseFloat(changes.getVal.previousValue) !== parseFloat(changes.getVal.currentValue)) {
        this.fieldForm.get(this.thisFieldName).setValue(changes.getVal.currentValue);
      }
    }
  }

}
