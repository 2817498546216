import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Router} from '@angular/router';

@Component({
  selector: 'app-choose',
  templateUrl: './choose.component.html',
  styleUrls: ['./choose.component.scss']
})
export class OurlyChooseComponent implements OnInit {

  @Input() color: string;
  @Input() title: string;
  @Input() okButton: string;
  @Input() icon: string;
  @Input() content: string;

  constructor(private modalCtrl: ModalController, private router: Router) { }

  ngOnInit() {
  }

  okClicked() {
    this.modalCtrl.dismiss();
    this.router.navigate([{ outlets: { modal: null }}]);
  }

  cancelClicked() {
    this.modalCtrl.dismiss();
    this.router.navigate([{ outlets: { modal: null }}]);
  }

}
