import {Component, Input, OnInit} from '@angular/core';

/**
 * Generated class for the OurlyIconComponent component.
 *
 * See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
 * for more info on Angular Components.
 */
@Component({
  selector: 'app-icon',
  templateUrl: 'icon.html'
})
export class OurlyIconComponent implements OnInit {

  @Input() name: string;
  public iconName;

  constructor() {
  }

  ngOnInit() {

    if (this.name) {
      this.iconName = this.name;
    }
  }

}
