import {Component, Injectable, Input, OnInit} from '@angular/core';
import {OurlyConfirmComponent} from '../modaldialog/confirm/confirm.component';
import {ModalController} from '@ionic/angular';
import {Router} from '@angular/router';
import {HelpComponent} from '../help/help.component';
import {DomSanitizer} from '@angular/platform-browser';
import {LinksService} from '../../services/links/links.service';
import {HelpService} from '../../services/help/help.service';

@Injectable({
  providedIn: 'root',
})

@Component({
  selector: 'app-context-help',
  templateUrl: './context-help.component.html',
  styleUrls: ['./context-help.component.scss']
})
export class ContextHelpComponent implements OnInit {

  @Input() helpId: string;
  @Input() light = true;

  protected tipsState;

  constructor(
      private modalController: ModalController,
      private router: Router,
      private domSanitizer: DomSanitizer,
      private linksService: LinksService,
      private helpService: HelpService
  ) { }

  ngOnInit() {
    this.tipsState = this.helpService.getTipsState();
    this.helpService.tipsStateChangedEvent.subscribe(state => {
      this.tipsState = state;
    });
  }

  async helpClicked() {
    this.helpService.toggleTipsState();
    /*
    const href = this.domSanitizer.bypassSecurityTrustResourceUrl(this.linksService.getHelpHref(this.helpId || '#'));
    const modal = await this.modalController.create({
        component: HelpComponent,
        componentProps: { href: href },
        cssClass: 'fullscreen-modal'
    });
    await modal.present();
    */
  }

}
