import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {UsersService} from '../../../services/users/users.service';
import Handlebars from 'handlebars/dist/cjs/handlebars';
import {RewardsService} from '../../../services/rewards/rewards.service';
import {HelpComponent} from '../../help/help.component';
import {ModalController} from '@ionic/angular';
import {DomSanitizer} from '@angular/platform-browser';
import {LinksService} from '../../../services/links/links.service';
import {ToastComponent} from '../../toast/toast.component';
import {GoalsService} from '../../../services/goals/goals.service';

@Component({
  selector: 'app-claimreward',
  templateUrl: './claimreward.component.html',
  styleUrls: ['./claimreward.component.scss'],
})
export class ClaimrewardComponent implements OnInit {

  @Input() reward;
  @Input() goalId;
  @Input() taskId;
  @Output() doneEvent: EventEmitter<number> = new EventEmitter<number>();

  protected currentPage;
  protected destination;
  protected destinations;

  constructor(
      private router: Router,
      private usersService: UsersService,
      private rewardsService: RewardsService,
      private modalController: ModalController,
      private domSanitizer: DomSanitizer,
      private linksService: LinksService,
      private toastComponent: ToastComponent,
      private goalsService: GoalsService
  ) { }

  ngOnInit() {
    this.currentPage = this.getPageIndexFromId(this.reward.redeemDetails.startId);
    this.rewardsService.presentReward(this.usersService.getCurrentUserId(), this.reward.id);
    this.goalsService.getFundingSources(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId(), undefined).then(result => {
      this.destinations = result;
    });

  }

  private getPageIndexFromId(id: string): any {
    return this.reward.redeemDetails.pages.findIndex(page => page.id === id);
  }

  destinationChanged(event) {
    this.destination = event.detail.value;
  }

  doneClicked(action) {
    switch (action.type) {
      case 'cancel':
      case 'dismiss':
        this.doneEvent.emit(0);
        break;
      case 'page':
        this.currentPage = this.getPageIndexFromId(action.arguments.id);
        break;
      case 'appurl':
        const template = Handlebars.compile(action.arguments.url);
        this.router.navigate([template(this.usersService.me())]);
        this.doneEvent.emit(1);
        break;
      case 'claim':
        if (this.goalId) {
          this.rewardsService.claimGoalReward(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId(), this.reward.id, this.goalId);
        } else if (this.taskId) {
          this.rewardsService.claimTaskReward(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId(), this.reward.id, this.taskId);
        } else {
          this.rewardsService.claimReward(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId(), this.reward.id);
        }
        this.toastComponent.presentToast('Reward successfully claimed');
        this.doneEvent.emit(0);
        break;
    }
  }

  async tandcClicked() {
    const href = this.domSanitizer.bypassSecurityTrustResourceUrl(this.linksService.getHelpHref('claimRewardTandC'));
    const modal = await this.modalController.create({
      component: HelpComponent,
      componentProps: { href: href },
      cssClass: 'fullscreen-modal'
    });
    await modal.present();
  }

}

