import { Injectable } from '@angular/core';

declare var ga: Function;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }

  send(page: string, info: string) {
    ga('set', 'page', page);
    ga('send', 'pageview', info);
  }
}
