import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ApiService} from '../../services/api/api.service';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {BaseComponent} from '../base/base.component';
import {UsersService} from '../../services/users/users.service';
import {WallitService} from '../../services/wallit/wallit.service';
import {BrandsService} from '../../services/brands/brands.service';
import {MessageService, MessageType} from '../../services/message/message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class OurlyHeaderComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  protected spinnerLevel = 0;
  protected wallit;
  private subscription!: Subscription;
  protected disableRightArrow = false;

  @Input() inTab = true;
  @Input() embed = false;
  @Input() helpId: string;
  @Input() transparent = false;
  @Input() noBack = false;
  @Input() rightButton = '';
  @Input() cancelButton = '';
  @Input() rightIcon = '';
  @Input() showBalance = false;
  @Input() title;
  @Output() editEvent: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() cancelEvent: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  @ViewChild('content', {static: false}) content;
  @ViewChild('content2', {static: false}) content2;

  constructor(
      private location: Location,
      apiService: ApiService,
      private thisApiService: ApiService,
      private router: Router,
      private usersService: UsersService,
      private wallitService: WallitService,
      protected brandsService: BrandsService,
      private messageService: MessageService
  ) {
    super(apiService);
    this.inTab = this.router.url.indexOf('/tabs/tab-') >= 0;
  }

  ngOnInit() {
    this.subscription = this.messageService
        .onMessage<{ disabled: boolean }>(MessageType.SetRightArrowState)
        .subscribe(payload => {
          this.disableRightArrow = payload.disabled;
        });
    this.thisApiService.spinnerSubscribe(val => this.spinnerLevel = val);
    if (!this.rightButton) {
      this.usersService.meSubscribe((me) => {
        if (me) {
          this.wallitService.monitorWallitChanged(wallit => {
            this.wallit = wallit;
          });
        } else {
          this.wallit = undefined;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    if (!this.inTab) {
      document.title = 'Wallit | ' + (this.title ? this.title :
          (this.content && this.content.nativeElement ? this.content.nativeElement.childNodes[0]?.data : (this.content2 && this.content2.nativeElement ? this.content2.nativeElement.childNodes[0].data : '')));
    }
  }

  editClicked() {
    this.editEvent.emit();
  }

  backClicked() {
    this.location.back();
  }

  cancelClicked() {
    this.cancelEvent.emit();
  }

  joinClicked() {
    this.router.navigate([ '/signup-username' ]);
  }

  loginClicked() {
    this.router.navigate([ '/SignIn' ]);
  }

  balanceClicked() {
    this.router.navigate([ '/tabs/tab-accounts'], { replaceUrl: true });
  }

  menuClicked() {
    this.router.navigate([ '/WallitActions' ]);
  }

}
