import {Component, Input, OnInit} from '@angular/core';
import {BrandsService} from '../../services/brands/brands.service';
import {Router} from '@angular/router';
import {ApiService} from '../../services/api/api.service';
import {BaseComponent} from '../base/base.component';
import {UsersService} from '../../services/users/users.service';
import {AppSettings} from '../../app.settings';
import {WallitService} from '../../services/wallit/wallit.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.scss'],
})
export class HeaderHomeComponent extends BaseComponent implements OnInit {

  protected releaseVersion;
  protected wallit;
  protected PRODUCTS_ONLY = this.brandsService.getUIConfig().productsOnly;

  constructor(
      protected brandsService: BrandsService,
      private router: Router,
      apiService: ApiService,
      private usersService: UsersService,
      private wallitService: WallitService,
      private navCtrl: NavController
  ) {
    super(apiService);
  }

  ngOnInit() {
    this.releaseVersion = AppSettings.getReleaseVersion();
     this.wallitService.monitorWallitChanged(wallit => {
      this.wallit = Object.assign(wallit, {});
     });
  }

  loginClicked() {
    this.router.navigate([ '/SignIn' ]);
  }

  joinClicked() {
    this.router.navigate([ '/signup-username' ]);
  }

  menuClicked() {
    // this.navCtrl.navigateForward('/WallitActions', { animationDirection: 'back' });
    this.router.navigate([ '/WallitActions' ]);  }

  balanceClicked() {
    this.router.navigate(['tabs/tab-accounts'], { replaceUrl: true });
  }

}
