import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Last4Pipe} from './last4/last4.pipe';
import {CapitalizePipe} from './capitalize/capitalize.pipe';
import {
    GetCardClassPipe, GetTransactionLabelPipe,
    GetTransferHistoryLine1Pipe,
    GetTransferHistoryLine2Pipe,
} from '../components/transfer-history/transfer-history.page';
import {GetBalancePipe} from '../components/bank-providers/dwolla/user-accounts-dwolla/user-accounts-dwolla.component';
import {GetContentPipe} from '../components/rebates-api/rebates-api.component';
import {GetAdvertiserNamePipe, GetDescriptionPipe, GetTitlePipe} from '../pages/rebatedetail/rebatedetail.page';
import {GetCarouselItemPipe} from '../components/cj-carousel/cj-carousel.component';
import {SanitizeHtmlPipe} from './sanitizeHtml/sanitizeHtml.pipe';

@NgModule({
    declarations: [SanitizeHtmlPipe, GetCarouselItemPipe, GetAdvertiserNamePipe, GetTitlePipe, GetDescriptionPipe, Last4Pipe, GetContentPipe, CapitalizePipe, GetTransferHistoryLine1Pipe, GetTransferHistoryLine2Pipe, GetCardClassPipe, GetTransactionLabelPipe, GetBalancePipe],
    imports: [
        CommonModule
    ],
    exports: [SanitizeHtmlPipe, GetCarouselItemPipe, GetAdvertiserNamePipe, GetTitlePipe, GetDescriptionPipe, Last4Pipe, GetContentPipe, CapitalizePipe, GetTransferHistoryLine1Pipe, GetTransferHistoryLine2Pipe, GetCardClassPipe, GetTransactionLabelPipe, GetBalancePipe],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PipesModule { }
