import { Component, OnInit } from '@angular/core';
import {AccountsService} from '../../services/accounts/accounts.service';
import {UtilsService} from '../../services/utils/utils.service';
import {UsersService} from '../../services/users/users.service';

@Component({
  selector: 'app-reimbursements',
  templateUrl: './reimbursements.component.html',
  styleUrls: ['./reimbursements.component.scss'],
})
export class ReimbursementsComponent implements OnInit {

  protected transactions = [];

  constructor(
      private accountsService: AccountsService,
      private utilsService: UtilsService,
      private usersService: UsersService
  ) { }

  ngOnInit() {}

  private getTransfersForUserId(userId: string) {
    this.accountsService.getPlaidDwollaTransactions(this.usersService.getCurrentFamilyId(), userId).then(data => {
      if (data && data.length > 0) {
        data = data.filter(transaction => transaction.status !== 'begin');
        data = data.reduce(function (accum, current) {
          const foundIndex = accum.findIndex(item => item.id === current.id);
          if (foundIndex < 0) {
            accum.push(current);
          } else {
            if (accum[foundIndex].date < current.date) {
              accum[foundIndex] = current;
            }
          }
          return accum;
        }, []);
        data.forEach(transaction => {
          if (transaction.transactionType === 'withdraw') {
            transaction.fundingSource.name = 'Wallit Balance';
            transaction.amount = -transaction.amount;
          }
          if (transaction.transactionType === 'wallitReward') {
            transaction.fundingSource.name = 'Cash Back';
          }
        });
        data.forEach(item => {
          item.userId = userId;
          item.by = this.utilsService.getUserName(this.usersService.lookupFamilyMember(userId));
        });
        this.transactions = this.transactions.concat(data);
        this.transactions.sort(function (a, b) {
          if (a.date < b.date) {
            return 1;
          }
          if (a.date > b.date) {
            return -1;
          }
          return 0;
        });
      }
    });
  }


}
