import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-get-funding-amount',
  templateUrl: './get-funding-amount.component.html',
  styleUrls: ['./get-funding-amount.component.scss']
})
export class GetFundingAmountComponent implements OnInit {

  @Input() parent: FormGroup;

  protected hideAmount = true;

  buttons = [
    { text: '$10',
      selected: false
    },
    { text: '$20',
      selected: false
    },
    { text: '$50',
      selected: false
    },
    { text: '$100',
      selected: false
    },
    { text: '$250',
      selected: false
    },
    { text: 'Other',
      selected: false
    }
  ];

  constructor() { }

  ngOnInit() {

  }

  otherSelected() {
    const selectedButton = this.buttons.find(button => button.selected);
    return selectedButton ? selectedButton.text === 'Other' : false;
  }

  amountClicked(clickedButton) {
    this.buttons.map(button => button.selected = false);
    clickedButton.selected = true;
    this.hideAmount = !this.otherSelected();
    if (this.hideAmount) {
      this.parent.get('amount').setValue(+clickedButton.text.substring(1));
    } else {
      // DEFAULT set to min amount 1.00
      this.parent.get('amount').setValue('1.00');
    }
  }

  buttonColor(button) {
    return button.selected ? 'secondary' : 'primary';
  }

}
