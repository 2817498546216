import {AfterViewInit, Component, HostListener, Input, OnInit} from '@angular/core';
import {ChartsService} from '../../../services/charts/charts.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-monthly-spending-chart',
  templateUrl: './monthly-spending-chart.component.html',
  styleUrls: ['./monthly-spending-chart.component.scss'],
})

export class MonthlySpendingChartComponent implements OnInit, AfterViewInit {

  @Input() userId;
  @Input() name = 'chart';

  private resizeTimeout = null;
  private chart = null;
  protected currentSpending = 0;

  private sortedItems = [];

  constructor(
      private chartsService: ChartsService,
      private router: Router,
  ) { }

  @HostListener('window:resize')
  onResize() {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      if (this.chart) {
        this.chart.reflow();
      }
    }).bind(this), 500);
  }


  ngAfterViewInit() {
    const primaryColor = window.getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary');
    const gray = '#e0e0e0';
    this.chartsService.spentThisMonth(this.userId, new Date().toISOString().slice(0, 7)).then(res => {
    });
    this.chartsService.getMonthlySpending(this.userId, new Date().toISOString().slice(0, 7)).then(data => {
      this.currentSpending = data.currentAmount;

      // hack to deal with strings being returned rather than numbers
      data.budget = +data.budget;
      data.lastMonthAmount = +data.lastMonthAmount;
      data.currentAmount = +data.currentAmount;

      if (data.budget === 0) {
        data.budget = this.chartsService.defaultMonthlyBudget;
      }
      // OLD: { current: 125, lastMonth: 220, budget: 195 };
      // NEW: { budget: 10, lastMonthAmount: 20, currentAmount: 10, max: 40 }
      this.sortedItems = this.sortedDictionary(data);
      this.sortedItems.length = 3;
      let total = 0;
      this.sortedItems.forEach(i => total += i[1]);
      total *= 0.1;
      function item(_this, index: number): any {
        let value = _this.sortedItems[index][1];
        if (index > 0) {
          value -= _this.sortedItems[index - 1][1];
        }
        if (isNaN(value)) { value = 0; }
        let name = '';
        if (_this.lookupLabel(_this.sortedItems[index][0])) { name = _this.lookupLabel(_this.sortedItems[index][0]); } else { name = 'N/A'; }
        return [{color: _this.sortedItems[index][1] > _this.currentSpending ? gray : primaryColor, y: value },
          { color: gray, name: name, y: 0}];
      }
      const displayItems = [];
      if (isNaN(total)) { total = 0; }
      displayItems.push(...item(this, 0));
      displayItems.push(...item(this, 1));
      displayItems.push(...item(this, 2));
      displayItems.push({y: total, color: gray});
      if (total === 0) {
        const dataItems = [];
        dataItems.push({
          name: 'Current',
          y: 33.3,
          val: 0
        });
        dataItems.push({
          name: 'Last Month',
          y: 33.3,
          val: 0
        });
        dataItems.push({
          name: 'Budget',
          y: 33.3,
          val: 0
        });
        this.loadChart(dataItems);
      } else {
        this.loadChart(displayItems);
      }
    });
  }

  ngOnInit() {
  }

  helpClicked() {
    this.router.navigate([ 'help', 'spendingchart' ]);
  }

  arrowClicked() {
    this.router.navigate([ '/ProfileNotificationSettings' ]);
  }

  lookupLabel(tag: string): string {
    switch (tag) {case 'currentAmount':
        return 'Current';
      case 'lastMonthAmount':
        return 'Last month';
      case 'budget':
        return 'Budget';
    }
  }

  sortedDictionary(dict) {
    const items = Object.keys(dict).map(function(key) {
      return [key, dict[key]];
    });
    return items.sort(function(first, second) {
      return first[1] - second[1];
    });
  }

  loadChart(data) {
    const _this = this;
    this.chart = this.chartsService.chart({
      chart: {
        renderTo: this.name,
        type: 'pie',
        width: 250,
        height: 140,
        animation: false,
        backgroundColor: '#ffffff'
      },
      plotOptions: {
        pie: {
          shadow: false,
          dataLabels: {
            enabled: true,
            alignTo: 'plotEdges',
            formatter: function() {
              if (this.point.name !== 'Slice') {
                if (this.point.options && this.point.options.hasOwnProperty('val')) {
                  return this.point.name + '<br>$ 0.00';
                } else {
                  return this.point.name + '<br>$' + _this.sortedItems[(this.colorIndex - 1) / 2][1];
                }
              }
              return null;
            },
            style: {
              color: 'grey',
              fontSize: '6pt'
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: '',
        margin: 0,
        style: {
          color: 'grey',
          fontWeight: 'bold'
        }
      },
      series: [{
        name: 'Spending',
        data: data,
        size: '80%',
        innerSize: '80%',
        dataLabels: {
          enabled: true
        }
      }]
    });
}

}
