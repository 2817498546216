import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard} from './guards/auth.guard';
import { LoginGuard} from './guards/login.guard';
import { BlockCookieBrowserGuard } from './guards/block-cookie-browser.guard';

const routes: Routes = [
  { path: '', redirectTo: 'tabs', pathMatch: 'full' },
  { path: 'Dashboard', redirectTo: 'tabs', pathMatch: 'full' },
  { path: 'developer/:familyId/:userId', loadChildren: () => import('./pages/developer/developer.module').then(m => m.DeveloperPageModule) },

    // 2FA routes
  {
    path: '2fa',
    loadChildren: () => import('./pages/two-factor-authentication/two-factor-authentication.module').then(m => m.TwoFactorAuthenticationModule)
  },

    // dashboard and user profile
  { path: 'ChildDetail/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/child-detail/child-detail.module').then(m => m.ChildDetailPageModule) },
  { path: 'CoParentDetail/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/co-parent-detail/co-parent-detail.module').then(m => m.CoParentDetailPageModule) },
  { path: 'WallitActions', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/wallit-actions/wallit-actions.module').then(m => m.WallitActionsPageModule) },
  { path: 'Profile', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule) },
  { path: 'EditProfile', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule) },
  { path: 'ProfileOther', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/profile-other/profile-other.module').then(m => m.ProfileOtherPageModule) },
  { path: 'AddAChild/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/add-achild/add-achild.module').then(m => m.AddAChildPageModule) },
  { path: 'AddACoparent/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/add-acoparent/add-acoparent.module').then(m => m.AddACoparentPageModule) },
  { path: 'ProfileNotificationSettings', canActivate: [ AuthGuard ], loadChildren:
    () => import('./pages/forms/profile-notification-settings/profile-notification-settings.module').then(m => m.ProfileNotificationSettingsPageModule) },
  { path: 'ProfileNotifications', canActivate: [ AuthGuard ],
    loadChildren: () => import('./pages/profile-notifications/profile-notifications.module').then(m => m.ProfileNotificationsPageModule) },
  { path: 'ProfileFamily', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/profile-family/profile-family.module').then(m => m.ProfileFamilyPageModule) },
  { path: 'chartsettings', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/chartsettings/chartsettings.module').then(m => m.ChartsettingsPageModule) },
  { path: 'confirm-lose-changes', canActivate: [ AuthGuard ],
    loadChildren: () => import('./pages/modals/confirm-lose-changes/confirm-lose-changes.module').then(m => m.ConfirmLoseChangesPageModule) },
  { path: 'invitationhistory/:familyId/:userId', canActivate: [ AuthGuard ],
    loadChildren: () => import('./pages/invitationhistory/invitationhistory.module').then(m => m.InvitationhistoryPageModule) },

  // team purchases
  { path: 'AddAPurchase/:teamId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/add-apurchase/add-apurchase.module').then(m => m.AddAPurchasePageModule) },
  { path: 'AddAPurchase/:teamId/:purchaseId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/add-apurchase/add-apurchase.module').then(m => m.AddAPurchasePageModule) },
  { path: 'viewReimbursements/:teamId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/view-reimbursements/view-reimbursements.module').then(m => m.ViewReimbursementsPageModule) },

  // goals
  { path: 'AddAGoal/:userId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/add-agoal/add-agoal.module').then(m => m.AddAGoalPageModule) },
  { path: 'GoalDetail/:familyId/:userId/:goalId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/goal-detail/goal-detail.module').then(m => m.GoalDetailPageModule) },
  { path: 'goal-adjustfunding/:familyId/:userId/:goalId', loadChildren: () => import('./pages/forms/goal-adjustfunding/goal-adjustfunding.module').then(m => m.GoalAdjustfundingPageModule) },
  { path: 'goal-adjustfunding/:goalId/:amount', loadChildren: () => import('./pages/forms/goal-adjustfunding/goal-adjustfunding.module').then(m => m.GoalAdjustfundingPageModule) },
  { path: 'goals/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/goals/goals.module').then(m => m.GoalsPageModule) },

  // gift cards
  { path: 'SelectGiftCard', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/select-gift-card/select-gift-card.module').then(m => m.SelectGiftCardPageModule) },
  { path: 'FillOutGiftCard/:id', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/fill-out-gift-card/fill-out-gift-card.module').then(m => m.FillOutGiftCardPageModule) },
  { path: 'giftcardhistory/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/giftcardhistory/giftcardhistory.module').then(m => m.GiftcardhistoryPageModule) },
  { path: 'gift-card-order-detail/:familyId/:userId/:orderId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/gift-card-order-detail/gift-card-order-detail.module').then(m => m.GiftCardOrderDetailPageModule) },

  { path: 'Giving', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/giving/giving.module').then(m => m.GivingPageModule) },

    // FTUE, signup/signin
  // { path: 'intro-signup', canActivate: [ BlockCookieBrowserGuard, LoginGuard ], loadChildren: './pages/intro-signup/intro-signup.module#IntroSignupPageModule' },
  { path: 'invite/:id', canActivate: [ BlockCookieBrowserGuard, LoginGuard ], loadChildren: () => import('./pages/invite/invite.module').then(m => m.InvitePageModule) },
  { path: 'SignIn', canActivate: [ BlockCookieBrowserGuard, LoginGuard ], loadChildren: () => import('./pages/forms/sign-in/sign-in.module').then(m => m.SignInPageModule) },
  { path: 'enter-sms-code/:phone/:birthdate/:invitationId',
    loadChildren: () => import('./pages/forms/enter-sms-code/enter-sms-code.module').then(m => m.EnterSmsCodePageModule) },
  { path: 'enter-sms-code/:phone/:birthdate',
    loadChildren: () => import('./pages/forms/enter-sms-code/enter-sms-code.module').then(m => m.EnterSmsCodePageModule) },
  { path: 'Setup1', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/setup1/setup1.module').then(m => m.Setup1PageModule) },
  { path: 'setup1-next', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/setup1-next/setup1-next.module').then(m => m.Setup1NextPageModule) },
  { path: 'Setup2', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/setup2/setup2.module').then(m => m.Setup2PageModule) },
  { path: 'Setup3', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/setup3/setup3.module').then(m => m.Setup3PageModule) },
  { path: 'setup4/:bankId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/setup4/setup4.module').then(m => m.Setup4PageModule) },
  { path: 'setup-invite-parent', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/setup-invite-parent/setup-invite-parent.module').then(m => m.SetupInviteParentPageModule) },
  { path: 'setup-kid-splash', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/setup-kid-splash/setup-kid-splash.module').then(m => m.SetupKidSplashPageModule) },
  { path: 'forgot-password', outlet: 'modal',
    loadChildren: () => import('./pages/modals/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) },
  { path: 'signup-start', loadChildren: () => import('./pages/forms/signup-start/signup-start.module').then(m => m.SignupStartPageModule) },
  // { path: 'signup-magiccode', canActivate: [ BlockCookieBrowserGuard, LoginGuard ], loadChildren: './pages/forms/sign-up-magiccode/sign-up-magiccode.module#SignupMagiccodePageModule' },
  { path: 'signup-username', canActivate: [ BlockCookieBrowserGuard, LoginGuard ], loadChildren: () => import('./pages/forms/sign-up-username/sign-up-username.module').then(m => m.SignupUsernamePageModule) },
  // { path: 'signup-phone', canActivate: [ BlockCookieBrowserGuard, LoginGuard ], loadChildren: './pages/forms/sign-up-phone/sign-up-phone.module#SignupPhonePageModule' },
  { path: 'signup-magiccode-next',
    loadChildren: () => import('./pages/forms/sign-up-magiccode-next/sign-up-magiccode-next.module').then(m => m.SignUpMagiccodeNextPageModule) },
  { path: 'resetpassword/:resetCode', loadChildren: () => import('./pages/forms/resetpassword/resetpassword.module').then(m => m.ResetpasswordPageModule) },
  { path: 'requestnewpassword', loadChildren: () => import('./pages/forms/requestnewpassword/requestnewpassword.module').then(m => m.RequestnewpasswordPageModule) },
  { path: 'forgotusername', loadChildren: () => import('./pages/forms/forgotusername/forgotusername.module').then(m => m.ForgotusernamePageModule) },
  { path: 'token/:token/:refresh', loadChildren: () => import('./pages/token/token.module').then(m => m.TokenPageModule) },
  { path: 'token/:token/:refresh/:supportMode', loadChildren: () => import('./pages/token/token.module').then(m => m.TokenPageModule) },

    // tasks
  { path: 'AddATask/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/add-atask/add-atask.module').then(m => m.AddATaskPageModule) },
  { path: 'add-task-location/:familyId/:userId/:categoryId', canActivate: [ AuthGuard ],
    loadChildren: () => import('./pages/forms/add-task-location/add-task-location.module').then(m => m.AddTaskLocationPageModule) },
  { path: 'AssignTask/:familyId/:userId/:taskId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/assign-task/assign-task.module').then(m => m.AssignTaskPageModule) },
  { path: 'start-task/:familyId/:userId/:taskId/:dueAt', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/start-task/start-task.module').then(m => m.StartTaskPageModule) },
  { path: 'approve-task/:familyId/:userId/:taskId/:dueAt', canActivate: [ AuthGuard ],
    loadChildren: () => import('./pages/forms/approve-task/approve-task.module').then(m => m.ApproveTaskPageModule) },
  { path: 'Tasks/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/tasks/tasks.module').then(m => m.TasksPageModule) },

    // rewards
  { path: 'rewards', loadChildren: () => import('./pages/rewards/rewards.module').then(m => m.RewardsPageModule) },
  { path: 'rewarddetail/:rewardId', loadChildren: () => import('./pages/forms/rewarddetail/rewarddetail.module').then(m => m.RewarddetailPageModule) },

  // accounts
  { path: 'Payments/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/tab-accounts/payments.module').then(m => m.PaymentsPageModule) },
  { path: 'edit-allowance/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/edit-allowance/edit-allowance.module').then(m => m.EditAllowancePageModule) },
  { path: 'Transfer/:direction/:bankId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/transfer/transfer.module').then(m => m.TransferPageModule) },
  { path: 'Transfer', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/transfer/transfer.module').then(m => m.TransferPageModule) },
  { path: 'AddCreditCard/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/add-credit-card/add-credit-card.module').then(m => m.AddCreditCardPageModule) },
  { path: 'ShareAccount/:familyId/:userId/:accountId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/share-account/share-account.module').then(m => m.ShareAccountPageModule) },
  { path: 'ledgerhistory/:familyId/:userId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/ledgerhistory/ledgerhistory.module').then(m => m.LedgerhistoryPageModule) },

  // modal outlet paths
  { path: 'modal-account-added', outlet: 'modal',
    loadChildren: () => import('./pages/modals/modal-account-added/modal-account-added.module').then(m => m.ModalAccountAddedPageModule) },
  { path: 'credit-card-added', outlet: 'modal',
    loadChildren: () => import('./pages/modals/credit-card-added/credit-card-added.module').then(m => m.CreditCardAddedPageModule) },
  { path: 'goal-created', outlet: 'modal',
    loadChildren: () => import('./pages/modals/goal-created/goal-created.module').then(m => m.GoalCreatedPageModule) },
  { path: 'transfer-sent', outlet: 'modal', loadChildren: () => import('./pages/modals/transfer-sent/transfer-sent.module').then(m => m.TransferSentPageModule) },
  { path: 'gift-card-received', outlet: 'modal',
    loadChildren: () => import('./pages/modals/gift-card-received/gift-card-received.module').then(m => m.GiftCardReceivedPageModule) },
  { path: 'confirm-account-remove/:id', outlet: 'modal',
    loadChildren: () => import('./pages/modals/confirm-account-remove/confirm-account-remove.module').then(m => m.ConfirmAccountRemovePageModule) },
  { path: 'confirm-approve-task/:id', outlet: 'modal',
    loadChildren: () => import('./pages/modals/confirm-approve-task/confirm-approve-task.module').then(m => m.ApproveTaskPageModule) },
  { path: 'confirm-dashboard-sortby', outlet: 'modal',
    loadChildren: () => import('./pages/modals/confirm-dashboard-sortby/confirm-dashboard-sortby.module').then(m => m.ConfirmDashboardSortbyPageModule) },
  { path: 'confirm-reject-task/:id', outlet: 'modal',
    loadChildren: () => import('./pages/modals/confirm-reject-task/confirm-reject-task.module').then(m => m.ConfirmRejectTaskPageModule) },
  { path: 'task-completed/:id', outlet: 'modal',
    loadChildren: () => import('./pages/modals/task-completed/task-completed.module').then(m => m.TaskCompletedPageModule) },

    // rebates
  { path: 'rebatelist/:categorySlug/:latitude/:longitude/:radius', loadChildren: () => import('./pages/rebatelist/rebatelist.module').then(m => m.RebatelistPageModule) },
  { path: 'rebatelist/:latitude/:longitude/:radius', loadChildren: () => import('./pages/rebatelist/rebatelist.module').then(m => m.RebatelistPageModule) },
  { path: 'rebatecategories/:latitude/:longitude/:radius', loadChildren: () => import('./pages/rebates/rebatecategories.module').then(m => m.RebateCategoriesPageModule) },
  { path: 'rebatecategories/:categorySlug/:latitude/:longitude/:radius', loadChildren: () => import('./pages/rebates/rebatecategories.module').then(m => m.RebateCategoriesPageModule) },
  { path: 'rebateslocal/:latitude/:longitude/:radius', loadChildren: () => import('./pages/rebateslocal/rebateslocal.module').then(m => m.RebateslocalPageModule) },
  { path: 'rebateslocal/:categorySlug/:latitude/:longitude/:radius', loadChildren: () => import('./pages/rebateslocal/rebateslocal.module').then(m => m.RebateslocalPageModule) },
  { path: 'rebatedetail/:type/:id', loadChildren: () => import('./pages/rebatedetail/rebatedetail.module').then(m => m.RebatedetailPageModule) },
  { path: 'rebatedetail/:type/:id/:data/:vendor', loadChildren: () => import('./pages/rebatedetail/rebatedetail.module').then(m => m.RebatedetailPageModule) },

  { path: 'tabs', loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: 'learnmore', loadChildren: () => import('./pages/learnmore/learnmore.module').then(m => m.LearnmorePageModule) },
  { path: 'cashback', loadChildren: () => import('./pages/cashback/cashback.module').then(m => m.CashbackPageModule) },
  { path: 'sendmoney', loadChildren: () => import('./pages/sendmoney/sendmoney.module').then(m => m.SendmoneyPageModule) },
  { path: 'sendmoney/:userId/:requestId/:amount', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/sendmoney/sendmoney.module').then(m => m.SendmoneyPageModule) },
  { path: 'userdetail/:userId', loadChildren: () => import('./pages/userdetail/userdetail.module').then(m => m.UserdetailPageModule) },
  { path: 'tab-activity-cashback', loadChildren: () => import('./pages/tab-activity-cashback/tab-activity-cashback.module').then(m => m.TabActivityCashbackPageModule) },
  { path: 'account-adds', loadChildren: () => import('./pages/account-adds/account-adds.module').then(m => m.AccountAddsPageModule) },
  { path: 'share-goal/:goalId', loadChildren: () => import('./pages/share-goal/share-goal.module').then(m => m.ShareGoalPageModule) },
  { path: 'set-sharegoal-header', loadChildren: () => import('./pages/modals/set-sharegoal-header/set-sharegoal-header.module').then(m => m.SetSharegoalHeaderPageModule) },
  { path: 'smart-balance-settings/:bankId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/smart-balance-settings/smart-balance-settings.module').then(m => m.SmartBalanceSettingsPageModule) },
  { path: 'smart-balance-settings/:bankId/:integrationId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/smart-balance-settings/smart-balance-settings.module').then(m => m.SmartBalanceSettingsPageModule) },
  { path: 'set-frequency', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/modals/set-frequency/set-frequency.module').then(m => m.SetFrequencyPageModule) },
  { path: 'EmailVerified', loadChildren: () => import('./pages/email-verified/email-verified.module').then(m => m.EmailVerifiedPageModule) },
  { path: 'advertiserdetail/:title/:id', loadChildren: () => import('./pages/advertiser-detail/advertiser-detail.module').then(m => m.AdvertiserDetailPageModule) },
  { path: 'team-manager', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-manager/team-manager.module').then(m => m.TeamManagerPageModule) },
  { path: 'team-create-workspace', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-create-workspace/team-create-workspace.module').then(m => m.TeamCreateWorkspacePageModule) },
  { path: 'team-create-workspace/:mode', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-create-workspace/team-create-workspace.module').then(m => m.TeamCreateWorkspacePageModule) },
  { path: 'team-add-members/:id', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-add-members/team-add-members.module').then(m => m.TeamAddMembersPageModule) },
  { path: 'team-add-members/:id/:mode', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-add-members/team-add-members.module').then(m => m.TeamAddMembersPageModule) },
  { path: 'team-set-benefits/:id', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-set-benefits/team-set-benefits.module').then(m => m.TeamSetBenefitsPageModule) },
  { path: 'team-set-benefits/:id/:mode', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-set-benefits/team-set-benefits.module').then(m => m.TeamSetBenefitsPageModule) },
  { path: 'teams', loadChildren: () => import('./pages/teams/teams.module').then(m => m.TeamsPageModule) },
  { path: 'team-list', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-list/team-list.module').then(m => m.TeamListPageModule) },
  { path: 'team-details', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-manager/team-details/team-details.module').then(m => m.TeamDetailsPageModule) },
  { path: 'team-details/:id', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-manager/team-details/team-details.module').then(m => m.TeamDetailsPageModule) },
  { path: 'team-edit/:id/:action', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-manager/team-edit/team-edit.module').then(m => m.TeamEditPageModule) },
  { path: 'stripe-success/:id/:destination', loadChildren: () => import('./pages/stripe-success/stripe-success.module').then(m => m.StripeSuccessPageModule) },
  { path: 'stripe-cancel/:destination', loadChildren: () => import('./pages/stripe-cancel/stripe-cancel.module').then(m => m.StripeCancelPageModule) },
  { path: 'stripe-portal-return/:destination', loadChildren: () => import('./pages/stripe-portal-return/stripe-portal-return.module').then(m => m.StripePortalReturnPageModule) },
  { path: 'wellness', redirectTo: '/signup-username?team=true', pathMatch: 'full' },
  { path: 'team-signup', loadChildren: () => import('./pages/team-signup/team-signup.module').then(m => m.TeamSignupPageModule) },
  { path: 'stripecreditcard/:id', loadChildren: () => import('./pages/stripecreditcard/stripecreditcard.module').then(m => m.StripecreditcardPageModule) },
  { path: 'emailcode/:email', loadChildren: () => import('./pages/emailcode/emailcode.module').then(m => m.EmailcodePageModule) },
  { path: 'team-member-detail/:teamId/:memberId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-member-detail/team-member-detail.module').then(m => m.TeamMemberDetailPageModule) },
  { path: 'team-plan/:teamId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-plan/team-plan.module').then(m => m.TeamPlanPageModule) },
  { path: 'team-member-reward/:teamId/:memberId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-member-reward/team-member-reward.module').then(m => m.TeamMemberRewardPageModule) },
  { path: 'team-upload-members/:teamId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-upload-members/team-upload-members.module').then(m => m.TeamUploadMembersPageModule) },
  { path: 'team-one-time-reward/:teamId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-one-time-reward/team-one-time-reward.module').then(m => m.TeamOneTimeRewardPageModule) },
  { path: 'activity-details/:id', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-activity/activity-details/activity-details.module').then(m => m.ActivityDetailsPageModule) },
  { path: 'activity-details', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-activity/activity-details/activity-details.module').then(m => m.ActivityDetailsPageModule) },
  { path: 'team-move-p2p-balance/:teamId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-move-p2p-balance/team-move-p2p-balance.module').then(m => m.TeamMoveP2pBalancePageModule) },
  { path: 'team-p2p-activity/:teamId', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/team-p2p-activity/team-p2p-activity.module').then(m => m.TeamP2pActivityPageModule) },
  { path: 'partial-reimbursement/:teamId/:transactionId/:balance/:transactionAmount', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/partial-reimbursement/partial-reimbursement.module').then(m => m.PartialReimbursementPageModule) },
  { path: 'bank-connection', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/bank-connection/bank-connection.module').then(m => m.BankConnectionPageModule) },
  { path: 'reimburse-to/:teamId/:transactionIds/:amount', canActivate: [ AuthGuard ], loadChildren: () => import('./pages/forms/reimburse-to/reimburse-to.module').then(m => m.ReimburseToPageModule) },
  { path: 'embeddedpage/:id', loadChildren: () => import('./pages/embeddedpage/embeddedpage.module').then(m => m.EmbeddedpagePageModule) },
  { path: 'products', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsPageModule) },
  { path: 'product-addpaymentmethod', loadChildren: () => import('./pages/product-addpaymentmethod/product-addpaymentmethod.module').then(m => m.ProductAddPaymentMethodPageModule) },
  { path: 'product-paymentmethods', loadChildren: () => import('./pages/product-paymentmethods/product-paymentmethods.module').then(m => m.ProductPaymentmethodsPageModule) },
  { path: 'product-paymenthistory', loadChildren: () => import('./pages/product-paymenthistory/product-paymenthistory.module').then(m => m.ProductPaymenthistoryPageModule) },
  { path: 'product-active', loadChildren: () => import('./pages/product-active/product-active.module').then(m => m.ProductActivePageModule) },
  { path: 'browser', loadChildren: () => import('./pages/browser/browser.module').then(m => m.BrowserPageModule) },
  { path: 'mywallit/:page', loadChildren: () => import('./pages/mywallit/mywallit.module').then(m => m.MywallitPageModule) },
  { path: 'tab-primary', loadChildren: () => import('./pages/forms/edit-profile/tabs/tab-primary/tab-primary.module').then(m => m.TabPrimaryPageModule) },
  { path: 'tab-dependents', loadChildren: () => import('./pages/forms/edit-profile/tabs/tab-dependents/tab-dependents.module').then(m => m.TabDependentsPageModule) },
  { path: 'tab-beneficiaries', loadChildren: () => import('./pages/forms/edit-profile/tabs/tab-beneficiaries/tab-beneficiaries.module').then(m => m.TabBeneficiariesPageModule) },
  { path: 'vendorcreditcard/:vendor', loadChildren: () => import('./pages/vendorcreditcard/vendorcreditcard.module').then(m => m.VendorcreditcardPageModule) },
  { path: 'stripe-vendor-return/:id/:destination/:vendor', loadChildren: () => import('./pages/stripe-vendor-return/stripe-vendor-return.module').then(m => m.StripeVendorReturnPageModule) },
  { path: 'membercardpage/:vendorproductid', loadChildren: () => import('./pages/membercardpage/membercardpage/membercardpage.module').then(m => m.MembercardpagePageModule) },
  { path: 'vendor-updates', loadChildren: () => import('./pages/vendor-updates/vendor-updates/vendor-updates.module').then(m => m.VendorUpdatesPageModule) },
  { path: 'brandgroup/:groupname', loadChildren: () => import('./pages/brandgroup/brandgroup.module').then(m => m.BrandgroupPageModule) },
  {
    path: 'memberenroll/:vendorproductid',
    loadChildren: () => import('./pages/memberenroll/memberenroll.module').then( m => m.MemberenrollPageModule)
  },
  {
    path: 'group-product-questions/:json',
    loadChildren: () => import('./pages/group-product-questions/group-product-questions.module').then( m => m.GroupProductQuestionsPageModule)
  },
  {
    path: 'group-product-enroll/:json',
    loadChildren: () => import('./pages/group-product-enroll/group-product-enroll.module').then( m => m.GroupProductEnrollPageModule)
  },
  {
    path: 'group-product-choose/:json',
    loadChildren: () => import('./pages/group-product-choose/group-product-choose.module').then( m => m.GroupProductChoosePageModule)
  },
];

const dwollaRoutes: Routes = [
  // paths that depend on back end bank being dwolla
  { path: 'Addbank/:familyId/:userId',  canActivate: [ AuthGuard ], data: { bank: 'dwolla'}, loadChildren: () => import('./pages/bank-providers/dwolla/addbank-dwolla/addbank-dwolla.module').then(m => m.AddbankDwollaPageModule) },
  { path: 'Addbank',  canActivate: [ AuthGuard ], data: { bank: 'dwolla'}, loadChildren: () => import('./pages/bank-providers/dwolla/addbank-dwolla/addbank-dwolla.module').then(m => m.AddbankDwollaPageModule) },
  { path: 'addfunding/:familyId/:userId/:accountId', canActivate: [ AuthGuard ], data: { bank: 'dwolla'}, loadChildren: () => import('./pages/bank-providers/dwolla/addfunding-dwolla/addfunding-dwolla.module').then(m => m.AddfundingDwollaPageModule) },
  { path: 'withdrawfunding/:familyId/:userId/:accountId', canActivate: [ AuthGuard ], data: { bank: 'dwolla'}, loadChildren: () => import('./pages/bank-providers/dwolla/withdrawfunding-dwolla/withdrawfunding-dwolla.module').then(m => m.WithdrawfundingDwollaPageModule) },
  { path: 'transferhistory/:familyId/:userId/:bankId', canActivate: [ AuthGuard ], data: { bank: 'dwolla'}, loadChildren: () => import('./pages/bank-providers/dwolla/tab-transfers-history-dwolla/tab-transfers-history-dwolla.module').then(m => m.TabTransfersHistoryDwollaPageModule) },
  { path: 'BankDetail/:familyId/:userId/:bankId/:fundingSourceId', canActivate: [ AuthGuard  ], data: { bank: 'dwolla'}, loadChildren: () => import('./pages/bank-providers/dwolla/bank-detail-dwolla/bank-detail-dwolla.module').then(m => m.BankDetailPageModule) },
  { path: 'BankDetail/:familyId/:userId/:bankId', canActivate: [ AuthGuard  ], data: { bank: 'dwolla'}, loadChildren: () => import('./pages/bank-providers/dwolla/bank-detail-dwolla/bank-detail-dwolla.module').then(m => m.BankDetailPageModule) },
  { path: 'child-transfer', canActivate: [ AuthGuard   ], data: { bank: 'dwolla'}, loadChildren: () => import('./pages/bank-providers/dwolla/child-transfer-dwolla/child-transfer-dwolla.module').then(m => m.ChildTransferDwollaPageModule) },
  { path: 'transaction-detail/:familyId/:userId/:transactionId', canActivate: [ AuthGuard  ], data: { bank: 'dwolla'}, loadChildren: () => import('./pages/bank-providers/dwolla/transaction-detail-dwolla/transaction-detail-dwolla.module').then(m => m.TransactionDetailDwollaPageModule) },

];

@NgModule({
  imports: [RouterModule.forRoot([...routes, ...dwollaRoutes])],
  exports: [RouterModule]
})
export class AppRoutingModule { }
