import { Injectable } from '@angular/core';
import { AppSettings} from '../../app.settings';
import {ActivatedRoute} from '@angular/router';

declare var gtag: Function;
declare var google_optimize;

@Injectable({
  providedIn: 'root'
})
export class ExperimentService {

  public static DEFAULT_ALLOWANCE = 'DEFAULT_ALLOWANCE';
  public static EXPLAIN_IDENTITY_REQUIRED = 'EXPLAIN_IDENTITY_REQUIRED';
  public static SIGNUP_ORDER = 'SIGNUP_ORDER';

  static prodExperiments = {
    DEFAULT_ALLOWANCE: { googleId: 'mpAvyM8bQKucxS9UAFyVhw', variant: undefined },
    EXPLAIN_IDENTITY_REQUIRED: { googleId: 'xotJj2vITK2b5aqax5YeUw', variant: undefined },
    SIGNUP_ORDER: { googleId: 'C5BhNBqLSPGC5h434DgiBw', variant: undefined }
  };

  constructor(
      private route: ActivatedRoute
  ) {
  }

  getExperimentId(id): String {
    return ExperimentService.prodExperiments[id].googleId;
  }

  getOptimizeExperiments(func) {
    gtag('event', 'optimize.callback', {
      callback: func
    });
  }

  getVariant(experimentId = null, func = null) {
      this.route.queryParams.subscribe(params => {
        Object.keys(ExperimentService.prodExperiments).forEach(key => {
          if (params[key]) {
            ExperimentService.prodExperiments[key].variant = +params[key];
          }
        });
        if (!experimentId) {
          return;
        }
        const savedVariant = ExperimentService.prodExperiments[experimentId].variant;
        if (savedVariant) {
          console.log('experiment', experimentId, ExperimentService.prodExperiments[experimentId].googleId, 'saved variant', savedVariant);
          func(savedVariant);
        } else {
          const googleExperimentId = this.getExperimentId(experimentId);
          let variant = typeof google_optimize !== 'undefined' ?  +google_optimize.get(googleExperimentId) : 0;
          if (typeof variant === 'undefined') {
            variant = 0;
          }
          console.log('experiment', experimentId, googleExperimentId, 'optimize variant', variant);
          func(variant);
        }
      });
  }
}
