import { Injectable } from '@angular/core';
import {Platform, AlertController} from '@ionic/angular';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import { BrowserDetectService } from './../services/browser-detect/browser-detect.service';

@Injectable({
  providedIn: 'root'
})
export class BlockCookieBrowserGuard implements CanActivate  {

    constructor(
      public router: Router,
      public browserDetectService: BrowserDetectService,
      public alertCtrl: AlertController
  ) {}

  canActivate(): boolean {
    const detectFalseBrowser = this.browserDetectService.detectBrowser(true);
    if (!detectFalseBrowser && navigator.cookieEnabled) {
      this.router.navigate(['/signup-start']);
      return false;
    }
    if (!navigator.cookieEnabled) {
      this.showAlert();
      this.router.navigate(['/signup-start']);
      return false;
    }
    return true;
  }

  async showAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Warning!',
      subHeader: 'Sorry, you need to enable cookies in your browser to use this app.',
      buttons: ['OK']
    });
    return await alert.present();
  }
}
