import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {FormGroup} from '@angular/forms';
import {BaseComponent} from '../base/base.component';
import {Router} from '@angular/router';
import {ToastComponent} from '../toast/toast.component';

@Component({
  selector: 'app-lockbutton',
  templateUrl: './lockbutton.component.html',
  styleUrls: ['./lockbutton.component.scss'],
})
export class LockbuttonComponent extends BaseComponent implements OnInit {

  @Output() clickedEvent: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  constructor(
      apiService: ApiService,
      private router: Router,
      private toastComponent: ToastComponent
  ) {
    super(apiService);
  }

  ngOnInit(
  ) {
  }

  lockedClicked() {
    if (this.clickedEvent) {
      this.clickedEvent.emit();
      this.toastComponent.presentToast('Please log in to view product details');
      // this.router.navigate([ '/learnmore' ]);
    }
  }

}
