import { Component, OnInit } from '@angular/core';
import {BrandsService} from '../../services/brands/brands.service';
import {ApiService} from '../../services/api/api.service';

@Component({
  selector: 'app-header-login',
  templateUrl: './header-login.component.html',
  styleUrls: ['./header-login.component.scss'],
})
export class HeaderLoginComponent implements OnInit {

  protected spinnerLevel = 0;

  constructor(
      public brandsService: BrandsService,
      public apiService: ApiService
  ) { }

  ngOnInit() {
    this.apiService.spinnerSubscribe(val => this.spinnerLevel = val);
  }



}
