import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {UtilsService} from '../utils/utils.service';
import {ApiService} from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class InvitationsService {

  constructor(
      private restangular: Restangular,
      private utilsService: UtilsService,
      private apiService: ApiService
  ) {
    this.apiService.isAuthenticated();
  }

  sendInvitation(familyId: string, userId: string, myName: string, phoneNumber: string, email: string, parent: boolean,
   magicLink: boolean, data = null): Promise<any> {
    const inviteData = {
      invitationType : phoneNumber ? 'phoneNumber' : (magicLink ? '' : 'email'),
      invitationValue: phoneNumber ? this.utilsService.fixPhoneNumber(phoneNumber) : email,
      userType: parent ? 'parent' : 'child',
      firstName: myName,
      baseDomain: window.location.protocol + '//' + window.location.host,
      permissionLevel: parent ? 8 : 1,
    };
    if (data) {
      inviteData['birthdate'] = data.birthdate;
      inviteData['lastName'] = data.lastName;
      inviteData['allowance'] = +data.allowance;
      inviteData['nickname'] = data.nickname;
      inviteData['email'] = data.email;
    }
    if (magicLink) {
      inviteData['userName'] = data.firstName + ' ' + data.lastName;
      return this.restangular.one('families', familyId).one('users', userId).one('magic-link').customPOST(inviteData).toPromise();
    } else {
      return this.restangular.one('v3').one('users', userId).one('invites').one(phoneNumber ? 'sms' : 'e-mail').
      customPOST(inviteData).toPromise();
    }
  }

  lookupMagicLink(link: string) {
    return this.restangular.one('public').one('magic-link').one(link).get().toPromise();
  }

  acceptSMSInvitation(phoneNumber: string): Promise<any> {
    return this.restangular.one('v3').one('invites', phoneNumber).one('status').one('accepted').put().toPromise();
  }

  cancelSMSInvitation(phoneNumber: string): Promise<any> {
    return this.restangular.one('v3').one('invites', phoneNumber).remove().toPromise();
  }

  resendSMSInvitations(userId: string): Promise<any> {
    return this.restangular.one('v3').one('users', userId).one('re-send-invites').put().toPromise();
  }

  lookupInvitation(invitationId: string): Promise<any> {
    return this.restangular.one('v3').one('invites', invitationId).get().toPromise();
  }

  getInvitations(familyId: string): Promise<any> {
    return this.restangular.one('v3').one('families', familyId).one('invites').getList().toPromise();
  }

  getMagicCodes(familyId: string): Promise<any> {
    return this.restangular.one('families', familyId).one('magicLinks').getList().toPromise();
  }

  getMagicCodeForUser(familyId: string, userId: string): Promise<any> {
    return this.getMagicCodes(familyId).then(data => {
      const child = data.find(item => item.userId === userId);
      return child ? child.magicLink : '';
    });
  }

  resetMagicCode(familyId: string, userId: string, childId: string): Promise<any> {
    const data = {
      childId: childId
    };
    return this.restangular.one('families', familyId).one('users', userId).all('reset-magic-link').customPUT(data).toPromise();
  }

  removeInvitation(familyId: string, userId: string, invitedUserId): Promise<any> {
    return this.restangular.one('v3').one('family', familyId).one('user', userId).one('invites', invitedUserId).remove().toPromise();
  }

}
