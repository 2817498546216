import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-goal-completion-bar',
  templateUrl: './goal-completion-bar.component.html',
  styleUrls: ['./goal-completion-bar.component.scss'],
})
export class GoalCompletionBarComponent implements OnInit {

  @Input() goal;
  @Input() light = false;
  @Input() percentage = true;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  private cgv;

  constructor() { }

  ngOnInit() {
    this.currentGoalValue(this.goal);
    this.valueChange.emit((100 * this.cgv / this.goal.cost).toFixed(0));
  }

  currentGoalValue(goal) {
    return goal?.balance?.value;
  }

  getPercentage(goal) {
    return (100 * this.currentGoalValue(goal) / goal.cost).toFixed(0);
  }

}
