import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommissionJunctionService} from '../../../services/commissionjunction/commissionjunction.service';

@Component({
  selector: 'app-set-advertisers',
  templateUrl: './set-advertisers.component.html',
  styleUrls: ['./set-advertisers.component.scss'],
})
export class SetAdvertisersComponent implements OnInit {

  @Output() advertisersChangedEvent: EventEmitter<string> = new EventEmitter<string>();

  @Input() advertisers;

  protected cjAdvertisers;

  constructor(
      private cjService: CommissionJunctionService
  ) { }

  ngOnInit() {
    this.advertisers = this.advertisers.split(',');
      this.cjService.getAdvertisers().then(result => {
        this.cjAdvertisers = result;
      });
  }

  cancelClicked() {
    this.advertisersChangedEvent.emit('');
  }

  setClicked() {
    this.advertisersChangedEvent.emit(this.advertisers.join(','));
  }

  advertiserChanged(event) {
    this.advertisers = event.detail.value;
  }

  isAdvertiserSelected(advertiser) {
    return false; // this.advertisers.split(',').find(id => id === advertiser.id) ? true : false;
  }

  openAdvertisers() {
    setTimeout(() => {
      const buttonElements = document.querySelectorAll('div.alert-checkbox-label');
      if (!buttonElements.length) {
        this.openAdvertisers();
      } else {
        for (let index = 0; index < buttonElements.length; index++) {
          buttonElements[index].innerHTML += '<img  src="' + this.cjAdvertisers[index].logo + '" style="width:60px;float:right;margin-right: 5px;"/>';
        }
      }
    }, 100);
  }

}
