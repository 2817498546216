import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Router} from '@angular/router';

@Component({
  selector: 'app-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.scss']
})
export class YesNoComponent implements OnInit {

  @Input() color: string;

  constructor(
      private modalCtrl: ModalController,
      private router: Router
  ) { }

  ngOnInit() {
  }

  yesClicked() {
    this.modalCtrl.dismiss(true);
    this.router.navigate([{ outlets: { modal: null }}]);
  }

  noClicked() {
    this.modalCtrl.dismiss(false);
    this.router.navigate([{ outlets: { modal: null }}]);
  }
}
