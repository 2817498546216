import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-set-keywords',
  templateUrl: './set-keywords.component.html',
  styleUrls: ['./set-keywords.component.scss'],
})
export class SetKeywordsComponent implements OnInit {

  @Output() keywordsChangedEvent: EventEmitter<string> = new EventEmitter<string | undefined>();

  @Input() keywords;

  constructor() { }

  ngOnInit() {
  }

  cancelClicked() {
    this.keywordsChangedEvent.emit(undefined);
  }

  setClicked() {
    this.keywordsChangedEvent.emit(this.keywords);
  }

  clearClicked() {
    this.keywordsChangedEvent.emit('');
  }

  keywordsChanged(event) {
    this.keywords = event.detail.value;
  }


}
