import {Injectable} from '@angular/core';
import {Auth} from 'aws-amplify';
import {CognitoUser, CognitoUserSession, ISignUpResult} from 'amazon-cognito-identity-js';
import {AlertController} from '@ionic/angular';

export interface userCredentialsInterface {
    username: string;
    password: string;
}

@Injectable({
    providedIn: 'root'
})
export class CognitoMfaService {
    currentCognitoUser: any;



    constructor(private alertController: AlertController) {}

    async cognitoMFASignIn(userCredentials: userCredentialsInterface): Promise<CognitoUser>  {
        this.currentCognitoUser = await Auth.signIn(userCredentials);
        return this.currentCognitoUser;
    }

    cognitoMFASignUp(userCredentials: userCredentialsInterface): Promise<ISignUpResult> {
        return Auth.signUp({username: userCredentials.username, password: userCredentials.password, attributes: {email: userCredentials.username}});
    }

    cognitoMFAConfirmSignIn(TOTPCode: string, mfaType: 'SOFTWARE_TOKEN_MFA'): Promise<any> {
        return Auth.confirmSignIn(this.currentCognitoUser, TOTPCode, mfaType);
    }

    async cognitoMFAConfirmSignUp(username: string, TOTPCode: string, user: userCredentialsInterface): Promise<any> {
        await Auth.confirmSignUp(username, TOTPCode);
        this.currentCognitoUser = await Auth.signIn(user);
        return this.currentCognitoUser;
    }

    async cognitoSetupTOTP(): Promise<string> {
        await this.cognitoUpdateCurrentUser();
        return Auth.setupTOTP(this.currentCognitoUser);
    }

    async cognitoVerifyTOTPToken(totpCode: string): Promise<CognitoUserSession > {
        await this.cognitoUpdateCurrentUser();
        return Auth.verifyTotpToken(this.currentCognitoUser, totpCode);
    }

    async cognitoSetPreferredMFA(mfaType: 'TOTP'): Promise<string> {
        await this.cognitoUpdateCurrentUser();
        return Auth.setPreferredMFA(this.currentCognitoUser, mfaType);
    }

    async cognitoRemoveMFA(): Promise<any> {
        await this.cognitoUpdateCurrentUser();
        await Auth.setPreferredMFA(this.currentCognitoUser, 'NOMFA');
        return Auth.forgetDevice();
    }

    cognitoResendSignUp(username: string): Promise<any> {
        return Auth.resendSignUp(username);
    }

    async cognitoUpdateCurrentUser(): Promise<void> {
        await Auth.currentAuthenticatedUser().then((user: CognitoUser): void => {
            this.currentCognitoUser = user;
        });
    }

    async cognitoRegisterUserDevice(): Promise<any> {
        return await Auth.rememberDevice();
    }

    async getCurrentCognitoUser(): Promise<any> {
        await this.cognitoUpdateCurrentUser();
        return Auth.getPreferredMFA(this.currentCognitoUser);
    }

    async cognitoInputCodeShowModal(modalText: string, modalTitle: string): Promise<string> {
        return new Promise((resolve): void => {
            this.alertController.create({
                header: modalTitle ?? 'Enter MFA Code',
                subHeader: modalText ?? `Add the MFA code`,
                inputs: [
                    {
                        name: 'mfa',
                        label: 'MFA Code',
                        type: 'text',
                        value: '',
                        placeholder: 'Enter code'
                    }
                ],
                buttons: [
                    {
                        text: 'Cancel',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: (): void => {
                            this.alertController.dismiss();
                            resolve('');
                        }
                    },
                    {
                        text: 'Verify code',
                        handler: (data: any): void => {
                            this.alertController.dismiss();
                            resolve(data.mfa);
                        }
                    }
                ]
            }).then((alert: HTMLIonAlertElement): void => {
                alert.present();
            });
        });
    }


}
