import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {VendorsService} from '../../services/vendors/vendors.service';
import {CryptoService} from '../../services/crypto/crypto.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-groupproducts',
  templateUrl: './groupproducts.component.html',
  styleUrls: ['./groupproducts.component.scss'],
})
export class GroupproductsComponent implements OnInit {

  protected products;
  protected compareCount = 0;
  protected comparePlans = [];
  protected compareAttributes = [];
  protected compareMode = false;
  private options = {};

  constructor(
      private domSanitizer: DomSanitizer,
      private vendorsService: VendorsService,
      private cryptoService: CryptoService,
      private router: Router
  ) { }

  ngOnInit() {
    this.vendorsService.getAllVendorGroupProductsForUser().then(result => {
      result.forEach(product => {
        this.vendorsService.getProductChoices(product.id).then(choices => {
          product.choices = choices;
          product.metadata.logo = this.domSanitizer.bypassSecurityTrustUrl(product.metadata.logo);
          choices.forEach(choice => {
            this.options[choice.name] = choice.selectoptions[0].amount;
          });
          this.updateValues(product);
        });
      });
      this.products = result;
    });
  }

  private evaluateWithContext(code, context) {
    return Function(...Object.keys(context), `return ${code};`)(...Object.values(context));
  }

  private updateValues(product) {
    product.plans.forEach(plan => {
      plan.product = product;
      plan.attributes.forEach(attribute => {
        function parseFunctionString(input) {
          const regex = /(.*)__([A-Z,a-z,0-9]+)\((.*)\)$/;
          const match = input.match(regex);
          if (match) {
            const prefix = match[1];
            const functionName = match[2]; // Extracts FUNCTIONNAME
            const args = match[3];        // Extracts ARGS
            return { prefix, functionName, args };
          } else {
            return null;
          }
        }
        const func = parseFunctionString(attribute.savedcontents || attribute.contents);
        if (func) {
          switch (func.functionName) {
            case 'lookup':
              if (!attribute.savedcontents) {
                attribute.savedcontents = attribute.contents;
              }
              attribute.contents = func.prefix + this.evaluateWithContext(func.args, {option: this.options});
              break;
          }
        }
      });
      plan.attributes = plan.attributes.slice();
      plan.show = true;
    });
    let matchedProduct = this.products.find(thisProduct => thisProduct.id === product.id);
    if (matchedProduct) {
      matchedProduct = product;
    }
  }

  getTier(plan) {
    const tierNames = {
      EMPL: 'Single Member',
      SPSE: 'Member plus Spouse',
      DPDT: 'Member plus Family'
    };
    return tierNames[plan.tier];
  }

  sanitizeHtml(html) {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  showDocument(url) {
    this.router.navigate(['embeddedpage', this.cryptoService.encodeJSON({url})]);
  }

  compareBoxClicked(event, productPlan) {
    if (event.detail.checked) {
      this.compareCount++;
      productPlan.compareSelected = true;
    } else {
      this.compareCount--;
      productPlan.compareSelected = false;
    }
  }

  select(plan, product) {
    this.vendorsService.getVendorProduct(product.vendorProductId).then(vendorProduct => {
      this.router.navigate(['group-product-questions', this.cryptoService.encodeJSON({detailsUrl: product.metadata.detailsUrl, planId: plan.id, planName: `${vendorProduct.vendorName} ${product.metadata.name} ${this.getTier(plan)}`, amount: parseFloat(plan.attributes[0].contents.replace(/[\$,]/g, ''))})]);
    });
  }

  compare() {
    this.compareMode = !this.compareMode;
    this.comparePlans = [];
    this.compareAttributes = [];
    this.products.forEach(product => {
      product.plans.forEach(plan => {
        if (plan.compareSelected) {
          this.comparePlans.push(plan);
          plan.compare.forEach(comparison => {
            if (!this.compareAttributes.find(attribute => attribute === comparison.name)) {
              this.compareAttributes.push(comparison.name);
            }
          });
        }
      });
    });
  }

  getComparisonValue(plan, compareAttribute) {
    const compareItem = plan.compare.find(comparison => comparison.name === compareAttribute);
    return compareItem?.value || '';
  }

  optionChange(event, product, name) {
    this.options[name] = event.detail.value;
    this.updateValues(product);
  }

}
