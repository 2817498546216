import {EventEmitter, Injectable} from '@angular/core';
import {loadStripe} from '@stripe/stripe-js';
import {ToastComponent} from '../../components/toast/toast.component';
import {Restangular} from 'ngx-restangular';
import {UsersService} from '../users/users.service';
import {TeamsService} from '../teams/teams.service';
import {Platform} from '@ionic/angular';
import {AppSettings} from '../../app.settings';
import {UiAlertService} from '../ui-alert/ui-alert.service';

export enum Plan {
  Free = 'Free',
  Plus = 'Plus',
  PlusAnnual = 'PlusAnnual'
}

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  public SERVICE_FEE = 0.05;
  public PREMIUM_MONTHLY_FEE = 7.00;
  public PREMIUM_ANNUAL_FEE = 66.00;
  public STANDARD_CATEGORIES = 6;

  private stripe;
  private paymentMethodsChangedEvent: EventEmitter<{}> = new EventEmitter<{}>();
  private productsPurchasedChangedEvent: EventEmitter<{}> = new EventEmitter<{}>();

  constructor(
    private toastComponent: ToastComponent,
    private restangular: Restangular,
    private usersService: UsersService,
    private teamsService: TeamsService,
    private platform: Platform,
    private uiAlertService: UiAlertService
  ) {
      this.stripe = this.setupStripe();
  }

  notifyProductsPurchasedChanged(id: string) {
    this.productsPurchasedChangedEvent.emit(id);
  }

  monitorProductsPurchasedChanged(func) {
    this.productsPurchasedChangedEvent.subscribe(value => func(value));
  }

  notifyPaymentMethodsChanged(id: string) {
    this.paymentMethodsChangedEvent.emit(id);
  }

  monitorPaymentMethodsChanged(func) {
    this.paymentMethodsChangedEvent.subscribe(value => func(value));
  }

  private async setupStripe() {
    return await loadStripe(AppSettings.getStripeInfo().key);
  }

  async setupPaymentMethod(amount, count, email, description, teamId, customerId, returnPage) {
    const stripe = await this.stripe;
    const session = await this.stripe.getCheckoutSessionID(amount, count, email, description, customerId, returnPage);
    this.teamsService.modifyTeam(teamId, {noEmail: true, stripeSessionId: session.id, stripeCustomerId: session.customer});
    stripe.redirectToCheckout({ sessionId: session.id }).then(function (result) {
      }).catch(error => {
        this.toastComponent.presentToast(error.error.message);
      });
  }

  changePlan(team, members, planName, doneFunc) {
    const periodName = planName === Plan.Plus ? 'Monthly' : 'Annual';
    const chargeAmount = members.length * (planName === Plan.Plus ? this.PREMIUM_MONTHLY_FEE : this.PREMIUM_ANNUAL_FEE);
    const oldChargeAmount = members.length * (team.plan === Plan.Plus ? this.PREMIUM_MONTHLY_FEE : this.PREMIUM_ANNUAL_FEE);
    switch (planName) {
      case Plan.Free:
        let moreInfo = team.categories.length > this.STANDARD_CATEGORIES ? `Your ${team.categories.length} current categories will be reduced to ${this.STANDARD_CATEGORIES}.` : '';
        const membersWithCustomAllowanceCount = members.find(member => member.amount !== team.amount).length;
        if (membersWithCustomAllowanceCount > 0) {
          moreInfo += ` Your ${membersWithCustomAllowanceCount} members with a custom allowance will have it reset to the team allowance of $${team.amount}`;
        }
        this.uiAlertService.presentAlertConfirm(`Downgrade to Free Plan.<br><br>This will cancel the ${team.plan === Plan.Plus ? 'monthly' : 'annual'} recurring charge of $${oldChargeAmount.toFixed(2)}  to your saved credit card for the Plus ${periodName} plan for ${members.length} member${members.length === 1 ? '' : 's'}. ${moreInfo}`).then(confirm => {
          if (confirm) {
            this.updatePlan(team.stripeCustomerId, chargeAmount * members.length, members.length, team.stripePaymentMethod, team.id, planName).then(_ => {
              this.teamsService.modifyTeam(team.id, {'plan': Plan.Free}).then(() => {
                this.toastComponent.presentToast('Payment cancelled and plan changed to Free');
                team.plan = Plan.Free;
                doneFunc();
              });
            });
          }
        });
        break;
      case Plan.Plus:
      case Plan.PlusAnnual:
        const period = planName === Plan.Plus ? 'month' : 'year';
        this.uiAlertService.presentAlertConfirm(
          `Upgrade to Plus ${planName === Plan.PlusAnnual ? 'Annual' : ''} Plan.<br><br>This will initiate ${planName === Plan.Plus ? 'a monthly' : 'an annual'} recurring charge of ` +
            `$${chargeAmount.toFixed(2)} for ${members.length ? members.length : 'new'} ` +
            `member${members.length === 1 ? '' : 's'} to your saved credit card on the first day of each ${period}. For the first ${period}, the amount will be pro-rated. Transactions will appear on your statement as 'Wallit'.`
        ).then(confirm => {
          if (confirm) {
            this.updatePlan(team.stripeCustomerId, chargeAmount * members.length, members.length, team.stripePaymentMethod, team.id, planName).then(_ => {
              this.teamsService.modifyTeam(team.id, {'plan': planName}).then(() => {
                this.toastComponent.presentToast(`Payment made and plan changed to Plus ${periodName}`);
                team.plan = planName;
                doneFunc();
              });
            });
          }
        });
        break;
    }
  }

  confirmCardSetup(clientSecret, data) {
      return this.stripe.then(stripe => stripe.confirmCardSetup(clientSecret, data));
  }

  confirmSetup(data) {
    return this.stripe.then(stripe => stripe.confirmSetup(data));
  }

  removeCard(paymentId) {
      return this.restangular.one('users', paymentId).one('stripe').remove().toPromise();
  }

  getCharges(customerId) {
      return this.restangular.one('users', customerId).one('stripe', 'getcharges').getList().toPromise();
  }

  getInvoices(customerId) {
    return this.restangular.one('users', customerId).one('stripe', 'getinvoices').getList().toPromise();
  }

  getInvoicesV2(customerId: string, paymentMethod: string, chargeHistory: boolean): Promise<any> {
    return this.restangular
        .one('users', customerId)
        .one('stripe')
        .one('paymentMethod', paymentMethod)
        .one('invoices')
        .one(chargeHistory)
        .getList().toPromise();
  }

  getElements() {
      return this.stripe.then(stripe => stripe.elements({mode: 'setup', currency: 'usd'}));
  }

  getVendorElements(vendor): Promise<any> {
    return this.restangular.one('stripe').one('vendorpublickey', vendor).get().toPromise().then(result => {
      return loadStripe(result.publicKey).then(vendorStripe => {
        return vendorStripe.elements({mode: 'setup', currency: 'usd'});
      }).catch(error => {
        console.log('VENDOR STRIPE LOAD ERROR', error);
      });
    });
  }

  private getAppEndpoint() {
    let endPoint = location.protocol + '//' + location.host;
    console.log('APPENDPOINT', endPoint)
    if (location.hostname === 'localhost') {
      endPoint = 'http://localhost:4000';
    }
    if (this.platform.is('capacitor')) {
      endPoint = 'https://www.wallit.app/mywallit';
    }
    return endPoint;
  }

  private getCheckoutSessionID(amount, count, email, description, customerId, returnPage) {
    return this.restangular.one('users', this.usersService.getCurrentUserId()).one('stripe').one('session').customPOST({amount, count, email, description, customerId, destination: this.encodeDestination(returnPage), appEndpoint: this.getAppEndpoint()}).toPromise();
  }

  getVendorCheckoutSessionID(vendor, amount, name, description, returnPage) {
    return this.restangular.one('users', this.usersService.getCurrentUserId()).one('stripe').one('vendorsession').customPOST({vendor, amount, description, name, destination: this.encodeDestination(returnPage, vendor), appEndpoint: this.getAppEndpoint()}).toPromise();
  }

  getVendorSession(vendor, id) {
    return this.restangular.one('stripe').one('vendorsession', vendor).one(id).get().toPromise();
  }

  gotoPortal(customerId, returnPage) {
    return this.restangular.one('users', customerId).one('stripe').one('portal').customPOST({destination: this.encodeDestination(returnPage), appEndpoint: this.getAppEndpoint()}).toPromise().then(session => {
      if (window.location !== window.parent.location) {
        window.open(session.url, '_blank');
      } else {
        window.location.href = session.url;
      }
    });
  }

  private encodeDestination(destination, vendor?) {
    const routerUrl = destination.replace(/\//g, ':');
    return vendor ? `${routerUrl}/${vendor}` : routerUrl;
  }

  decodeDestination(destination) {
    return destination.replace(/:/g, '/');
  }

  makePayment(customerId, description, amount, newMembers, stripePaymentMethod, serviceFee, teamId, planName) {
    return this.restangular.one('users', stripePaymentMethod).one('stripe').one('makepayment').customPOST({customerId, amount, newMembers: newMembers, description, serviceFee, teamId, planName}).toPromise();
  }

  makeOneTimePayment(customerId, description, amount, memberEmail, message, stripePaymentMethod, teamId, memberBalance, type) {
    return this.restangular.one('users', stripePaymentMethod).one('stripe').one('makeonetimepayment').customPOST({customerId, amount, memberEmail, message, description, teamId, memberBalance, type}).toPromise();
  }

  updatePlan(customerId, amount, memberCount, stripePaymentMethod, teamId, planName) {
    return this.restangular.one('users', stripePaymentMethod).one('stripe').one('updateplan').customPOST({customerId, amount, memberCount, teamId, planName}).toPromise();
  }

  cancelPayment(customerId, stripePaymentMethod, teamId) {
    return this.restangular.one('users', stripePaymentMethod).one('stripe').one('cancelpayment').customPOST({customerId, teamId}).toPromise();
  }

  changeAllowance(customerId, amount, allMembers, serviceFee) {
    return this.restangular.one('users').one('stripe').one('changeallowance').customPOST({customerId, amount, totalMemberCount: allMembers, serviceFee}).toPromise();
  }

  getSetupIntent(teamId, customerId, email, userId?) {
      return this.restangular.one('users', this.usersService.getCurrentUserId()).one('stripe').one('getsetupintent').get({customerId: customerId ? customerId : '', email: encodeURIComponent(email), teamId, userId: userId ? userId : ''}).toPromise();
  }

  getProducts(brandId: string) {
    return this.restangular.one('users', this.usersService.getCurrentUserId()).one('stripe').one('getproducts', brandId).getList().toPromise();
  }

  cancelSubscription(subscriptionId: string) {
    return this.restangular.one('products').one('cancelsubscription', subscriptionId).put().toPromise();
  }

  getPurchasedProducts(customerId: string) {
    return this.restangular.one('products').one('purchased', customerId).getList().toPromise();
  }

  getProduct(productId: string) {
    return this.restangular.one('stripe').one('product', productId).get().toPromise();
  }

  getPaymentMethods() {
    return this.restangular.one('users', this.usersService.getCurrentUserId()).one('stripe').one('getpaymentmethods').getList().toPromise();
  }

  getCustomerPaymentMethods(customerId) {
    return this.restangular.one('stripe').one('getcustomerpaymentmethods', customerId).getList().toPromise();
  }

  removePaymentMethod(paymentMethodId) {
    return this.restangular.one('users', paymentMethodId).one('stripe').remove().toPromise();
  }

  getUserPaymentInfo() {
    return this.restangular.one('users', this.usersService.getCurrentUserId()).one('stripe').one('getpaymentinfo').get().toPromise();
  }

  purchaseSubscription(customerId: string, productId: string, quantity = 0, noProrate) {
    return this.restangular.one('users', customerId).one('stripe').one('purchasesubscription').customPUT({productId, quantity, noProrate}).toPromise();
  }

  setSubscriptionProduct(subscriptionId: string, productId: string) {
    console.log('SETSUBSCRIPTIONPRODUCT', subscriptionId, productId);
    return this.restangular.one('stripe').one('product', productId).one('subscription', subscriptionId).put().toPromise();
  }

  isProductPurchased(productId: string): Promise<any> {
    return this.getUserPaymentInfo().then(paymentInfo => {
      if (paymentInfo.stripeCustomerId) {
        return this.getPurchasedProducts(paymentInfo.stripeCustomerId).then(purchases => {
          return !!purchases.find(purchase => purchase.items.data[0].plan.product === productId);
        });
      } else {
        return false;
      }
    });
  }

}

