import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-team-info-card',
  templateUrl: './team-info-card.component.html',
  styleUrls: ['./team-info-card.component.scss'],
})
export class TeamInfoCardComponent implements OnInit {

  @Input() teamMember;

  constructor(
      private router: Router
  ) { }

  ngOnInit() {}

  teamClicked() {
    this.router.navigate(['tabs/tab-activity/team', this.teamMember.id]);
  }

}
