import { Injectable } from '@angular/core';
import {BrandsService} from '../brands/brands.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(
      private brandsService: BrandsService
  ) { }

  getMessage(id: string): string {
    return this.brandsService.getContentMessage(id);
  }

}
