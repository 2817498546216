import {Component, Input, OnInit} from '@angular/core';
import {FieldbaseComponent} from '../fieldbase/fieldbase.component';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-get-amount',
  templateUrl: './get-amount.component.html',
  styleUrls: ['./get-amount.component.scss']
})
export class GetAmountComponent extends FieldbaseComponent implements OnInit {

  @Input() label: string;

  constructor(
      private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit() {
    this.parent.addControl('amount', this.formBuilder.control('', [ Validators.required ]));
  }
  setVal(value: any, frmControlName: string) {
    this.parent.get(frmControlName).setValue(value);
  }
}
