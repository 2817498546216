import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarHideDirective } from './navbar-hide.directive';
import { ArrowKeyboardDirective } from './arrow-keyboard.directive';

@NgModule({
  declarations: [NavbarHideDirective, ArrowKeyboardDirective],
  imports: [
    CommonModule
  ],
  exports: [NavbarHideDirective, ArrowKeyboardDirective]
})
export class SharedDirectivesModule { }
