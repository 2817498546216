import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormControl,
  Validator,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import {errors} from './allowance-errors';
import {FormfieldbaseComponent, setTimeoutPromise} from '../formfieldbase/formfieldbase.component';

@Component({
  selector: 'app-allowance-field',
  templateUrl: './allowance-field.component.html',
  styleUrls: ['./allowance-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AllowanceFieldComponent),
      multi: true
    },
    {
     provide: NG_VALIDATORS,
     useExisting: forwardRef(() => AllowanceFieldComponent),
     multi: true
   }
 ]
})

export class AllowanceFieldComponent extends FormfieldbaseComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

  @Input() label: string;
  @Input() getAllowance: string;

  private minAmount: number;
  private maxAmount: number;
  private showCurrencySymbol = false;

  validate(c: AbstractControl): ValidationErrors | null {
    const controls = this.fieldForm.controls;
      Object.keys(controls).forEach(
        (controlName) => {
          if (controls[controlName].errors !== null) {
            this.required = controls[controlName].errors.hasOwnProperty('required');
            this.minLength = controls[controlName].errors.hasOwnProperty('min');
            this.maxLength = controls[controlName].errors.hasOwnProperty('max');
            this.invalid = controls[controlName].errors.hasOwnProperty('pattern');
          } else {
            this.required = false;
            this.minLength = false;
            this.maxLength = false;
            this.invalid = false;
          }
        });
       let message = '';
       if (this.required) { message = errors.required; }
       if (this.minLength) { message = errors.minLength + this.minAmount.toFixed(2) + ' or greater'; }
       if (this.maxLength) { message = errors.maxLength + this.maxAmount.toFixed(2) + ' or less'; }
       if (this.invalid) { message = errors.invalid + this.maxAmount.toFixed(2); }
       this.errorMessage = message;
    return this.fieldForm.valid ? null : { invalidForm: {valid: false, message: message}};
  }

  constructor(private cdRef: ChangeDetectorRef) {
    super('amountField');
    this.showCurrencySymbol = true;
    this.minAmount = 1.00;
    this.maxAmount = 1000000.00;
    this.fieldForm = new FormGroup(
      {
        amountField: new FormControl('',
        [ Validators.min(this.minAmount),
          Validators.max(this.maxAmount),
          Validators.pattern(/^\d{1,7}(?:[.,]\d{1,2})?$/)
        ]),
      });
   }
  setMinAndMaxAmount() {
    if (parseFloat(this.minValue) > 0 && parseFloat(this.maxValue) > 0) {
      // we have min and max from @Input set
      const minInputAllowance = parseFloat(this.minValue);
      const maxInputAllowance = parseFloat(this.maxValue);
      if ( maxInputAllowance > minInputAllowance ) { // set from default to this new min and max
        this.minAmount = minInputAllowance;
        this.maxAmount = maxInputAllowance;
      } else { // wrong values for min and max set -> use default
        this.minAmount = 1.00;
        this.maxAmount = 1000000.00;
      }
    } else { // wrong values for min and max set -> use default
      this.minAmount = 1.00;
      this.maxAmount = 1000000.00;
    }
  }

  isFloatOrInt(n) {
    return !isNaN(n) && n.toString().match(/^-?\d*(\.\d+)?$/);
  }

  ngOnInit() {
    // set to default
    if (!this.requiredField) {
      this.setMinAndMaxAmount();
      const regAllowance = new RegExp('^\\d{1,' + this.maxAmount.toFixed(0).length + '}(?:[.,]\\d{1,2})?$');
      this.fieldForm.get(this.thisFieldName).setValidators([
        Validators.min(this.minAmount),
        Validators.max(this.maxAmount),
        Validators.pattern(regAllowance)
      ]);
    } else {
      this.setMinAndMaxAmount();
      const regAllowance = new RegExp('^\\d{1,' + this.maxAmount.toFixed(0).length + '}(?:[.,]\\d{1,2})?$');
      this.fieldForm.get(this.thisFieldName).setValidators([
        Validators.required,
        Validators.min(this.minAmount),
        Validators.max(this.maxAmount),
        Validators.pattern(regAllowance)
      ]);
    }
    this.fieldForm.get(this.thisFieldName).valueChanges.subscribe((amch) => {
      // emit only valid amount
      if (parseFloat(amch) > 0 && parseFloat(amch) <= this.maxAmount && this.isFloatOrInt(amch)) {
        const regAllowance = new RegExp('^\\d{1,' + this.maxAmount.toFixed(0).length + '}(?:[.,]\\d{1,2})?$');
        const validAmount = regAllowance.test(amch);
        if (validAmount) {
          this.valueChange.emit(amch);
        } else {
          this.valueChange.emit('');
        }
      } else {
        this.valueChange.emit('');
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.requiredAllowance && changes.requiredAllowance.isFirstChange() && this.requiredField) {
      this.setMinAndMaxAmount();
      const regAllowance = new RegExp('^\\d{1,' + this.maxAmount.toFixed(0).length + '}(?:[.,]\\d{1,2})?$');
      this.fieldForm.get(this.thisFieldName).setValidators([
        Validators.required,
        Validators.min(this.minAmount),
        Validators.max(this.maxAmount),
        Validators.pattern(regAllowance)
      ]);
    }
    if (changes.requiredAllowance && changes.requiredAllowance.isFirstChange() && !this.requiredField) {
      this.setMinAndMaxAmount();
      const regAllowance = new RegExp('^\\d{1,' + this.maxAmount.toFixed(0).length + '}(?:[.,]\\d{1,2})?$');
      this.fieldForm.get(this.thisFieldName).setValidators([
        Validators.min(this.minAmount),
        Validators.max(this.maxAmount),
        Validators.pattern(regAllowance)
      ]);
    }
    if (changes.getAllowance.currentValue && parseFloat(changes.getAllowance.currentValue) > 0) {
      this.setValue(changes.getAllowance.currentValue);
    }
  }

  hideCurrencySymbol() {
    if ( this.fieldForm.get(this.thisFieldName).value !== ''
        && this.fieldForm.get(this.thisFieldName).value !== null
        && this.fieldForm.get(this.thisFieldName).value !== undefined ) {
      this.showCurrencySymbol = true;
    } else {
      this.showCurrencySymbol = false;
    }
  }

  focusInput (event: any): void {
    this.showCurrencySymbol = true;
    this.setValue('');
    this.fieldForm.get(this.thisFieldName).markAsTouched();
    this.fieldForm.get(this.thisFieldName).markAsDirty();
    let total = 0;
    let container = null;
    const _rec = (obj: any) => {
        total += obj.offsetTop;
        const par = obj.offsetParent;
        if (par && par.localName !== 'ion-content') {
            _rec(par);
        } else {
            container = par;
        }
    };
    _rec(event.target);
    container.scrollToPoint(0, total - 50, 400);
  }

}
