import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export enum MessageType {
  SetRightArrowState = 'SetRightArrowState'
}

interface Message<T = any> {
  type: string;
  payload?: T;
}

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private subject = new Subject<Message>();

  /**
   * Sends a message of a specific type with optional payload.
   * @param type - The type of the message.
   * @param payload - Optional data to send with the message.
   */
  sendMessage<T>(type: string, payload?: T): void {
    this.subject.next({ type, payload });
  }

  /**
   * Returns an Observable to listen for messages of a specific type.
   * @param type - The type of the message to listen for.
   */
  onMessage<T>(type: string): Observable<T> {
    return this.subject.asObservable().pipe(
        filter(message => message.type === type),
        map(message => message.payload as T)
    );
  }
}
