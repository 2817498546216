import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppSettings} from '../../../app.settings';
import {BrandsService} from '../../../services/brands/brands.service';

@Component({
  selector: 'app-user-accounts',
  templateUrl: './user-accounts.component.html',
  styleUrls: ['./user-accounts.component.scss'],
})
export class UserAccountsComponent implements OnInit {

  @Input() familyId: string;
  @Input() userId: string;
  @Input() balance: number;
  @Input() selectOnly = false;
  @Output() accountSelectedEvent: EventEmitter<any> = new EventEmitter<any>();

  protected isDwolla = this.brandsService.getUIConfig().bank === 'dwolla';

  constructor(
      private brandsService: BrandsService
  ) { }

  ngOnInit() {}

}
