import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TeamsService} from '../../services/teams/teams.service';
import {UsersService} from '../../services/users/users.service';
import { IonSearchbar } from '@ionic/angular';
import { deepCopy } from '@angular-devkit/core/src/utils/object';

@Component({
  selector: 'app-team-member-selector',
  templateUrl: './team-member-selector.component.html',
  styleUrls: ['./team-member-selector.component.scss'],
})
export class TeamMemberSelectorComponent implements OnInit {

  @Input() teamId: string | null = null;
  @Output() membersSelectedEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchbar', {static: false}) searchbar!: IonSearchbar;
  protected members: any[] = [];
  protected filteredMembers: any[] = [];
  showMembersSpinner = false;

  constructor(
      private teamsService: TeamsService,
      private usersService: UsersService
  ) { }

  ngOnInit(): void {
    if (this.teamId) {
      this.showMembersSpinner = true;
      this.filteredMembers = [];
      this.teamsService.getTeamMembers(this.teamId).then(members => {
        this.members = members.filter((member: any) => (member && member.email && member.userId !== this.usersService.getCurrentUserId()) && member.status === 'active');
        this.filteredMembers = deepCopy(this.members);
        this.showMembersSpinner = false;
      }).catch(err => {
        this.filteredMembers = [];
        this.showMembersSpinner = false;
        console.error('Error in get team members', err);
      });
    }
  }

  private membersUpdated(): void {
    this.filteredMembers = deepCopy(this.members.filter((member: any) => !this.searchbar.value || member.userEmail.indexOf(this.searchbar.value) >= 0));
    this.membersSelectedEvent.emit(this.members.filter((member: any) => member.selected));
  }

  memberCheckboxClicked(filteredMember: any): void {
    const member = this.members.find((thisMember: any): boolean => thisMember.email === filteredMember.email);
    member.selected = !member.selected;
    this.membersUpdated();
  }

  selectAll(): void {
    this.members.forEach((member: any): boolean => member.selected = true);
    this.membersUpdated();
  }

  deselectAll(): void {
    this.members.forEach((member: any): boolean => member.selected = false);
    this.membersUpdated();
  }

  filterItems(): void {
    this.membersUpdated();
  }

}
