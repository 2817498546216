import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor() { }

  encodeJSON(input: any): string {
    return btoa(unescape(encodeURIComponent(JSON.stringify(input))));
  }

  decodeJSON(input: string): any {
    // return JSON.parse(decodeURIComponent(escape(window.atob(input))));
    return JSON.parse(window.atob(decodeURIComponent(input)));
  }

}
