import {Component, Input, OnInit} from '@angular/core';
import {IonItemSliding} from '@ionic/angular';
import {Router} from '@angular/router';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {GoalsService} from '../../services/goals/goals.service';
import {ToastComponent} from '../toast/toast.component';

@Component({
  selector: 'app-goal-item',
  templateUrl: './goal-item.component.html',
  styleUrls: ['./goal-item.component.scss'],
})
export class GoalItemComponent implements OnInit {

  @Input() goal;
  @Input() userId;
  @Input() familyId;
  @Input() balance;

  constructor(
      private router: Router,
      private uiAlertService: UiAlertService,
      private goalsService: GoalsService,
      private toastComponent: ToastComponent
  ) { }

  ngOnInit() {}

  goToGoal(goal) {
    this.router.navigate([ '/GoalDetail', this.familyId, this.userId, goal.goalId ]);
  }

  removeGoal(sliding: IonItemSliding, goal) {
    this.uiAlertService.presentAlertConfirm('Do you really want to remove this goal?').then(confirm => {
      sliding.close();
      if (confirm) {
        this.goalsService.remove(this.userId, goal.goalId).then((response) => {
              this.goalsService.reload();
              this.toastComponent.presentToast('Goal removed');
            }
        ).catch((error) => {
          this.toastComponent.presentError(error);
        });
      }
    });
  }

}
