import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-fieldbase',
  templateUrl: './fieldbase.component.html',
  styleUrls: ['./fieldbase.component.scss']
})
export class FieldbaseComponent implements OnInit {

  @Input() parent: FormGroup;

  constructor() { }

  ngOnInit() {
  }

  showError(fieldName: string, errorMessage: string): string | undefined {
    // TODO: fix for multiple forms on a page
    return (this.parent.get(fieldName).errors && this.parent.get(fieldName).touched) ? errorMessage : null;
  }

}
