import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';

@Injectable({
  providedIn: 'root'
})
export class D3chartsService {

  constructor(private restangular: Restangular) { }

  getEarnedKept(userId: string, familyId: string, date: string): Promise<any> {
    // date: YYYY-MM
    // returns: { earned: 20, spent: 0 }
    return this.restangular.one('families', familyId)
    .one('users', userId).one('reporting')
    .one('chart')
    .one('kidsTransfersOutWallit', date)
    .get().toPromise();
  }

}
