import {Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {BrandsService} from '../../services/brands/brands.service';
import {Api} from '@ionic/pro/dist/src/api';
import {ApiService} from '../../services/api/api.service';

@Component({
  selector: 'app-header-setup',
  templateUrl: './header-setup.component.html',
  styleUrls: ['./header-setup.component.scss']
})
export class OurlyHeaderSetupComponent implements OnInit {

  protected spinnerLevel;

  @Input() helpId = 'signin';

  constructor(
      private location: Location,
      public brandsService: BrandsService,
      public apiService: ApiService
  ) { }

  ngOnInit() {
    this.apiService.spinnerSubscribe(val => this.spinnerLevel = val);
  }

  backClicked() {
    this.location.back();
  }

}
