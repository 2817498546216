import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FamilyService} from '../../services/family/family.service';
import {SessionService} from '../../services/session/session.service';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {ToastComponent} from '../toast/toast.component';
import {UsersService} from '../../services/users/users.service';
import {UtilsService} from '../../services/utils/utils.service';
import {listAnimator} from '../../services/animations/animations.service';
import {IonItemSliding} from '@ionic/angular';
import {LedgerService} from '../../services/ledger/ledger.service';
import {TasksService} from '../../services/tasks/tasks.service';
import {ApiService} from '../../services/api/api.service';
import {ExperimentService} from '../../services/experiments/experiment.service';

@Component({
  selector: 'app-user-my-family',
  templateUrl: './user-my-family.component.html',
  styleUrls: ['./user-my-family.component.scss'],
  // animations: listAnimator
})
export class OurlyUserMyFamilyComponent implements OnInit, OnChanges {

  @Input() family: any = [];
  @Input() noAddButton = false;
  @Input() noAddMember = false;

  private memberBalances = {};
  private familyBalance = 0;
  protected spinnerLevel;
  protected isChild;
  private isOnboardingCompleted;

  constructor(
      private router: Router,
      private familyService: FamilyService,
      private sessionService: SessionService,
      private uiAlertService: UiAlertService,
      private usersService: UsersService,
      private toastComponent: ToastComponent,
      private utilsService: UtilsService,
      private ledgerService: LedgerService,
      private tasksService: TasksService,
      private apiService: ApiService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.isOnboardingCompleted = this.usersService.isOnboardingCompleted();
    this.isChild = this.usersService.isChild();
    this.apiService.spinnerSubscribe(val => this.spinnerLevel = val);
    // sort alphabetically by name
    this.family = this.family.slice().sort((a, b) => {
      const aName = this.utilsService.getUserName(a);
      const bName = this.utilsService.getUserName(b);
      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    });
    const meUser = this.usersService.me().user;
    meUser.userId = meUser.id;
    meUser.isMe = true;
    if (this.usersService.me().family) {
      meUser.familyId = this.usersService.me().family.id;
      this.family.unshift(meUser);
      this.family.forEach(member => {
        this.familyService.getAllowances(member.familyId, member.userId).then(response => {
          // *(response.filter(allowance => allowance.userId === member.userId && allowance.status !== 'approved').length);
          member.allowancePending = response.filter(allowance => allowance.userId === member.userId && allowance.status !== 'approved').length;
        });
        this.ledgerService.getUserBalance(member.familyId, member.userId).then(balance => {
          this.memberBalances[member.userId] = balance.value;
          this.familyBalance += +balance.value;
        });
        function setCount(count) {
          member.noTasks = member.permissionLevel === 1  && count === 0;
        }
        this.tasksService.getPendingTaskCount(member.familyId, member.userId, setCount).then(count => {
          member.taskCount = count;
        });
      });
    }
    this.memberBalances = {};
    this.familyBalance = 0;
  }

  pendingTasks(member) {
    return member.taskCount;
  }

  balance(member) {
    return this.memberBalances[member.userId];
  }

  goToMember(member) {
    if (member.userId === this.usersService.me().user.id) {
      // it is "me"
      this.router.navigate([ '/Profile' ]);
      return;
    }
    if (this.usersService.isParent()) {
      this.router.navigate([member.userType === this.familyService.CHILD ?
          '/ChildDetail' : '/CoParentDetail', member.familyId, member.userId]);
    }
  }

  hasAllowance(member) {
    return member.allowancePending;
    // return true;
  }

  approveAllowance(event, member) {
    event.stopPropagation();
    // this.router.navigate([ '/edit-allowance', this.usersService.me().family.id, member.userId ]);
    this.router.navigate([ '/edit-allowance', member.familyId, member.userId ]);
  }

  assignTask(event, member) {
    event.stopPropagation();
    this.router.navigate([ 'AddATask', this.usersService.me().family.id, member.userId ]);
  }

  getMemberIcon(member) {
    return this.usersService.getAvatarOld(member);
  }

  noMemberTasks(member) {
    return member.noTasks;
  }

  familyClicked() {
    if (this.usersService.isParent()) {
      this.router.navigate(['ProfileFamily']);
    }
  }

  deletefamilyMember(sliding: IonItemSliding, member) {
    this.uiAlertService.presentAlertConfirm('Do you really want to remove this family member?').then(confirm => {
      sliding.close();
      if (confirm) {
        this.familyService.removeMember(member.familyId, member.userId).then((response) => {
          this.toastComponent.presentToast(member.userType === this.familyService.CHILD ? 'Child removed' : 'Coparent removed');
        }
        ).catch((error) => {
          this.toastComponent.presentError(error);
        });
      }
    });
  }

}
