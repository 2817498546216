import { Injectable } from '@angular/core';
import {AlertController} from '@ionic/angular';
import {AnalyticsService} from '../analytics/analytics.service';
import {ClipboardServiceProvider} from '../clipboard/clipboard.service';
import {BrandsService} from '../brands/brands.service';

@Injectable({
  providedIn: 'root'
})
export class UiAlertService {

  constructor(
      private alertController: AlertController,
      private analyticsService: AnalyticsService,
      private clipboardService: ClipboardServiceProvider,
      private brandsService: BrandsService
  ) {
  }

  INVITATION_CANCEL = 0;
  INVITATION_RESEND = 1;
  INVITATION_CANCEL_INVITATION = 2;

  paymentMessage() {
    return this.brandsService.getUIConfig().tabs.accounts ?
    `You must first set up a payment method. If you have already linked your payment method under Accounts, you still need to link it here in order to authorize funds to be withdrawn. Set it up now?` :
    `You must first set up a payment method. Set it up now?`;
  }

  presentAlertLoseChangesConfirm(): Promise<boolean> {
    return this.presentAlertConfirm('Do you really want to lose your changes?').then(value => {
      return value !== 0;
    });
  }

  async presentTransferConfirm(from: string, to: string, amount: number): Promise<number> {
    return this.presentAlertConfirm('Please confirm your transfer of $' + amount + ' from ' + from + ' to ' + to);
  }

  async presentInfo(header: string, message: string, copy = false, contents = ''): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      const buttons = [
        {
          text: 'Ok',
          handler: () => {
            this.analyticsService.send('presentInfo', 'Ok');
            resolve(false);
          }
        }
      ];
      if (copy) {
        buttons.unshift({
          text: 'Copy to Clipboard',
          handler: () => {
            this.analyticsService.send('presentInfo', 'Clipboard');
            this.clipboardService.copy(contents);
            resolve(true);
          }
        });
      }
      this.analyticsService.send('presentInfo', message);
      this.alertController.create({
        header: header,
        message: message,
        buttons: buttons
      }).then(result => result.present());
    });
  }

  async presentAlertConfirm(message, copy = false, contents = ''): Promise<number> {
    return new Promise<number>(resolve => {
      const buttons = [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.analyticsService.send('presentAlert', 'No');
            resolve(0);
          }
        }, {
          text: 'Yes',
          handler: () => {
            this.analyticsService.send('presentAlert', 'Yes');
            resolve(1);
          }
        }
      ];
      if (copy) {
        buttons.unshift({
          text: 'Copy to Clipboard',
          handler: () => {
            this.analyticsService.send('presentInfo', 'Clipboard');
            this.clipboardService.copy(contents);
            resolve(2);
          }
        });
      }
      this.analyticsService.send('presentConfirm', message);
      this.alertController.create({
        header: 'Confirm',
        message: message,
        buttons: buttons
      }).then(result => result.present());
    });
  }

  async presentAlertInvitationPending(magicCode: string): Promise<number> {
    return new Promise<number>(resolve => {
      this.analyticsService.send('presentInvitationPending', '');
      this.alertController.create({
        header: 'Invitation',
        message: magicCode ? 'Magic code is ' + magicCode : 'Choose an action',
        buttons: [
          {
            text: 'Resend Invitation',
            handler: () => {
              this.analyticsService.send('presentAlert', 'Resend');
              resolve(this.INVITATION_RESEND);
            }
          }, {
            text: 'Remove Invitation',
            handler: () => {
              this.analyticsService.send('presentAlert', 'CancelInvitation');
              resolve(this.INVITATION_CANCEL_INVITATION);
            }
          },
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              this.analyticsService.send('presentAlert', 'Cancel');
              resolve(this.INVITATION_CANCEL);
            }
          }
        ]
      }).then(result => result.present());
    });
  }

  async presentAlertCharity(title): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      this.analyticsService.send('presentAlertCharity', title);
      this.alertController.create({
        header: title,
        subHeader: 'Enter amount to donate',
        inputs: [
          {
            name: 'amount',
            label: 'Amount',
            type: 'number',
            value: '1.00',
            placeholder: 'Enter amount'
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              this.analyticsService.send('presentAlertCharity', 'Cancel');
              reject();
            }
          }, {
            text: 'Pay',
            handler: (data) => {
              this.analyticsService.send('presentAlertCharity', 'Pay ' + data.amount);
              resolve(data.amount);
            }
          }
        ]
      }).then(result => result.present());
    });
  }

  async presentAlertPayAllowance(amount: number): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      this.analyticsService.send('presentAlertPayAllowance', amount.toString());
      this.alertController.create({
        header: 'Approve Allowance',
        subHeader: 'Enter amount to pay',
        inputs: [
          {
            type: 'radio',
            label: 'Pay full allowance',
            value: amount
          },
          {
            type: 'radio',
            label: 'Pay partial allowance',
            value: amount / 2
          },
          {
            type: 'radio',
            label: 'Other',
            value: 0
          },
          {
            name: 'amount',
            label: 'Amount',
            type: 'number',
            value: '1.00',
            placeholder: 'Enter amount'
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              this.analyticsService.send('presentAlertPayAllowance', 'Cancel');
              reject();
            }
          }, {
            text: 'Pay',
            handler: (data) => {
              this.analyticsService.send('presentAlertPayAllowance', data.amount.toString());
              resolve(data.amount);
            }
          }
        ]
      }).then(result => result.present());
    });
  }

  async presentAlertEnterPIN(phone: string, pin?: any): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.analyticsService.send('presentAlertEnterPIN', '');
      this.alertController.create({
        header: 'Enter PIN Code',
        subHeader: `Sent to ${phone} ${pin?.pin ? pin.pin : ''}`,
        inputs: [
          {
            name: 'pin',
            label: 'PIN',
            type: 'text',
            value: '',
            placeholder: 'Enter PIN'
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              this.analyticsService.send('presentAlertEnterPIN', 'Cancel');
              reject();
            }
          }, {
            text: 'Verify Phone Number',
            handler: (data) => {
              this.analyticsService.send('presentAlertEnterPIN', data.pin);
              resolve(data.pin);
            }
          }
        ]
      }).then(result => result.present());
    });
  }
}

