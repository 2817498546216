import { Platform, AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class BrowserDetectService {

  constructor(private platform: Platform, public alertCtrl: AlertController) {

  }

  detectBrowser(showMessage = true): boolean {
      let sBrowser: string;
      const sUsrAg = navigator.userAgent;

      // The order matters here, and this may report false positives for unlisted browsers.

      if (sUsrAg.indexOf('Firefox') > -1) {
        sBrowser = 'Mozilla Firefox';
      } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
        sBrowser = 'Opera';
      } else if (sUsrAg.indexOf('Trident') > -1) {
        sBrowser = 'Microsoft Internet Explorer';
      } else if (sUsrAg.indexOf('Edge') > -1) {
        sBrowser = 'Microsoft Edge';
      } else if (sUsrAg.indexOf('Chrome') > -1) {
        sBrowser = 'Google Chrome or Chromium';
      } else if (sUsrAg.indexOf('Safari') > -1) {
        sBrowser = 'Apple Safari';
      } else {
        sBrowser = 'unknown';
      }

      // *('You are using: ' + sBrowser);

      if (this.blockBrowser(sBrowser, showMessage)) {
        return false; // means false browser
      }
    return true; // browser is ok
  }

  blockBrowser(sBrowser: string, showMessage: boolean): boolean {
    // here we can add more browser to block
    if (sBrowser === 'Microsoft Edge' || sBrowser === 'Microsoft Internet Explorer') {
      // right now we only show alert message for unsuported browser
      if (showMessage) { this.showAlert(); }
      return true; // block this browser
    }
    return false; // do not block this browser
  }

  async showAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Warning!',
      subHeader: 'Sorry, this browser is not supported. Please use Safari, Chrome, or Firefox.',
      buttons: ['OK']
    });
    return await alert.present();
  }

}
