import {Component, Input, OnChanges, OnInit} from '@angular/core';
import { TasksService } from '../../services/tasks/tasks.service';
import {AccountsService} from '../../services/accounts/accounts.service';
import {Router} from '@angular/router';
import {FamilyService} from '../../services/family/family.service';
import {SessionService} from '../../services/session/session.service';
import {IonItemSliding, AlertController, LoadingController} from '@ionic/angular';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {ToastComponent} from '../toast/toast.component';
import {Location} from '@angular/common';
import {listAnimator} from '../../services/animations/animations.service';
import {UsersService} from '../../services/users/users.service';
import {ApiService} from '../../services/api/api.service';

@Component({
  selector: 'app-user-my-tasks',
  templateUrl: './user-my-tasks.component.html',
  styleUrls: ['./user-my-tasks.component.scss'],
  // animations: listAnimator
})
export class UserMyTasksComponent implements OnInit, OnChanges {

  @Input() userId = '';
  @Input() familyId = '';
  @Input() isChild = false;

  protected isParent;
  protected pendingTaskCount: number;
  protected spinnerLevel;

  tasks: Array<any> = [];

  constructor(
      private taskService: TasksService,
      private router: Router,
      private familyService: FamilyService,
      private sessionService: SessionService,
      private uiAlertService: UiAlertService,
      private toastComponent: ToastComponent,
      private location: Location,
      private tasksService: TasksService,
      private usersService: UsersService,
      private apiService: ApiService,
      public alertCtrl: AlertController,
      public loadingController: LoadingController
  ) { }

  ngOnInit() {
    this.apiService.spinnerSubscribe(val => this.spinnerLevel = val);
    this.isParent = this.usersService.isParent();
    function order(a, b) {
      if (a.taskName < b.taskName) {
        return -1;
      }
      if (a.taskName > b.taskName) {
        return 1;
      }
      return 0;
    }
    function reload(_this, result) {
      if (result) {
        _this.tasks = result.sort((a, b) => order(a, b));
        _this.tasksService.getPendingTaskCount(_this.familyId, _this.userId).then(count => {
          _this.pendingTaskCount = count;
        });
      }
    }
    if (this.isChild) {
      this.tasksService.memberTasksSubscribe(this.familyId, this.userId,
          result =>  { reload(this, result); });
    } else {
      this.tasksService.myTasksSubscribe(this.familyId, this.userId,
          result =>  { reload(this, result); } );
    }
  }

  ngOnChanges() {
  }

  async requestMoreWork() {
    const loading = await this.loadingController.create({
      message: 'Please wait...',
    });
    await loading.present();
    this.familyService.requestMe().then((response) => {
      const data = {
        familyId: this.familyId,
        userId: response.family.ownerId
      };
      const bodyReq = {
        notificationType: 'kidsRequestMoreWorkParent',
        childId: this.userId,
        familyId: this.familyId,
        familyAlias: response.user.familyAlias
      };
      this.familyService.requestMoreWork(data, bodyReq).then((res) => {
          loading.dismiss();
          this.showRequestMoreWorkConfirmMessage();
      }).catch( error => {
        console.log('Error send request more tasks: ', error);
        loading.dismiss();
        this.errorLoading();
      });
    }).catch( error => {
      console.log('Error get request me data: ', error);
      loading.dismiss();
      this.errorLoading();
    });
  }

  async errorLoading() {
    const loadingErr = await this.loadingController.create({
      message: 'Error occured ... Please try again',
      duration: 2000
    });
    await loadingErr.present();

    const { role, data } = await loadingErr.onDidDismiss();
  }

  async showRequestMoreWorkConfirmMessage() {
    const alert = await this.alertCtrl.create({
      header: 'Success!',
      subHeader: 'We\'ve let your parent know that you would like more tasks assigned. We\'ll remind them!',
      buttons: ['Got it!']
    });
    return await alert.present();
  }
  taskFrequency(task: any): string {
    return task.occurrence ? task.occurrence : 'Once';
  }

  goToAllTasks() {
    this.router.navigate([ '/Tasks', this.familyId, this.userId ]);
  }

  goToChildTask(task) {
    if (this.usersService.isParent()) {
      this.router.navigate(['AssignTask', this.familyId, this.userId, task.taskId], {queryParams: {edit: true}});
    } else {
      this.router.navigate([ '/Tasks', this.familyId, this.userId ]);
    }
  }

  removeTask(sliding: IonItemSliding, task) {
    this.uiAlertService.presentAlertConfirm('Do you really want to remove this task?').then(confirm => {
      sliding.close();
      if (confirm) {
        this.tasksService.remove(this.familyId, this.userId, task.taskId).then((response) => {
          this.tasksService.reload();
          this.toastComponent.presentToast('Task removed');
            }
        ).catch((error) => {
          this.toastComponent.presentError(error);
        });
      }
    });
  }

}
