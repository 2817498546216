import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TransferService} from '../../services/transfer/transfer.service';
import {UsersService} from '../../services/users/users.service';
import {IonItemSliding} from '@ionic/angular';
import {UiAlertService} from '../../services/ui-alert/ui-alert.service';
import {ToastComponent} from '../toast/toast.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-p2pactivity',
  templateUrl: './p2pactivity.component.html',
  styleUrls: ['./p2pactivity.component.scss'],
})
export class P2pactivityComponent implements OnInit {

  @Input() request;
  @Input() userNames = {};
  @Input() userAvatars = {};

  constructor(
      private transferService: TransferService,
      private usersService: UsersService,
      private uiAlertService: UiAlertService,
      private toastComponent: ToastComponent,
      private router: Router
  ) { }

  ngOnInit() {
  }

  payRequest(sliding: IonItemSliding, request) {
    sliding.close();
    this.router.navigate(['/sendmoney', request.requesterUserId, request.requestId, request.amountRequested]);
  }

  removeRequest(sliding: IonItemSliding, request) {
    this.uiAlertService.presentAlertConfirm('Do you really want to remove this request for payment?').then(confirm => {
      sliding.close();
      if (confirm) {
        this.transferService.cancelP2Prequest(this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId(), request.requestId).then(_ => {
          this.toastComponent.presentToast('Request successfully canceled');
        });
      }
    });
  }

  isOutbound(request) {
    return request.requesterUserId === this.usersService.getCurrentUserId();
  }

  getTargetName(request) {
    if (this.isOutbound(request)) {
      return 'You paid ' + (this.userNames[request.requestId] || '');
    } else {
      return (this.userNames[request.requestId] || '') + ' paid you';
    }
  }

  getMemberIcon(member) {
    return this.usersService.getAvatarOld(member);
  }

  getStatus(request) {
    switch (request.transactionStatus) {
      case 'received':
        return this.isOutbound(request) ? 'Request Sent' : 'Request Received';
      case 'canceled':
        return 'Request Canceled';
      default:
        return request.transactionStatus || '';
    }
  }

  allowCancelAction(request) {
    return request.transactionStatus === 'received' && this.isOutbound(request);
  }

  allowPayAction(request) {
    return request.transactionStatus === 'received' && !this.isOutbound(request);
  }

}
