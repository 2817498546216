import {Component, Input, OnInit} from '@angular/core';
import {TeamsService} from '../../services/teams/teams.service';
import {UsersService} from '../../services/users/users.service';
import {Router} from '@angular/router';
import {RebatesService} from '../../services/rebates/rebates.service';

@Component({
  selector: 'app-rebates-categories',
  templateUrl: './rebates-categories.component.html',
  styleUrls: ['./rebates-categories.component.scss'],
})
export class RebatesCategoriesComponent implements OnInit {

  @Input() title;

  protected categories = [];

  constructor(
      private teamsService: TeamsService,
      private usersService: UsersService,
      private rebatesService: RebatesService,
      private router: Router
  ) { }

  ngOnInit() {
    this.usersService.meSubscribe(me => {
      if (me) {
        this.teamsService.getUserMemberTeams(this.usersService.getCurrentUserId()).then(teams => {
          const allCategories = new Set();
          teams.forEach(team => {
            team.categories.forEach(category => {
              allCategories.add(category);
            });
          });
          this.teamsService.getCategories().then(categories => {
            this.categories = categories.filter(category => Array.from(allCategories).find(catName => catName === category.name));
          });
        });
      }
    });
  }

  showAllClicked() {
    this.router.navigate(['/rebatecategories', '0', '0', '0']);
  }

  categoryClicked(category) {
      this.router.navigate(['/rebatelist', '=' + category.name, '0', '0', '0']);
  }


}
