import { Component, OnInit } from '@angular/core';
import {ToastController} from '@ionic/angular';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  private toast;

  constructor(
      private toastController: ToastController,
  ) { }

  ngOnInit() {
  }

  clearToast() {
    if (this.toast) {
      this.toast.dismiss();
      this.toast = null;
    }
  }

  async presentToast(message: string) {
    if (this.toast) {
      this.toast.dismiss();
    }
    this.toast = await this.toastController.create({
      message: message,
      duration: 300000,
      cssClass: 'toastClass',
      buttons: [
        {
          text: 'X',
          handler: () => {
            this.toast.dismiss();
          }
        }
      ]
    });
    this.toast.onDidDismiss().then(_ => {
    });
    this.toast.present().then(() => {
      const toastElement = document.querySelector('ion-toast');
      const shadowRoot = toastElement.shadowRoot;

      const toastContent = shadowRoot.querySelector('.toast-content');
      if (toastContent) {
        toastContent.setAttribute('style', 'width: 80%');
      }
    });
  }

  presentError(error): boolean {
    if (error && error.data && error.data.errors) {
      this.presentToast(error.data.errors);
      return true;
    }
    if (error && error.error) {
      this.presentToast(error.error.error || error.error.errors || error.error.display_message || error.error.error_message || error.error);
      return true;
    }
    console.log('unknown error', error);
    return false;
  }

}
