import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {VendorsService} from '../../services/vendors/vendors.service';
import {CryptoService} from '../../services/crypto/crypto.service';
import {Router} from '@angular/router';
import {ToastComponent} from '../toast/toast.component';
import {Location} from '@angular/common';
import {UsersInsuranceService} from '../../services/usersinsurance/users.insurance.service';
import {UsersService} from '../../services/users/users.service';

@Component({
  selector: 'app-groupproducts',
  templateUrl: './groupproducts.component.html',
  styleUrls: ['./groupproducts.component.scss'],
})

export class GroupproductsComponent implements OnInit {

  @Input() params;

  protected products;
  protected compareCount = 0;
  protected comparePlans = [];
  protected compareAttributes = [];
  protected compareMode = false;
  private options = {};

  expandedSections: { [key: number]: boolean } = {};

  constructor(
      private domSanitizer: DomSanitizer,
      private vendorsService: VendorsService,
      private cryptoService: CryptoService,
      private router: Router,
      private toastComponent: ToastComponent,
      private location: Location,
      private usersInsuranceService: UsersInsuranceService,
      private usersService: UsersService
  ) { }

  ngOnInit() {
    this.vendorsService.getAllVendorGroupProductsForUser().then(result => {
      result.forEach(product => {
        this.vendorsService.getProductChoices(product.id).then(choices => {
          product.choices = choices;
          product.metadata.logo = this.domSanitizer.bypassSecurityTrustUrl(product.metadata.logo);
          choices.forEach(choice => {
            this.options[choice.name] = choice.selectoptions[0].values;
          });
          this.updateValues(product);
          this.buildComparisonGrid(product);
        }).catch(error => {
          console.log('ERRROR*******', error.error)
          this.toastComponent.presentToast(error.error);
        });
      });
      this.products = result;
    });
  }

  private buildComparisonGrid(product) {
    let options;
    if (product.choices?.length > 0) {
      options = product.choices[0].selectoptions;
    }
    if (options) {
      product.comparison = {
        // TODO: THIS ONLY WORKS FOR A SINGLE CHOICE DROPDOWN
        label: product.choices[0].label,
        colHeaders: ['Comparison Benefit Highlights', ...options.map(option => option.label)],
        rows: product.choices[0].comparison.rows.map(row => [row.label, ...product.choices[0].selectoptions.map(option => row.field ? option.values.EMPL[row.field] : '_')])
      };
    }
  }

  allCellsEqual(cells: any[]): boolean {
    return cells.length > 0 && cells.every(item => item === cells[0]);
  }

  private evaluateWithContext(code, context) {
    try {
      return Function(...Object.keys(context), `return ${code};`)(...Object.values(context));
    } catch (error) {
      return `Eval Error: ${error}`;
    }
  }

  private getCurrentOptions(plan) {
    const optionsForTier = {};
    Object.entries(this.options).forEach(([key, value]) => {
      Object.entries(value).forEach(([key2, value2]) => {
        if (key2 === plan.tier) {
          optionsForTier[key] = value[key2];
        }
      });
    });
    return optionsForTier;
  }

  private parseFunctionString(input) {
    const regex = /(.*)__([A-Z,a-z,0-9]+)\((.*)\)(.*)$/;
    const match = input.match(regex);
    if (match) {
      const prefix = match[1];
      const functionName = match[2]; // Extracts FUNCTIONNAME
      const args = match[3];        // Extracts ARGS
      const postfix = match[4];
      return { prefix, postfix, functionName, args };
    } else {
      return null;
    }
  }

  private updateValues(product) {
    product.plans.forEach(plan => {
      plan.product = product;
      plan.attributes.forEach(attribute => {
        const func = this.parseFunctionString(attribute.savedcontents || attribute.contents);
        if (func) {
          switch (func.functionName) {
            case 'lookup':
              if (!attribute.savedcontents) {
                attribute.savedcontents = attribute.contents;
              }
              attribute.contents = func.prefix + this.evaluateWithContext(func.args, {option: this.getCurrentOptions(plan)});
              break;
          }
        }
      });
      plan.attributes = plan.attributes.slice();
      plan.show = true;
    });
    let matchedProduct = this.products.find(thisProduct => thisProduct.id === product.id);
    if (matchedProduct) {
      matchedProduct = product;
    }
  }

  getTier(plan) {
    const tierNames = {
      EMPL: 'Single Member',
      SPSE: 'Member plus Spouse',
      DPDT: 'Member plus Family',
      SPF: 'Single Parent Family'
    };
    return tierNames[plan.tier];
  }

  sanitizeHtml(html) {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  showDocument(url) {
    this.router.navigate(['embeddedpage', this.cryptoService.encodeJSON({url})]);
  }

  compareBoxClicked(event, productPlan) {
    if (event.detail.checked) {
      this.compareCount++;
      productPlan.compareSelected = true;
    } else {
      this.compareCount--;
      productPlan.compareSelected = false;
    }
  }

  private async dependentsMatchPlan(plan, product) {
    const dependents = await this.usersInsuranceService.getInsuranceDependents(this.usersService.getCurrentUserId(), product.vendorProductId);
    const hasChildren = dependents.some(dependent => ['c', 'dependent'].includes(dependent.relationship.toLowerCase()));
    const hasSpouse = dependents.some(dependent => ['s', 'partner'].includes(dependent.relationship.toLowerCase()));
    const notify = (result, extraInfo) => {
      if (!result) {
        this.toastComponent.presentToast(`You have selected a plan tier that requires additional information about your dependents: ${extraInfo}`);
      }
    };
    switch (plan.tier) {
      case 'EMPL':
        return true;
      case 'SPSE':
        notify(hasSpouse, 'Spouse');
        return hasSpouse;
      case 'DPDT':
        notify(hasSpouse && hasChildren, 'Spouse and Children');
        return hasSpouse && hasChildren;
      case 'SPF':
        notify(hasChildren, 'Children');
        return hasChildren;
    }
  }

  async select(plan, product) {
    if (!await this.dependentsMatchPlan(plan, product)) {
      this.location.back();
      return;
    }
    this.vendorsService.getVendorProduct(product.vendorProductId).then(vendorProduct => {
      this.vendorsService.getVendorGroupPlan(plan.id).then(planDetails => {
        this.router.navigate([planDetails.questions && planDetails.questions.length > 0 ? 'group-product-questions' : 'group-product-enroll', this.cryptoService.encodeJSON({
          action: this.params,
          tier: plan.tier,
          detailsUrl: product.metadata.detailsUrl,
          planId: plan.id,
          options: this.getCurrentOptions(plan),
          planName: `${vendorProduct.vendorName} ${product.metadata.name} ${this.getTier(plan)}`,
          amount: parseFloat(plan.attributes[0].contents.replace(/[\$,]/g, '')),
          vendorGroupProductId: plan.vendorGroupProductId,
          form: {}
        })]);
      });
    });
  }

  compare(thisProduct) {
    if (thisProduct.comparison) {
      thisProduct.showComparison = !thisProduct.showComparison;
      return;
    }
    this.compareMode = !this.compareMode;
    this.comparePlans = [];
    this.compareAttributes = [];
    this.products.forEach(product => {
      product.plans.forEach(plan => {
        if (plan.compareSelected) {
          this.comparePlans.push(plan);
          plan.compare.forEach(comparison => {
            if (!this.compareAttributes.find(attribute => attribute === comparison.name)) {
              this.compareAttributes.push(comparison.name);
            }
          });
        }
      });
    });
    if (this.compareMode && this.compareAttributes.length === 0) {
      this.compareMode = false;
      this.toastComponent.presentToast('No attributes available to compare');
    }
  }

  expandTemplate(product, template: string): string {
    const func = this.parseFunctionString(template);
    if (func) {
      switch (func.functionName) {
        case 'lookup':
          return this.expandTemplate(product, func.prefix + this.evaluateWithContext(func.args, {option: this.getCurrentOptions(product.plans[0])}) + func.postfix);
      }
    }
    return template;
  }

  getComparisonValue(plan, compareAttribute) {
    const compareItem = plan.compare.find(comparison => comparison.name === compareAttribute);
    if (compareItem) {
      const func = this.parseFunctionString(compareItem.value);
      if (func) {
        switch (func.functionName) {
          case 'lookup':
            return func.prefix + this.evaluateWithContext(func.args, {option: this.getCurrentOptions(plan)}) + func.postfix;
        }
      }
    }
    return '';
  }

  optionChange(event, product, name) {
    this.options[name] = product.choices.find(product => product.name === name)?.selectoptions.find(option => option.label === event.detail.value)?.values;
    this.updateValues(product);
  }

  toggleSection(index: number): void {
    this.expandedSections[index] = !this.expandedSections[index];
  }

  isRowVisible(product, index: number): boolean {
    // Find the closest preceding section header
    let sectionIndex = -1;
    for (let i = index; i >= 0; i--) {
      if (product.comparison.rows[i][1] === '_') {
        sectionIndex = i;
        break;
      }
    }
    // If no section header is found, assume the row is visible
    if (sectionIndex === -1) {
      return true;
    }
    // Check if the section is expanded
    return this.expandedSections[sectionIndex];
  }

}
