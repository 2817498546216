import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-smart-balance-card',
  templateUrl: './smart-balance-card.component.html',
  styleUrls: ['./smart-balance-card.component.scss'],
})
export class SmartBalanceCardComponent implements OnInit {

  @Input() info;
  @Output() gearClickedEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() cardClickedEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(
      private router: Router
  ) { }

  ngOnInit() {
  }

  gearClicked(event) {
    event.preventDefault();
    // this.router.navigate(['smart-balance-settings',  this.info.bankId, this.info.plaidAccountId ]);
    this.gearClickedEvent.emit(this.info);
  }

  cardClicked() {
    this.cardClickedEvent.emit(this.info);
    // this.router.navigate(['/BankDetail', this.usersService.getCurrentFamilyId(), this.usersService.getCurrentUserId(), this.info.plaidAccountId, this.info.bankId]);
  }

  smartBalanceDetails() {
    this.router.navigate(['smart-balance-settings', this.info.bankId, this.info.account_id ]);
  }
}
