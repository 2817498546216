import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'last4'
})
export class Last4Pipe implements PipeTransform {

  transform(value: string, args?: any): string {
    return value.replace(/\d(?=\d{4})/g, '*');
  }

}
