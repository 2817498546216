import {Component, OnInit} from '@angular/core';
import {BrandsService} from '../../services/brands/brands.service';
import {ApiService} from '../../services/api/api.service';
import {BaseComponent} from '../base/base.component';

@Component({
  selector: 'app-header-team',
  templateUrl: './header-team.component.html',
  styleUrls: ['./header-team.component.scss'],
})
export class HeaderTeamComponent extends BaseComponent implements OnInit {

  constructor(
      protected brandsService: BrandsService,
      apiService: ApiService,
  ) {
    super(apiService);
  }

  ngOnInit() {
  }

}
