import {Component, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform} from '@angular/core';
import {AccountsService} from '../../../../services/accounts/accounts.service';
import {Router} from '@angular/router';
import {SessionService} from '../../../../services/session/session.service';
import {IonItemSliding} from '@ionic/angular';
import {ToastComponent} from '../../../toast/toast.component';
import {UiAlertService} from '../../../../services/ui-alert/ui-alert.service';
import {UsersService} from '../../../../services/users/users.service';
import {LedgerService} from '../../../../services/ledger/ledger.service';
import {ApiService} from '../../../../services/api/api.service';
import {ContentService} from '../../../../services/content/content-service.service';
import {WallitService} from '../../../../services/wallit/wallit.service';
import {BrandsService} from '../../../../services/brands/brands.service';

@Component({
  selector: 'app-user-accounts-dwolla',
  templateUrl: './user-accounts-dwolla.component.html',
  styleUrls: ['./user-accounts-dwolla.component.scss'],
  // animations: listAnimator
})
export class UserAccountsDwollaComponent implements OnInit {

  protected accountsBalance;
  protected totalBalance;
  protected banks = [];
  private sharedAccountInfo;
  protected wallit;
  private useChildSources = false;
  private account;
  protected reauthRequired;
  protected spinnerLevel;
  private showNoAccountsMessage = false;
  private noAccountsTextMessage = 'Linking a bank account is quick, easy, and a great way to get reimbursed for lifestyle benefits and work-related expenses.'; // this.contentService.getMessage('connectAnAccount');
  private showButtonLinkAnAccount;
  protected ACH_ERRORS_ENABLED = this.brandsService.getUIConfig().achTools;
  protected showAchErrorCodes = false;

  @Input() credit: boolean;
  @Input() familyId: string;
  @Input() userId: string;
  @Input() balance: number;
  @Input() selectOnly = false;
  @Output() accountSelectedEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
      private accountService: AccountsService,
      private router: Router,
      private apiService: ApiService,
      private sessionService: SessionService,
      private toastComponent: ToastComponent,
      private uiAlertService: UiAlertService,
      private accountsService: AccountsService,
      private usersService: UsersService,
      private ledgerService: LedgerService,
      private contentService: ContentService,
      private wallitService: WallitService,
      private brandsService: BrandsService
  ) { }

  ngOnInit() {
    this.wallitService.monitorWallitChanged(wallit => {
      this.wallit = JSON.parse(JSON.stringify(wallit));
      this.banks = JSON.parse(JSON.stringify(this.wallit.accounts))
          .filter(account => this.credit ?
              (account.metadata && account.metadata.account && account.metadata.account.type === 'credit') :
              (account.fundingSourceType === 'balance' || (account.metadata.account && account.metadata.account.type !== 'credit')));
      const wallitBankBalance = (this.banks.filter((account: any) => account.fundingSourceType === 'balance'))[0];
      if (wallitBankBalance) {
        const wallitBankIndex: number = this.banks.indexOf(wallitBankBalance);
        this.banks[wallitBankIndex].balances.current = this.wallit.wallitBalance;
      }
      this.totalBalance = 0;
      this.banks.forEach(bank => {
        this.totalBalance += parseFloat(this.lookupBalance(bank));
      });
    });
    // this.showButtonLinkAnAccount = this.credit;
    if (!this.credit) {
      // this.showButtonLinkAnAccount = !this.wallit.accounts.find(account => account.account && account.account.type === 'credit');
    }
    this.apiService.spinnerSubscribe(val => this.spinnerLevel = val);
    // TODO: Brittle code checking for Dashboard in URL. Switch to using @Input parameter
    const currUrl = this.router.url;
    if (currUrl.includes('Dashboard')) {
      this.showNoAccountsMessage = true;
    }
    if (currUrl.includes('ChildDetail')) {
      this.noAccountsTextMessage = 'This child does not have any linked accounts';
      this.showButtonLinkAnAccount = false;
    }
  }

  isNobalanceAccount(account): boolean {
    return account.fundingSourceType === 'nobalance' || !account.balanceCache;
  }

  isBalanceAccount(account): boolean {
    return account.fundingSourceType === 'balance';
  }

  needsVerify(account) {
    return account.fundingSourceType === 'needsverification';
  }

  pendingVerification(account): boolean {
    return account.fundingSourceType === 'pendingverification';
  }

  needsReauth(account) {
    return account.fundingSourceType === 'needsreauth';
  }

  noTransfers(account) {
    return account.counterParty?.isRemovedFundingSource;
  }

  lookupIcon(account) {
    return  this.isBalanceAccount(account) || !account.metadata.institution  || !account.metadata.institution.logo ? '/assets/logo/Wallit - Brandmark.svg' : account.metadata.institution.logo;
  }

  lookupName(account) {
      if (this.isBalanceAccount(account)) {
          return 'Wallit Balance';
      }
      return `${account.metadata.account.name} - ${account.metadata.account.mask}`;
  }

  getPending(account) {
    return account.fundingSourceType === 'balance' ? this.wallit.pendingInboundAmount : 0;
  }

  lookupBalance(account) {
    if (this.isBalanceAccount(account)) {
      return account.balances.current;
    }
    if (account.balanceCache) {
      const balances = account.balanceCache.accounts[0];
      return balances.type === 'credit' || balances.balances.current ? balances.balances.current : balances.balances.available;
    }
    return undefined;
  }

  gotBalance(account) {
    return this.isBalanceAccount(account);
  }

  private updateBankBalances() {
  }

  goToAccount(account) {
    if (this.useChildSources) {
      if (!this.isBalanceAccount(account)) {
        const fundingSourceId = this.sharedAccountInfo.sharedFundingSources.
         find(sharedAccount => sharedAccount.plaidAccountId === account.account_id).dwollaFundingSourceId;
        this.router.navigate([ '/BankDetail', this.familyId,
          this.usersService.me().user.id, account.account_id, fundingSourceId ]);
      } else {
        this.router.navigate([ '/child-transfer' ] );
      }
    } else {
      if (this.needsVerify(account) || this.pendingVerification(account)) {
        return;
      }
      if (this.selectOnly) {
        this.accountSelectedEvent.emit(account);
        return;
      }
      if (this.isBalanceAccount(account)) {
        this.router.navigate(['/tabs/tab-activity']);
        return;
      }
      if (this.isNobalanceAccount(account) || !account.counterParty) {
          this.router.navigate(['/BankDetail', this.familyId, this.userId, account.metadata.account_id]);
        return;
      }
      this.router.navigate(['/BankDetail', this.familyId, this.userId, account.counterParty.plaidAccountId, account.counterParty.fundingSourceId ? account.counterParty.fundingSourceId : 'credit']);
    }

  }

  verifyClicked(event, account) {
    event.stopPropagation();
    this.router.navigate(['/Addbank', this.familyId, this.userId], { queryParams: {publicToken: account.publicToken, microDeposits: true}});
  }

  reauthClicked(event, account) {
    event.stopPropagation();
    this.router.navigate(['/Addbank', this.familyId, this.userId], { queryParams: {publicToken: account.publicToken}});
  }

  linkAnAccountClicked() {
    this.router.navigate(['/bank-connection']);
  }

  removeAccount(sliding: IonItemSliding, account) {
    this.uiAlertService.presentAlertConfirm('Do you really want to remove this account?').then(confirm => {
      sliding.close();
      if (confirm) {
        const promise = !account.counterParty ? this.accountsService.removePlaidAccount(this.familyId, this.userId, account.accessToken) :
         this.accountsService.removeAccount(this.familyId, this.userId, account.counterParty.fundingSourceId);
        promise.then((response) => {
              this.toastComponent.presentToast('Account removed');
              this.wallitService.getWallit();
            }
        ).catch((error) => {
          this.toastComponent.presentError(error);
        });
      }
    });
  }

  setACHFailCode(sliding: IonItemSliding, account) {
    sliding.close();
    this.account = account;
    this.showAchErrorCodes = true;
  }

  setAchCodeDone(event) {
    this.showAchErrorCodes = null;
    this.accountsService.setAchTestMode(this.userId, this.account.metadata.account_id, event).then(() => {
      this.toastComponent.presentToast('ACH error mode set');
    });
  }

}

@Pipe({
  name: 'getBalance',
  pure: true
})
export class GetBalancePipe implements PipeTransform {

  transform(item: any, thisArg: UserAccountsDwollaComponent): string {
    return thisArg.lookupBalance(item);
  }

}
