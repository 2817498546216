import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {LinksService} from '../../services/links/links.service';
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {

  @Input() href: any;

  constructor(
      private modalCtrl: ModalController,
      private router: Router
  ) { }

  ngOnInit() {
  }

  backClicked() {
    this.modalCtrl.dismiss(true);
  }

}
