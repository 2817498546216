import {Component, ContentChild, ElementRef, OnInit} from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit {

  @ContentChild('footer', {static: false}) footer: ElementRef;

  constructor() { }

  ngOnInit() {}

}
