import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HelpComponent} from '../../help/help.component';

@Component({
  selector: 'app-windowopen',
  templateUrl: './windowopen.component.html',
  styleUrls: ['./windowopen.component.scss'],
})
export class WindowOpenComponent implements OnInit {

  @Input() url;
  @Output() doneEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  cancelClicked() {
    this.doneEvent.emit();
  }

  continueClicked() {
    window.open(this.url);
    this.doneEvent.emit();
  }

}
