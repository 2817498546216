import { Component, OnInit, SimpleChanges, OnChanges, ChangeDetectorRef, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormControl,
  Validator,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import {errors} from './city-errors';
import {FormfieldbaseComponent} from '../formfieldbase/formfieldbase.component';

@Component({
  selector: 'app-city-field',
  templateUrl: './city-field.component.html',
  styleUrls: ['../formfieldbase/formfieldbase.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CityFieldComponent),
      multi: true
    },
    {
     provide: NG_VALIDATORS,
     useExisting: forwardRef(() => CityFieldComponent),
     multi: true
   }
 ]
})

export class CityFieldComponent extends FormfieldbaseComponent implements OnChanges, ControlValueAccessor, Validator   {

  constructor(private cdRef: ChangeDetectorRef) {
    super('city');
    this.fieldForm = new FormGroup(
      {
        city: new FormControl('',
        [ Validators.minLength(3),
          Validators.maxLength(70),
          Validators.pattern('[a-zA-Z0-9 ]*')
        ]),
      });
      this.fieldForm.get(this.thisFieldName).setValue('');
   }

  ngOnChanges(changes: SimpleChanges) {
    if (this.minValue  && changes.hasOwnProperty('minValue') && changes.minValue.isFirstChange() ) {
      if (parseInt(this.minValue, 10) > 0) { // value must be greater than zero always
        this.minLen = parseInt(this.minValue, 10); // override with custom min
      } else {
        this.minLen = 3;
      }
      if (this.maxLen > this.minLen && this.requiredField) {
        this.fieldForm.get(this.thisFieldName).setValidators([
          Validators.required,
          Validators.minLength(this.minLen), // override constructor
          Validators.maxLength(this.maxLen),
          Validators.pattern('[a-zA-Z0-9 ]*')
        ]);
      } else if (this.maxLen > this.minLen && !this.requiredField) {
        this.fieldForm.get(this.thisFieldName).setValidators([
          Validators.minLength(this.minLen), // override constructor
          Validators.maxLength(this.maxLen),
          Validators.pattern('[a-zA-Z0-9 ]*')
        ]);
      }
    }
    if (this.maxValue  && changes.hasOwnProperty('maxValue') && changes.maxValue.isFirstChange() ) {
      if (parseInt(this.maxValue, 10) > 0) { // value must be greater than zero always
        this.maxLen = parseInt(this.maxValue, 10); // override with custom max
      } else {
        this.maxLen = 70;
      }
      if (parseInt(this.maxValue, 10) > this.minLen && this.requiredField) {
        this.fieldForm.get(this.thisFieldName).setValidators([
          Validators.required,
          Validators.minLength(this.minLen),
          Validators.maxLength(this.maxLen), // override constructor
          Validators.pattern('[a-zA-Z0-9 ]*')
        ]);
      } else if (parseInt(this.maxValue, 10) > this.minLen && !this.requiredField) {
        this.fieldForm.get(this.thisFieldName).setValidators([
          Validators.minLength(this.minLen),
          Validators.maxLength(this.maxLen), // override constructor
          Validators.pattern('[a-zA-Z0-9 ]*')
        ]);
      }
    }
    if (this.getVal && changes.getVal.firstChange !== null && changes.getVal.firstChange !== undefined && this.getVal !== '' ) {
      this.fieldForm.get(this.thisFieldName).setValue(this.getVal);
    } else if (changes.getVal.isFirstChange() && (this.getVal === '' || !this.getVal) ) {
      this.setValue('');
    }
    if (this.getVal && this.getVal !== '') {
      if (parseFloat(changes.getVal.previousValue) !== parseFloat(changes.getVal.currentValue)) {
        this.fieldForm.get(this.thisFieldName).setValue(changes.getVal.currentValue);
      }
    }
  }

}
